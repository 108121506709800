import { useState, memo } from 'react';

import StageButton from '../StageButton';
import CustomsDialog from './CustomsDialog';
import {StyledTableCell} from 'Utils';

const Customs = ({ loginUser, order }) => {
    const getEXStatus = () => {
        return order.consignee?.useCustoms ? true : false;
    }
    const getFreeDayStatus = () => {
        if (!order.freeDays || !order.taskyam?.data?.CargoList?.ImportProcess?.PortUnloadingDate)
            return "";
        var dt1 = order.taskyam?.data?.CargoList?.ImportProcess?.PortUnloadingDate;
        var freedays = order.freeDays;
        var dt = new Date();
        var dt2 = 0;
        if (!isNaN(dt1))
            dt2 = new Date(Number(dt1));
        else
            dt2 = new Date(dt1);
        dt = dt.getTime() / (24 * 3600000);
        dt2 = dt2.getTime() / (24 * 3600000) + freedays;
        var diff = (dt2 - dt);
        return " (" + Math.floor(diff) + ")";
    }
    const getStatus = () => {
        var stage = { 'status': `${order?.orderStatus ? (order?.orderStatus[7]?.status ? order.orderStatus[7]?.status : `${order?.orderStatus[6]?.status === "SUCCESS" ? "IN_PROGRESS" : "NOT_STARTED"}`) : null}`, 'notes': `${order.consignee?.useCustoms ? "Consignee doesn't use custom clearance service" : ""}` };
        if (order.locationId != 2) {
            /*if (order.incotermsId == 3) {
                stage = {};
                stage.status = "dissable";
                stage.notes = "";
            }*/
        } else {
            if ( order.incotermsId == 7 || order.incotermsId == 9 || order.incotermsId == 9) {
                stage = {};
                stage.status = "dissable";
                stage.notes = "";
            }
        }
        return stage;
    }
    const currentStage = order.orderStatus ? order.orderStatus[7] : null;

    const [openDialog, setOpenDialog] = useState(false);
    const onHandleOpenDialog = () => setOpenDialog(true);
    const onHandleCloseDialog = () => setOpenDialog(false);

  return (
      <StyledTableCell style={{ width: "5vw", p: 0 }}>
          <StageButton stage={getStatus()} onHandleOpenDialog={onHandleOpenDialog} stageName={"CC" + getFreeDayStatus()} loginUser={loginUser} eXStatus={getEXStatus()} />
          {openDialog && <CustomsDialog loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} onHandleCloseDialog1={onHandleCloseDialog} />}
    </StyledTableCell>
  );
};

export default memo(Customs);
