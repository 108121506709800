/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Grid, Typography, Button, Select, MenuItem, FormGroup, CircularProgress, FormControl, InputLabel, InputAdornment } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { TextField } from 'style-guide';
import ShipmentDataRow from '../common/shipmentDataRow/ShipmentDataRow';
import './CustomerShipments.scss';
import DefaultIcon from "../../../resources/default_icon.svg"
// import SearchIcon from "../../../resources/search_icon.svg"
import ListIcon from "../../../resources/list_icon.png"
import MapIcon from "../../../resources/map_icon.png"
import { REQUEST_ACTIONS, sendRequest, getCustomerData } from 'utils/Communicator';
import { addOrderFilter } from 'Utils';
import { useDispatch } from 'react-redux';
import TitleBar from "../common/TitleBar";
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { getOrders, onOrdersCountChange } from 'reducers/order-reducers/ordersSlicer';
import ShipmentDataMap from '../common/shipmentDataMap/ShipmentDataMap';
import ShipmentDetailDialog from '../common/shipmentDetailDialog/ShipmentDetailDialog';
import { TABS, SHIPMENT_SORTBY_FILTERS, SHIPMENT_TYPE_FILTERS, SHIPMENT_STATUS_FILTERS, SHIPMENT_TABS } from 'constants/global-constants';
import { getStatusStatus } from '../common/commonUtility';
import globeIcon from "../../../resources/globe.jpg"
import fclIcon from "../../../resources/fclmap.png"
import lclIcon from "../../../resources/lclmap.png"
import airIcon from "../../../resources/airmapblue.png"
import fileArrowDown from "../../../resources/FileArrowDown.png"
import worldMap from "../../../resources/world.png"
import SearchIcon from '@mui/icons-material/Search';
import * as XLSX from 'xlsx';

const CustomerShipments = ({ loginUser, jobSearch, setJobSearch, mapDisplay, setMapDisplay }) => {
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const dispatch = useDispatch();
    const [isShipmentList, setisShipmentList] = useState(true);
    const [activeTab, setActiveTab1] = useState('Active');
    const [sortBy, setSortBy] = useState(SHIPMENT_SORTBY_FILTERS[0]);
    const [shipmentType, setShipmentType1] = useState('');
    const [shipmentStatus, setShipmentStatus1] = useState('');
    // const [searchFilter, setSearchFilter] = useState('');
    // const { orders, page, size, ordersCount } = useSelector(state => state.orders);
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
  
    const [orderList, setOrderList] = useState([]);
    const [orderListFiltered, setOrderListFiltered] = useState([]);
    const [isOrderDetails, setIsOrderDetails] = useState(false);
    const [detailActiveTab, setdetailActiveTab] = useState(undefined);
    const [selectedOrder, setSelectedOrder] = useState('');

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [infoOpen, setInfoOpen] = useState(false);
    const [mapRef, setMapRef] = useState(null);
    const [mapX, setMapX] = useState({ lat: 32, lng: 34 });
    const [mapZoom, setMapZoom] = useState(2);
    const [shipperInitItems, setShipperInitItems] = useState(0);
    const [shipperItems, setShipperItems] = useState(0);
    const [truckerItems, setTruckerItems] = useState(0);
    const [departureItems, setDepartureItems] = useState(0);
    const [podItems, setPODItems] = useState(0);
    const [customsItems, setCustomsItems] = useState(0);
    const [importedItems, setImportedItems] = useState(0);
    const [polAir, setPolAir] = useState(null);
    const [podAir, setPodAir] = useState(null);
    const [polSea, setPolSea] = useState(null);
    const [podSea, setPodSea] = useState(null);
    const [sMode, setSMode] = useState(null);
    const [cOrigin, setCOrigin] = useState(null);
    const [incotermsData, setIncotermsData] = useState(null);
    const [fclType20Data, setFclType20Data] = useState(null);
    const [fclType40Data, setFclType40Data] = useState(null);
    const [varInterval, setVarInterval] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [sType, setSType1] = useState(10);
    const setSType = (val) => {
        setSType1(val);
        if (val == 10)
            setOrderListFiltered(orderList.filter(order => filterAll(order)));
        else if (val == 20)
            setOrderListFiltered(orderList.filter(order => filterAir(order)));
        else if (val == 30)
            setOrderListFiltered(orderList.filter(order => filterSea(order)));
    }
    const getSType = (val) => {
        if (val == 10)
            return "All";
        else if (val == 20)
            return "Air";
        else if (val == 30)
            return "Sea";
    }
    //
    useEffect(() => {
        if (orderList)
            filterA("Active", 10)
    }, [orderList]);
    // useEffect for fetch order data from API
    useEffect(() => {
        const params = [];
        let size = 1;
        setIsLoading(true);
        getCustomerData(loginUser, failedCallback, setOrderList1, false);

        sendRequest('/api/fclType40', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setFclType40Data(response),
            failedCallback,
        });
        sendRequest('/api/fclType20', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setFclType20Data(response),
            failedCallback,
        });
        /*setVarInterval(setInterval(() => {
            getCustomerData(loginUser, failedCallback, setOrderList1);
        }, 30000));*/

        // predefined air poa data get
        sendRequest('/api/poa/air', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setPodAir(response),
            failedCallback,
        });

        // predefined air pol data get
        sendRequest('/api/pol/air', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setPolAir(response),
            failedCallback,
        });

        // predefined lcl poa data get
        sendRequest('/api/poa/lcl', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setPodSea(response),
            failedCallback,
        });

        // predefined lcl pol data get
        sendRequest('/api/pol/lcl', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setPolSea(response),
            failedCallback,
        });

        // predefined shipping mode data get
        sendRequest('/api/subMode', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setSMode(response),
            failedCallback,
        });

        console.log("ordeCustomer Shipment use effect");
        // predefined country of origin data get
        sendRequest('/api/countryOfOrigin', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setCOrigin(response),
            failedCallback,
        });
        sendRequest('/api/incoterms', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setIncotermsData(response),
            failedCallback,
        });
    }, []);
    const getPOLStatus = (order) => {
        if (order.orderStatus[3].status === "SUCCESS" || getLastStatusNum(order) >= 3) {
            if (order.orderStatus[5].status) {
                if (order.orderStatus[5].status === "SUCCESS")
                    return "done";
                else (order.orderStatus[5].status === "IN_PROGRESS")
                return "inprogress";
            }
            else
                return "inprogress";
        }
        else
            return ""
    }
    const getPODStatus = (order) => {
        if (order.orderStatus[4].status === "SUCCESS") {
            if (order.orderStatus[5].status === "SUCCESS") {
                if (order.orderStatus[6].status) {
                    if (order.orderStatus[6].status === "SUCCESS")
                        return "done";
                    else if (order.orderStatus[6].status === "IN_PROGRESS")
                        return "inprogress";
                    else return "";
                }
                else
                    return "inprogress";
            }
            else
                return "";
        }
        else
            return "";
    }
    const getCustomsStatus = (order) => {
        if (order.orderStatus[4].status === "SUCCESS" && order.orderStatus[5].status === "SUCCESS" && order.orderStatus[6].status === "SUCCESS") {
            if (order.orderStatus[7].status === "SUCCESS") {
                return "done";
            } else if (order.orderStatus[7].status === "IN_PROGRESS") {
                return "inprogress";
            } else
                return "";
        }
        else
            return ""
    }
    const getImportStatus = (order) => {
        if (order.orderStatus[4].status === "SUCCESS" && order.orderStatus[5].status === "SUCCESS" && order.orderStatus[6].status === "SUCCESS" && order.orderStatus[7].status === "SUCCESS") {
            if (order.orderStatus[8].status === "SUCCESS") {
                return "done";
            } else if (order.orderStatus[8].status === "IN_PROGRESS") {
                return "inprogress";
            } else
                return "";
        }
        else
            return ""
    }
    const getLastStatusNum = (order) => {
        if (order.orderStatus[8]?.status === "IN_PROGRESS" || order.orderStatus[8]?.status === "FAILED")
            return 6;
        if (order.orderStatus[7]?.status === "IN_PROGRESS" || order.orderStatus[7]?.status === "FAILED")
            return 5;
        if (order.orderStatus[6]?.status === "IN_PROGRESS" || order.orderStatus[6]?.status === "FAILED")
            return 4;
        if (order.orderStatus[5]?.status === "IN_PROGRESS" || order.orderStatus[5]?.status === "FAILED")
            return 3;
        if (order.orderStage[4]?.status === "IN_PROGRESS" || order.orderStage[4]?.status === "FAILED")
            return 2;
        if (order.orderStage[3]?.status === "IN_PROGRESS" || order.orderStage[3]?.status === "FAILED")
            return 2;
        if (order.orderStage[2]?.status === "IN_PROGRESS" || order.orderStage[2]?.status === "FAILED")
            return 1;
        if (order.orderStage[1]?.status === "IN_PROGRESS" || order.orderStage[1]?.status === "FAILED")
            return 1;
        if (order.orderStage[0]?.status === "IN_PROGRESS" || order.orderStage[0]?.status === "FAILED")
            return 1;
    }
    const getLastRoute = (order) => {
        if ("inprogress" === ((order.orderStatus[1].status === 'SUCCESS' || getLastStatusNum(order) > 1) ? 'done' : 'inprogress'))
            return 'Shipper'
        if ("inprogress" === ((order.orderStatus[3].status === 'SUCCESS' || getLastStatusNum(order) > 2) ? 'done' : (order.orderStatus[1].status === 'SUCCESS' ? 'inprogress' : '')))
            return 'Warehouse';
        if (getPOLStatus(order) === "inprogress")
            return "Departure";
        if (getPODStatus(order) === "inprogress")
            return "Destination";
        if (getCustomsStatus(order) === "inprogress")
            return "Customs";
        if (getImportStatus(order) === "inprogress")
            return "Delivered";

        if (order.orderStatus[8]?.status === "IN_PROGRESS" || order.orderStatus[8]?.status === "FAILED")
            return "Delivered";
        if (order.orderStatus[7]?.status === "IN_PROGRESS" || order.orderStatus[7]?.status === "FAILED")
            return "Customs";
        if (order.orderStatus[6]?.status === "IN_PROGRESS" || order.orderStatus[6]?.status === "FAILED")
            return "Destination";
        if (order.orderStatus[5]?.status === "IN_PROGRESS" || order.orderStatus[5]?.status === "FAILED")
            return "Departure";
        if (order.orderStage[4]?.status === "IN_PROGRESS" || order.orderStage[4]?.status === "FAILED")
            return "Warehouse";
        if (order.orderStage[3]?.status === "IN_PROGRESS" || order.orderStage[3]?.status === "FAILED")
            return "Warehouse";
        if (order.orderStage[2]?.status === "IN_PROGRESS" || order.orderStage[2]?.status === "FAILED")
            return "Shipper";
        if (order.orderStage[1]?.status === "IN_PROGRESS" || order.orderStage[1]?.status === "FAILED")
            return "Shipper";
        if (order.orderStage[0]?.status === "IN_PROGRESS" || order.orderStage[0]?.status === "FAILED")
            return "Shipper";
    }
    function setShipmentStatus(data) {
        if (data === "Shipper") {
            setOrderListFiltered(orderList.filter(order => order.mapStatus == 0));
        }
        if (data === "W/H") {
            setOrderListFiltered(orderList.filter(order => order.mapStatus == 2));
        }
        if (data === "Departure") {
            setOrderListFiltered(orderList.filter(order => order.mapStatus == 5 || order.mapStatus == 3));
        }
        if (data === "Port of Destination") {
            setOrderListFiltered(orderList.filter(order => order.mapStatus == 6));
        }
        if (data === "Custom") {
            setOrderListFiltered(orderList.filter(order => order.mapStatus == 7));
        }
        if (data === "Importer") {
            setOrderListFiltered(orderList.filter(order => order.mapStatus == 8));
        }
        setShipmentStatus1(data);
        setShipmentType1(data);
        setActiveTab1("Active");
    }
    function setShipmentType(data) {
        if (activeTab === "Active")
            setOrderListFiltered(orderList.filter(order => filterAll(order)));
        if (activeTab === "Delivered")
            return (order.shippingModeId == 2 || order.shippingModeId == 3) && order.historyFlag;

        if (data === "Air") {
            setOrderListFiltered(orderList.filter(order => filterAll(order)));
        }
        if (data === "LCL") {
            setOrderListFiltered(orderList.filter(order => order.shippingModeId == 2));
        }
        if (data === "FCL") {
            setOrderListFiltered(orderList.filter(order => order.shippingModeId == 3));
        }
        setShipmentType1(data)
        setShipmentStatus1(data);
        setActiveTab1("Active")
    }
    function setActiveTab(data) {
        filterA(data, sType);
        setActiveTab1(data)
        setShipmentType1(data)
        setShipmentStatus1(data);
    }
    const formatDateTime = (date) => {
        if (!date)
            return "-";
        var dt = new Date(date);
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)}`;
    }
    const getCountryName = (order) => {
        var cname = cOrigin?.find(ctr => ctr.id == order.countryOfOriginId);
        return cname ? ", " + cname.name : "";
    }
    const getPOD = (order) => {
        var custDate = order.airStatus?.podDate ? order.airStatus?.podDate : (order.orderStage[4]?.departureStage[0]?.etd ? order.orderStage[4]?.departureStage[0].etd : null)
        if (!custDate)
            return "";
        var dt = order?.sinay?.arDate1 ? order.sinay.arDate1 : custDate;
        return ", " + formatDateTime(dt);
    }
    function setOrderList1(orders) {
        if (orders?.length < 1) {
            return;
        }
        orders.sort(function (a, b) { return b.date - a.date });
        for (var i = 0; i < orders.length; i++) {
            var port = orders[i].shippingModeId == 1 ? podAir?.find(air => air.id == orders[i].poaId)?.name : podSea?.find(sea => sea.id == orders[i].poaId)?.name;
            var searchText = orders[i].jobNumber + ", " + orders[i].suppliersPO;
            searchText += (orders[i].shipper?.companyShortName ? ", " + orders[i].shipper.companyShortName : "");
            searchText += orders[i].shippingModeId == 3 ? ", FCL" : (orders[i].shippingModeId == 2 ? ", LCL" : ", AIR");
            searchText += getCountryName(orders[i]) + (port ? ", " + port : "") + getPOD(orders[i]) ;
            orders[i].searchText = searchText;
        }
        setOrderList(orders);
        setOrderListFiltered(orders);
        setIsLoading(false);
    }
    // Order Details dialog handle function with active tab
    // @params order, tab name(active)
    const orderDetailHandle = (order, tab = TABS[1]) => {
        if (order?.id) {
            setSelectedOrder(order);
            setdetailActiveTab(tab);
            setIsOrderDetails(!isOrderDetails);
        }
    };
    const getOrderQuantity = (order) => {
        var res = 0;
        if (order.orderDetails) {
            for (var i = 0; i < order.orderDetails.length; i++) {
                res += order.orderDetails[i].numberOfPieces;
            }
        }
        if (order.orderFCLDetails?.quantity40) {
            res += order.orderFCLDetails.quantity40
        }
        if (order.orderFCLDetails?.quantity20) {
            res += order.orderFCLDetails.quantity20
        }
        return res;
    }
    const getOrderWeight = (order) => {
        var res = 0;
        if (order.orderDetails) {
            for (var i = 0; i < order.orderDetails.length; i++) {
                res += order.orderDetails[i].weightPerPiece;
            }
        }
        return res;
    }
    const getOrderVolume = (order) => {
        var res = 0;
        if (order.orderDetails) {
            for (var i = 0; i < order.orderDetails.length; i++) {
                res += (order.orderDetails[i].height * order.orderDetails[i].width * order.orderDetails[i]["length"]) / 1000000;
            }
        }
        return res;
    }
    const getFCLType = (order) => {
        var res = null;
        var res1 = null;
        if (order.orderFCLDetails?.fclType20Id) {
            res = fclType20Data.find(fcl => fcl.id == order.orderFCLDetails?.fclType20Id);
            if (order.orderFCLDetails?.fclType40Id) {
                res1 = fclType40Data.find(fcl => fcl.id == order.orderFCLDetails?.fclType40Id);
            }
        }
        else if (order.orderFCLDetails?.fclType40Id) {
            res = fclType40Data.find(fcl => fcl.id == order.orderFCLDetails?.fclType40Id);
        }
        return res ? res.name + (res1 ? (", " + res1.name) : "") : "-"
    }

    const jsonToExcel = function (data, name, header) {
        var ws = XLSX.utils.json_to_sheet(data, header);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "All");
        XLSX.writeFile(wb, name + ".xlsx");
    }
    const exportShipments = () => {
        var reap = orderListFiltered.filter(o1 => o1.searchText ? (o1?.searchText?.includes(jobSearch)) : (true));
        var data_main = [];
        var header = ["Type", "File", "JOB", "PO", "Supplier Name", "QTY", "Package", "Weight", "Volume", "INCOTERMS", "Origin Country", "Load Port", "Discharge Port", "ETA", "Vessel name", "Container", "Stage", "File Number"];
        for (var i = 0; i < reap.length; i++) {
            var data = {
                "Type": reap[i].locationId == 2 ? "Export" : "Import",
                "File": reap[i]?.orderStage[4]?.departureStage[0]?.fileNumber ? reap[i]?.orderStage[4]?.departureStage[0]?.fileNumber : "-",
                "JOB": reap[i].jobNumber ? reap[i].jobNumber : "-",
                "PO": reap[i]?.clientRefNumber ? reap[i]?.clientRefNumber : "-",
                "Sub Mode": sMode?.find(mode => mode.id === reap[i].subModeId)?.name || '-',
                "Supplier Name": reap[i]?.shipper?.companyName || '-',
                "QTY": getOrderQuantity(reap[i]),
                "Package": reap[i].shippingModeId == 3 ? getFCLType(reap[i]) : "PACKAGES",
                "Weight": getOrderWeight(reap[i]),
                "Volume": getOrderVolume(reap[i]),
                "INCOTERMS": incotermsData.find(inco => inco.id == reap[i]?.incotermsId).name || '-',
                "Origin Country": cOrigin?.find(country => country.id === reap[i].countryOfOriginId)?.name,
                "Load Port": ((reap[i]?.shippingModeId === 1) ? (polAir?.find(pol => pol.id === reap[i].polId)?.name) : (polSea?.find(pol => pol.id === reap[i].polId)?.name)) || '-',
                "Discharge Port": ((reap[i]?.shippingModeId === 1) ? (podAir?.find(poa => poa.id === reap[i].poaId)?.name) : (podSea?.find(poa => poa.id === reap[i].poaId)?.name)) || '-',
                //"ETD": formatDateTime(reap[i]?.orderStage[4]?.departureStage[0]?.etd),
                "ETA": formatDateTime(reap[i].airStatus?.podDate ? reap[i].airStatus?.podDate : (reap[i].orderStage[4]?.departureStage[0]?.eta ? reap[i].orderStage[4]?.departureStage[0].eta : null)),
                "Vessel name": reap[i]?.orderStage[4]?.departureStage[0]?.vesselNumber ? reap[i]?.orderStage[4]?.departureStage[0]?.vesselNumber : "-",
                "Container": reap[i]?.orderStage[4]?.departureStage[0]?.containerNumber ? reap[i]?.orderStage[4]?.departureStage[0]?.containerNumber : "-",
                //"AWB": reap[i]?.shippingModeId === 1 ? (reap[i]?.orderStage[4]?.departureStage[0]?.awb ? reap[i]?.orderStage[4]?.departureStage[0]?.awb : "-") : "-",
                //"Flight": reap[i]?.orderStage[4]?.departureStage[0]?.flightNumber ? reap[i]?.orderStage[4]?.departureStage[0]?.flightNumber : "-",
                "Stage": getLastRoute(reap[i]),
                "File Number": reap[i].fileNumber,
            };

            data_main.push(data);
        }
        jsonToExcel(data_main, "orders", header);
    }

    // useEffect for hide scroll from body if order details dialog opne
    useEffect(()=> {
        isOrderDetails && document.body.classList.add('dialog-open');
    }, [isOrderDetails]);

    const activeShipmentsActions = () => (
        <Fragment>
            {!isMobile() ? <Grid item container flexWrap="nowrap"><Button style={{ marginBottom: "2vw" }} title="" className="icon-btn" onClick={() => exportShipments()}>
                <img src={fileArrowDown} />
            </Button></Grid> :
                <Grid item container flexWrap="nowrap" style={{ background: "#fff" }}>
                    <img src={fileArrowDown} style={{ marginRight: "5vw", width: "3vw" }} onClick={() => exportShipments()} />
                </Grid>
            }
        </Fragment>);
    const activeShipmentsActionsExport = () => (
        <Fragment>
            {isMobile() && <Grid item container flexWrap="nowrap" style={{ background: "#fff" }}>
                <TextField
                    xs={12}
                    sm={null}
                    md={null}
                    lg={null}
                    style={{ width: "100%" }}
                    label=''
                    type='search'
                    className="search"
                    value={jobSearch}
                    endAdornment={
                        <InputAdornment position='end'>
                            <SearchIcon onClick={() => setOrderList1(orderList)} />
                        </InputAdornment>
                    }
                    onChange={event => setJobSearch(event.target.value)}
                />
            </Grid>
            }
        </Fragment>);

    const isExportInList = (oList) => {
        if (oList) {
            if (oList.length > 0) {
                for (var i = 0; i < oList.length; i++) {
                    if (oList[i].locationId == 2)
                        return 1;
                }
            }
            return 0;
        }
        return 0;
    }
    const isImportInList = (oList) => {
        if (oList) {
            if (oList.length > 0) {
                for (var i = 0; i < oList.length; i++) {
                    if (oList[i].locationId != 2)
                        return 1;
                }
            }
            return 0;
        }
        return 0;
    }

    const getActiveNum = () => {
        return orderList.filter(o => !o.historyFlag).length;
    }
    const getHistoryNum = () => {
        return orderList.filter(o => o.historyFlag).length;
    }

    const filterA = (tab, type) => {
        var list = [];
        if (tab === "Active") {
            if (type == 10)
                list = (orderList.filter(order => !order?.historyFlag && (jobSearch ? order?.searchText?.includes(jobSearch) : true)));
            else if (type == 20)
                list = (orderList.filter(order => order?.shippingModeId == 1 && !order?.historyFlag && (jobSearch ? order?.searchText?.includes(jobSearch) : true)));
            else if (type == 30)
                list = (orderList.filter(order => (order?.shippingModeId == 2 || order?.shippingModeId == 3) && !order?.historyFlag && (jobSearch ? order?.searchText?.includes(jobSearch) : true)));
        }
        else if (tab === "Delivered") {
            if (type == 10)
                list = (orderList.filter(order => order?.historyFlag && (jobSearch ? order?.searchText?.includes(jobSearch) : true)));
            else if (type == 20)
                list = (orderList.filter(order => order?.shippingModeId == 1 && order?.historyFlag && (jobSearch ? order?.searchText?.includes(jobSearch) : true)));
            else if (type == 30)
                list = (orderList.filter(order => (order?.shippingModeId == 2 || order?.shippingModeId == 3) && order?.historyFlag && (jobSearch ? order?.searchText?.includes(jobSearch) : true)));
        }
        else {
            if (type == 10)
                list = (orderList.filter(order => (jobSearch ? order?.searchText?.includes(jobSearch) : true)));
            else if (type == 20)
                list = (orderList.filter(order => order?.shippingModeId == 1 && (jobSearch ? order?.searchText?.includes(jobSearch) : true)));
            else if (type == 30)
                list = (orderList.filter(order => (order?.shippingModeId == 2 || order?.shippingModeId == 3) && (jobSearch ? order?.searchText?.includes(jobSearch) : true)));
        }
        setOrderListFiltered(list);
    }
    const filterAll = (data) => {
        if (activeTab === "Active")
            return !data.historyFlag;
        if (activeTab === "Delivered")
            return data.historyFlag;
        return true;
    }
    const filterAir = (data) => {
        if (activeTab === "Active")
            return data.shippingModeId == 1 && !data.historyFlag;
        if (activeTab === "Delivered")
            return data.shippingModeId == 1 && data.historyFlag;
        return data.shippingModeId == 1;
    }
    const filterSea = (order) => {
        if (activeTab === "Active")
            return (order.shippingModeId == 2 || order.shippingModeId == 3) && !order.historyFlag;
        if (activeTab === "Delivered")
            return (order.shippingModeId == 2 || order.shippingModeId == 3) && order.historyFlag;
        return order.shippingModeId == 2 || order.shippingModeId == 3;
    }

    return <Fragment>
        <Box className="shipments-content">
            {!isMobile() ? 
                <Box className="shipments-header">

                    <Grid item container sm={10} flexWrap="nowrap" flexDirection="row" style={{ marginLeft: "5vw" }}>
                        <Grid item container flexDirection="column" className="filterSection1" style={{}}>
                            <Typography className='tk-good-times typoText' >Shipments</Typography>
                            <Grid item container className='tab-wrapper' flexDirection="row" flexWrap="nowrap">
                                {SHIPMENT_TABS.map((tab, index) =>
                                    <Button key={index} className={`btn-tab btn-primary ${activeTab === tab && 'active'}`} onClick={() => setActiveTab(tab)}>
                                        {`${tab} (${tab === SHIPMENT_TABS[0] ? orderList.length : (tab === SHIPMENT_TABS[1] ? getActiveNum() : getHistoryNum())})`}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>

                        <Grid item container flexDirection="column" style={{}}>
                            <Typography className='tk-good-times typoText' >Shipment Type</Typography>
                            <Grid item container className='tab-wrapper' flexDirection="row" flexWrap="nowrap">
                                <FormControl>
                                    <InputLabel id="selectShipmentType"></InputLabel>
                                    <Select className="shipmentDropsoenSelect"
                                        labelId="selectShipmentType"
                                        value={sType}
                                        label=""
                                        onChange={event => setSType(event.target.value)}
                                        MenuProps={{
                                            sx: {
                                                "&& .Mui-selected": {
                                                    color: "#00AEEF !important",
                                                    background: "none",
                                                    "-webkit-text-stroke": "thin"
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem className="menuItem1" value={10} >ALL ({orderList.filter(order => filterAll(order)).length})</MenuItem>
                                        <MenuItem className="menuItem1" value={20} >AIR ({orderList.filter(order => filterAir(order)).length})</MenuItem>
                                        <MenuItem className="menuItem1" value={30} >SEA ({orderList.filter(order => filterSea(order)).length})</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                    </Grid>
                </Box> :
                <Box className="shipments-header">

                    <Grid item container sm={10} flexWrap="nowrap" flexDirection="row" class="customerShipmentFilter">
                        <Grid item container flexDirection="column" className="selectonBoxController">
                            <Typography className='tk-good-times typoText' >Shipments</Typography>
                            <Grid item container className='tab-wrapper1' flexDirection="row" flexWrap="nowrap">
                                <InputLabel id="selectShipmentType1"></InputLabel>
                                <Select className="shipmentDropsoenSelect"
                                    labelId="selectShipmentType1"
                                    value={activeTab}
                                    label={"Shipments"}
                                >
                                    {SHIPMENT_TABS.map((tab, index) =>
                                        <MenuItem key={index} className={`menuItem1`} value={index} onClick={() => setActiveTab(tab)}>
                                            {`${tab} (${tab === SHIPMENT_TABS[0] ? orderList.length : (tab === SHIPMENT_TABS[1] ? getActiveNum() : getHistoryNum())})`}
                                        </MenuItem>
                                    )}
                                </Select>
                            </Grid>
                            <Typography className='tk-good-times typoTextBottom' >{activeTab}</Typography>
                        </Grid>

                        <Grid item container flexDirection="column" className="selectonBoxController">
                            <Typography className='tk-good-times typoText' >Shipment Type</Typography>
                            <Grid item container className='tab-wrapper1' flexDirection="row" flexWrap="nowrap">
                                <InputLabel id="selectShipmentType"></InputLabel>
                                <Select className="shipmentDropsoenSelect"
                                    labelId="selectShipmentType"
                                    value={sType}
                                    label="Shipment Type"
                                    onChange={event => setSType(event.target.value)}
                                >
                                    <MenuItem className="menuItem1" value={10} >ALL ({orderList.filter(order => filterAll(order)).length})</MenuItem>
                                    <MenuItem className="menuItem1" value={20} >AIR ({orderList.filter(order => filterAir(order)).length})</MenuItem>
                                    <MenuItem className="menuItem1" value={30} >SEA ({orderList.filter(order => filterSea(order)).length})</MenuItem>
                                </Select>
                            </Grid>
                            <Typography className='tk-good-times typoTextBottom' >{getSType(sType)}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            }
            <Box className='shipment-list'>
                {isLoading && <Grid item container justifyContent="center">
                    <CircularProgress style={{ marginTop: "20px" }} />
                </Grid>}
                {isShipmentList ?
                    <Box className="active-shipments">
                        {/*{isLoading && <Grid item container justifyContent="center">
                            <CircularProgress style={{ marginTop: "20px" }} />
                        </Grid>}*/}
                        {(!isLoading && isImportInList(orderListFiltered.filter(o1 => o1.searchText ? (o1?.searchText?.includes(jobSearch)) : (true))) > 0) && <TitleBar titleName={'Import'}>
                            {activeShipmentsActions()}
                        </TitleBar>}
                        {(!isLoading && isImportInList(orderListFiltered.filter(o1 => o1.searchText ? (o1?.searchText?.includes(jobSearch)) : (true))) > 0) && <ShipmentDataRow orders={orderListFiltered.filter(o1 => o1.searchText ? (o1?.searchText?.includes(jobSearch)) : (true))} isLoading={isLoading}
                            showImportsExport={true}
                            showImports={true}
                            polAir={polAir}
                            podAir={podAir}
                            polSea={polSea}
                            podSea={podSea} cOrigin={cOrigin} setisShipmentList={setisShipmentList} orderDetailHandle={orderDetailHandle} />}
                        {(!isLoading && isExportInList(orderListFiltered.filter(o1 => o1.searchText ? (o1?.searchText?.includes(jobSearch)) : (true))) > 0) && <TitleBar titleName={'Export'}>
                            {activeShipmentsActionsExport()}
                        </TitleBar>}
                        {(!isLoading && isExportInList(orderListFiltered.filter(o1 => o1.searchText ? (o1?.searchText?.includes(jobSearch)) : (true))) > 0) && <ShipmentDataRow orders={orderListFiltered.filter(o1 => o1.searchText ? (o1?.searchText?.includes(jobSearch)) : (true))} isLoading={isLoading}
                            showImportsExport={true}
                            showImports={false}
                            polAir={polAir}
                            podAir={podAir}
                            polSea={polSea}
                            podSea={podSea} cOrigin={cOrigin} setisShipmentList={setisShipmentList} orderDetailHandle={orderDetailHandle} />}
                    </Box> : <ShipmentDataMap orders={orderListFiltered.filter(o1 => o1.searchText ? (o1?.searchText?.includes(jobSearch)) : (true))}
                        polAir={polAir}
                        podAir={podAir}
                        polSea={polSea}
                        podSea={podSea} cOrigin={cOrigin} setisShipmentList={setisShipmentList} orderDetailHandle={orderDetailHandle} />
                }
                {/*{isShipmentList ? 
                    <ShipmentDataRow orders={orderListFiltered.filter(o1 => o1.searchText ? (o1?.searchText?.includes(jobSearch)) : (true))}
                        showImportsExport={false}
                        showImports={true}
                        polAir={polAir}
                        podAir={podAir}
                        polSea={polSea}
                        podSea={podSea} cOrigin={cOrigin} isBookmark={false} setisShipmentList={setisShipmentList} orderDetailHandle={orderDetailHandle} /> 
                    : <ShipmentDataMap orders={orderListFiltered} setisShipmentList={setisShipmentList} orderDetailHandle={orderDetailHandle} />}*/}
            </Box>
        </Box>
        {/* Shipment Details Dialog */}
        <ShipmentDetailDialog
            open={isOrderDetails}
            close={setIsOrderDetails}
            order={selectedOrder}
            defaultTab={detailActiveTab}
            polAir={polAir}
            podAir={podAir}
            polSea={polSea}
            podSea={podSea}
            sMode={sMode}
            cOrigin={cOrigin}
        />
    </Fragment>
};

export default CustomerShipments;