import { useEffect, useCallback, useState } from 'react';

import { Box, Grid, Button, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import { SelectField, TextField, DateTimePicker, MultilineField, Dialog } from 'style-guide';

import { STATUS } from 'constants/global-constants';
import { REQUEST_ACTIONS, sendRequest, setOrderData } from 'utils/Communicator';

import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from 'reducers/order-reducers/ordersSlicer';
import {
    resetDepartureStage,
    onStageStatusInfoIdChange,
    onCutOffTimeChange,
    onUpdateTimeChange,
    onRefNumberChange,
    onNotesChange,
    onMasterChange,
    onHouseChange,
    onFileNumberChange,
    onIdChange,
    onWHETATimeChange,
    onWHRECTimeChange,
    onETATimeChange,
    onETDTimeChange,
    onDepartureStatusesChange,
    onShippingLineNamesOptionChange,
    onShippingLineNamesChange,
    onShipNameChange,
    onImoChange,
    onContainerNumberChange,
    onBookingNumberChange,
    onVesselNumberChange,
    onShippingLineNameChange,
    onAirlineChange,
    onFlightNumberChange,
    onUrgentShipmentStatusChange,
    onTelexHBLChange,
    onTelexMBLChange,
    onAWBChange,
    onDepartureDateChange,
    onInvoiceUploadedChange,
    onFileCloseChange,
    onPreAlertFileChange,
    onArrivedChange,
    onPartialShipmentChange,
    onDEPChange,
    onCutoffChange,
} from 'reducers/stage-reducers/departureStageSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { addOrderFilter, addSearchFilter, addStatusChatMessage, getStatusChatMessage } from 'Utils';
const DepartureDialog = ({ loginUser, order, openDialog, setOpenDialog, pageName }) => {
    const dispatch = useDispatch();
    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const [freeDays, setFreeDays] = useState(order.freeDays);
    const onHandleCheckboxChange = (event, action) => dispatch(action(event.target.checked));
    const onHandleChange = (event, action) => dispatch(action(event.target.value));
    const onHandleShippingChange = (event, action) => {
        onHandleChange(event, action);
        for (var i = 0; i < shippingLineNamesOption.length; i++) {
            if (shippingLineNamesOption[i].id === event.target.value) {
                dispatch(onShipNameChange(shippingLineNamesOption[i].scac));
                setShippingLineNumber(shippingLineNamesOption[i].shippingLineNumber)
            }
        }
        console.log(shippingLineNamesOption);
        console.log(event.target.value);
       
    }
    const onHandleChangeValidation = (event, action, validator) => {
        var val = validator(event.target.value);
        if (val) {
            snackbarToggle(val);
            dispatch(action(event.target.value));
        }
        dispatch(action(event.target.value));
    };
    const validateAWB = (val) => {
        var vals = val.split("-");
        if (vals.length != 2)
            return { type: "error", message: "AWB should start have following format: 123-12345678." }
        if (vals[0].length != 3)
            return { type: "error", message: "AWB should start have following format: 123-12345678." }
        if (vals[1].length != 8)
            return { type: "error", message: "AWB should start have following format: 123-12345678." }
        return false;
    }
    const validateSCAC = (val) => {
        if (val.length != 4)
            return { type: "error", message: "SCAC should have 4 characters." };
        return false;
    }
    const validateContainerNum = (val) => {
        var containers = [];
        if (val.length < 11)
            return { type: "error", message: "Container Number should have 11 characters." };
        else if (val.length == 11)
            return false;
        else {
            containers = val.split(",");
            for (var i = 0; i < containers.length; i++) {
                if (containers[i].trim().length != 11)
                    return { type: "error", message: "Container Number should have 11 characters." };
            }
        }
        return false;
    }
    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        customerId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId, userTracer, userCustoms,
        urgentShipment,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.orderTableFilter);
    const { page, size } = useSelector(state => state.orders);
    const {
        id,
        stageStatusInfoId,
        departureStatuses,
        shippingLineNames,
        shippingLineNamesOption,
        cutOffTime,
        updateTime,
        refNumber,
        master,
        fileNumber,
        house,
        notes,
        wheta,
        whrec,
        eta,
        etd,
        shipName,
        imo,
        containerNumber,
        bookingNumber,
        vesselNumber,
        shippingLineName,
        airline,
        telexMbl,
        telexHbl,
        urgentShipmentStatus,
        flightNumber,
        awb,
        departureDate,
        invoiceUploaded,
        fileClose,
        preAlertFile,
        arrived,
        partialShipment,
        dep,
        cutoff,
    } = useSelector(state => state.departureStage);

    const [isLoaded, setIsLoaded] = useState(false);

    const [shippingLineNumber, setShippingLineNumber] = useState('');

    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const currentStage = order.orderStage[4];

    const { incotermsData, airPolData, airPoaData, lclPolData, countryOfOriginData, lclPoaData, subModeData, fclType20Data, fclType40Data } =
        useSelector(state => state.predefinedData);
    const getComments = (str) => {
        var strs1 = [];
        var strs = [];
        strs = str.split("\n");
        for (var i = 0; i < strs.length; i++) {
            //if (isHebrew(strs[i]))
            //    strs1.push(encodeURI(strs[i]));
            //else
            strs1.push(strs[i]);
        }
        return strs1.join("<br/>");
    }
    const getKindOfPieces = () => {
        if (order.shippingModeId == 3) {
            if (order.orderFCLDetails) {
                var fcl20 = "";
                var fcl40 = "";
                if (order.orderFCLDetails?.fclType20Id) {
                    fcl20 = fclType20Data.find(dat => dat.id == order.orderFCLDetails?.fclType20Id)?.name
                }
                if (order.orderFCLDetails?.fclType40Id) {
                    fcl40 = fclType40Data.find(dat => dat.id == order.orderFCLDetails?.fclType40Id)?.name
                }
                if (fcl20 && fcl40)
                    return fcl20 + ", " + fcl40;
                if (fcl20 && !fcl40)
                    return fcl20;
                if (!fcl20 && fcl40)
                    return fcl40;
                return "";
            }
        }
        return "";
    }
    const getTotalPieces = () => {
        var num = 0;
        for (var i = 0; i < order.orderDetails?.length; i++) {
            num += order.orderDetails[i].numberOfPieces;
        }
        if (order.orderFCLDetails?.quantity20) {
            num += order.orderFCLDetails.quantity20;
        }
        if (order.orderFCLDetails?.quantity40) {
            num += order.orderFCLDetails.quantity40;
        }
        return num ? num : "";
    }
    const getTo = (poaId, shippingModeId, bol) => {
        try {
            var str = "";
            if (shippingModeId == 1) {
                str = airPoaData.find(poa => poa.id == poaId)?.name
            }
            else str = lclPoaData.find(poa => poa.id == poaId)?.name
            if (str)
                return str;
            else if (bol)
                str = countryOfOriginData.find((ctr) => ctr.id == order.countryOfOriginId)?.name;
            return str ? str : "";
        } catch (e) {
            return "N/A";
        }
    }
    const getFrom = (polId, shippingModeId) => {
        try {
            if (shippingModeId == 1) {
                return airPolData.find(pol => pol.id == polId).name
            }
            return lclPolData.find(pol => pol.id == polId).name
        } catch (e) {
            return "N/A"
        }
    }
    const getType = (shippingModeId) => {
        try {
            if (shippingModeId == 1) {
                return "Air";
            } else if (shippingModeId == 2) {
                return "LCL";
            } else {
                return "FCL";
            }
        } catch (e) {
            return "N/A"
        }
    }
    const getTotalWeight = () => {
        var wt = 0;
        for (var i = 0; i < order.orderDetails.length; i++) {
            wt += order.orderDetails[i].weightPerPiece != 0 ? (order.orderDetails[i].numberOfPieces * order.orderDetails[i].weightPerPiece) : order.orderDetails[i].totalweight;
        }
        return wt ? wt.toFixed(2) : "";
    }
    const getChWeight = () => {
        var vt = 0;
        var manualwt = 0;
        for (var i = 0; i < order.orderDetails.length; i++) {
            var vol = order.orderDetails[i].manualVolume ? order.orderDetails[i].manualVolume : (order.orderDetails[i]['length'] * order.orderDetails[i].width * order.orderDetails[i].height);
            vt = vt + order.orderDetails[i].numberOfPieces * (vol) / 1000000;
        }
        return vt ? vt.toFixed(2) : "";
    }
    const formatDate = (date) => {
        if (!date)
            return "-";
        var str = [];
        if (date.includes("T")) {
            str = date.split("T")[1].split(":");
            var time = str[0] + ":" + str[1];

            const dt = new Date(date);
            const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
            return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)} ${time}`;
        } else {
            return date;
        }
    }
    const formatDateTime = (date) => {
        if (!date)
            return "";
        var dt = new Date(Number(date));
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)} ${padL(dt.getHours())}:${padL(dt.getMinutes())}`;
    }
    const formatDateMonthYear = (date) => {
        if (!date)
            return "";
        var dt = new Date(Number(date));
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)}`;
    }
    const [showMail, setShowMail1] = useState(false);
    const getCompanyShortName = (company) => {
        if (company?.companyShortName)
            return company.companyShortName?.substring(0, 11).toUpperCase();
        else
            return company.companyName?.substring(0, 11).toUpperCase();
    }

    const setShowMail = () => {
        showMail ? setShowMail1(false) : setShowMail1(true);
        var sub = getType(order.shippingModeId) + " " + getFrom(order.polId, order.shippingModeId, true) + "-" + getTo(order.poaId, order.shippingModeId);
        sub += " | " + getCompanyShortName(order.shipper) + " | " + getCompanyShortName(order.consignee) + ", ref " + order.jobNumber;
        setMailSubject(sub);
    }
    const getTodayDate = () => {
        return new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(new Date())
    }
    const [mailSubject, setMailSubject] = useState("");
    const [mailBody, setMailBody] = useState("");
    const [mailToAdd, setMailToAdd1] = useState("");

    const [useMailToAgent, setUseMailToAgent] = useState(false);
    const [mailToAgent, setMailToAgent1] = useState("");
    const [mailToAgentValidation, setMailToAgentValidation] = useState(true);
    const setMailToAgent = (str) => {
        setMailToAgent1(str);
        setMailToAgentValidation(validateEmail(str));
    }
    const [useMailToAgent2, setUseMailToAgent2] = useState(false);
    const [mailToAgent2, setMailToAgent12] = useState("");
    const [mailToAgentValidation2, setMailToAgentValidation2] = useState(true);
    const setMailToAgent2 = (str) => {
        setMailToAgent12(str);
        setMailToAgentValidation2(validateEmail(str));
    }
    const [useMailToAgent3, setUseMailToAgent3] = useState(false);
    const [mailToAgent3, setMailToAgent13] = useState("");
    const [mailToAgentValidation3, setMailToAgentValidation3] = useState(true);
    const setMailToAgent3 = (str) => {
        setMailToAgent13(str);
        setMailToAgentValidation3(validateEmail(str));
    }
    const [useMailToAgent4, setUseMailToAgent4] = useState(false);
    const [mailToAgent4, setMailToAgent14] = useState("");
    const [mailToAgentValidation4, setMailToAgentValidation4] = useState(true);
    const setMailToAgent4 = (str) => {
        setMailToAgent14(str);
        setMailToAgentValidation4(validateEmail(str));
    }
    const [useMailToAgent5, setUseMailToAgent5] = useState(false);
    const [mailToAgent5, setMailToAgent15] = useState("");
    const [mailToAgentValidation5, setMailToAgentValidation5] = useState(true);
    const setMailToAgent5 = (str) => {
        setMailToAgent15(str);
        setMailToAgentValidation5(validateEmail(str));
    }
    const [useMailToAdd, setUseMailToAdd] = useState(false);
    const setMailToAdd = (str) => {
        setMailToAdd1(str);
        if (str) {
            var strs = str.split(",");
            var valid = true;
            for (var i = 0; i < strs.length; i++) {
                if (!validateEmail(strs[i]))
                    valid = false;
            }
            setUseMailToAdd(valid);
        }
    }
    const [useMailToCustomer, setUseMailToCustomer] = useState(false);
    const [mailToCustomer, setMailToCustomer] = useState(order?.consignee?.email ? order.consignee.email : "");
    const [mailToCustomerValidation, setMailToCustomerValidation] = useState(order?.consignee?.email ? true : false);

    const [useMailToCustomer2, setUseMailToCustomer2] = useState(false);
    const [mailToCustomer2, setMailToCustomer2] = useState(order?.consignee?.email2 ? order.consignee.email2 : "");
    const [mailToCustomerValidation2, setMailToCustomerValidation2] = useState(order?.consignee?.email2 ? true : false);

    const [useMailToCustomer3, setUseMailToCustomer3] = useState(false);
    const [mailToCustomer3, setMailToCustomer3] = useState(order?.consignee?.email3 ? order.consignee.email3 : "");
    const [mailToCustomerValidation3, setMailToCustomerValidation3] = useState(order?.consignee?.email3 ? true : false);

    const [useMailToCustomer4, setUseMailToCustomer4] = useState(false);
    const [mailToCustomer4, setMailToCustomer4] = useState(order?.consignee?.email4 ? order.consignee.email4 : "");
    const [mailToCustomerValidation4, setMailToCustomerValidation4] = useState(order?.consignee?.email4 ? true : false);

    const [useMailToCustomer5, setUseMailToCustomer5] = useState(false);
    const [mailToCustomer5, setMailToCustomer5] = useState(order?.consignee?.email5 ? order.consignee.email5 : "");
    const [mailToCustomerValidation5, setMailToCustomerValidation5] = useState(order?.consignee?.email5 ? true : false);

    const getCargoType = (type) => {
        if (type === "Cargo")
            return "General Cargo";
        return type;
    }
    const shipperCountry = (order?.shipper?.countryId ? countryOfOriginData.find(ctr => ctr.id == order.shipper.countryId)?.name : "");
    const consigneeCountry = (order?.consignee?.countryId ? countryOfOriginData.find(ctr => ctr.id == order.consignee.countryId)?.name : "");
    const getShipperPhone = (shipper) => {
        if (shipper?.mobile?.trim()) {
            return shipper?.mobile;
        } else if (shipper?.businessPhone?.trim()) {
            return shipper?.businessPhone;
        }
        if (shipper?.mobile2?.trim()) {
            return shipper?.mobile2;
        } else if (shipper?.businessPhone2?.trim()) {
            return shipper?.businessPhone2;
        }
        if (shipper?.mobile3?.trim()) {
            return shipper?.mobile3;
        } else if (shipper?.businessPhone3?.trim()) {
            return shipper?.businessPhone3;
        }
        if (shipper?.mobile4?.trim()) {
            return shipper?.mobile4;
        } else if (shipper?.businessPhone4?.trim()) {
            return shipper?.businessPhone4;
        }
        if (shipper?.mobile5?.trim()) {
            return shipper?.mobile5;
        } else if (shipper?.businessPhone5?.trim()) {
            return shipper?.businessPhone5;
        }
        return "";

        var phoneStr = "";
        if (shipper?.mobile) {
            phoneStr = shipper?.mobile;
        } else if (shipper?.businessPhone) {
            phoneStr = shipper?.businessPhone;
        }

        if (shipper?.mobile2) {
            if (phoneStr.length != 0)
                phoneStr += ", " + shipper?.mobile2;
            else
                phoneStr = shipper?.mobile2;
        } else if (shipper?.businessPhone2) {
            if (phoneStr.length != 0)
                phoneStr += ", " + shipper?.businessPhone2;
            else
                phoneStr = shipper?.businessPhone2;
        }

        if (shipper?.mobile3) {
            if (phoneStr.length != 0)
                phoneStr += ", " + shipper?.mobile3;
            else
                phoneStr = shipper?.mobile3;
        } else if (shipper?.businessPhone3) {
            if (phoneStr.length != 0)
                phoneStr += ", " + shipper?.businessPhone3;
            else
                phoneStr = shipper?.businessPhone3;
        }

        if (shipper?.mobile4) {
            if (phoneStr.length != 0)
                phoneStr += ", " + shipper?.mobile4;
            else
                phoneStr = shipper?.mobile4;
        } else if (shipper?.businessPhone4) {
            if (phoneStr.length != 0)
                phoneStr += ", " + shipper?.businessPhone4;
            else
                phoneStr = shipper?.businessPhone4;
        }

        if (shipper?.mobile5) {
            if (phoneStr.length != 0)
                phoneStr += ", " + shipper?.mobile5;
            else
                phoneStr = shipper?.mobile5;
        } else if (shipper?.businessPhone5) {
            if (phoneStr.length != 0)
                phoneStr += ", " + shipper?.businessPhone5;
            else
                phoneStr = shipper?.businessPhone5;
        }
        return phoneStr;
    }
    const sendMail = () => {
        if (useMailToAgent && mailToAgent === "") {
            alert("Please enter agent email.")
            return;
        }
        if (useMailToAgent && !validateEmail(mailToAgent)) {
            alert("Please enter a proper agent email.")
            return;
        }
        if (useMailToCustomer && mailToCustomer === "") {
            alert("Please make sure to have proper consignee email.")
            return;
        }
        if (useMailToCustomer && !validateEmail(mailToCustomer)) {
            alert("Please enter a proper consignee email in CRM.")
            return;
        }
        if (!((useMailToAgent && validateEmail(mailToAgent)) ||
            (useMailToCustomer && validateEmail(mailToCustomer)) || useMailToAdd)) {
            alert("Select atleast one of agent and/or customer and/or additional e-mails should be valid.");
            return;
        }
        var mtoCust = mailToCustomer;
        if (!useMailToCustomer) {
            mtoCust = null;
        }
        var mtoCust2 = mailToCustomer2;
        if (!useMailToCustomer2) {
            mtoCust2 = null;
        }
        var mtoCust3 = mailToCustomer3;
        if (!useMailToCustomer3) {
            mtoCust3 = null;
        }
        var mtoCust4 = mailToCustomer4;
        if (!useMailToCustomer4) {
            mtoCust4 = null;
        }
        var mtoCust5 = mailToCustomer5;
        if (!useMailToCustomer5) {
            mtoCust5 = null;
        }
        var mtoagent = mailToAgent;
        if (!useMailToAgent) {
            mtoagent = null;
        }
        var mtoagent2 = mailToAgent2;
        if (!useMailToAgent2) {
            mtoagent2 = null;
        }
        var mtoagent3 = mailToAgent3;
        if (!useMailToAgent3) {
            mtoagent3 = null;
        }
        var mtoagent4 = mailToAgent4;
        if (!useMailToAgent4) {
            mtoagent4 = null;
        }
        var mtoagent5 = mailToAgent5;
        if (!useMailToAgent5) {
            mtoagent5 = null;
        }
        var addto = mailToAdd;
        if (!useMailToAdd) {
            addto = null;
        }
        var impt = order.locationId === "2" ? "Export" : "Import";
        /*var mbody = '<br /> <div style={{ textAlign: "center", width: "100%" }}><b>' + impt + ' JOB ORDER No. - ' + order.jobNumber + '</b></div><br /><br /><b> From: </b> <br />' + getFrom(order.polId, order.shippingModeId, false) + ' <br /> <br /><b>To: </b> <br />' + getTo(order.poaId, order.shippingModeId) + ' <br /><br /><b>Shipment Details: </b><table style="text-align: center"><tr><th style="text-decoration: underline">Pieces</th><th style="text-decoration: underline; padding: 5px">L(CM)</th><th style="text-decoration: underline; padding: 5px">W(CM)</th><th style="text-decoration: underline; padding: 5px">H(CM)</th><th style="text-decoration: underline; padding: 5px">Weight per piece (KG)</th></tr>';
        for (var i = 0; i < order.orderDetails.length; i++) {
            mbody += "<tr><td>" + order.orderDetails[i].numberOfPieces + "</td><td>" + order.orderDetails[i]["length"] + "</td><td>" + order.orderDetails[i].width + "</td><td>" + order.orderDetails[i].height + "</td><td>" + order.orderDetails[i].weightPerPiece + "</td></tr>";
        }
        mbody += "<tr><td></td><td></td><td></td><td></td><td>Total Weight</td></tr><tr><td></td><td></td><td></td><td></td><td>" + getTotalWeight() + "</td></tr><tr><td></td><td></td><td></td><td></td><td>Chargeable Weight</td></tr><tr><td></td><td></td><td></td><td></td><td>" + getChWeight() + "</td></tr></table> <br/><br/>";
        mbody += '<Grid continer lg={12} item style={{ margin: "5px" }}><br /> Client ref: ' + (order.clientRefNumber ? order.clientRefNumber : "") + ' <br />Sub mode: ' + subModeData.find((sub) => order.subModeId == sub.id).name;
        mbody += '<br /> <br /> <b>Customer Info</b><div style="width: 100%; margin: 10px; margin-top: 0; padding: 0">Name: ' + order.consignee.companyName + '<br />  Address: ' + (order.consignee.address1 ? order.consignee.address1 : "") + ' ' + (order.consignee.address2 ? order.consignee.address2 : "") + '<br />  City: ' + (order.consignee.city ? order.consignee.city : "") + '<br />  State: ' + (order.consignee.state ? order.consignee.state : "") + '<br />  Country: ' + countryOfOriginData.find((ctr) => ctr.id == order.consignee.countryId).name + '<br />  Phone: ' + (order.consignee.phone ? order.consignee.phone : "") + ' <br />  E-Mail : ' + (order.consignee.email ? order.consignee.email : "") + '</div>';
        mbody += '<br /> <b>Shipper Info</b><div style="width: 100%; margin: 10px; margin-top: 0; padding: 0"> Name: ' + order.shipper.companyName + '<br />  Address: ' + (order.shipper.address1 ? order.shipper.address1 : "") + ' ' + (order.shipper.address2 ? order.shipper.address2 : "") + '<br />  City: ' + (order.shipper.city ? order.shipper.city : "") + '<br />  State: ' + (order.shipper.state ? order.shipper.state : "") + '<br />  Country: ' + countryOfOriginData.find((ctr) => ctr.id == order.shipper.countryId).name + ' <br />  Phone: ' + (order.shipper.phone ? order.shipper.phone : "") + ' <br />  E-Mail : ' + (order.shipper.email ? order.shipper.email : "") + '</div> </div>';
        mbody += '<div style="width:100%; display:flex; background: yellow; margin-top: 15px"><p style="margin: 10px">' + (mailBody ? mailBody : "") + "</p></div> <br/><br/> Regards.";
*/
        var mbody = '<div style="width: 100%; text-align: center"><img src="cid:header"/></div>';
        mbody += '<br /> <div style="text-align: center; width: 100%"><b>' + (order.locationId === "2" ? "Export " : "Import ") + ' JOB ORDER No. - ' + order.jobNumber + ' </b></div> <br /><br />';
        mbody += '<br /> <b style="text-decoration: underline">Shipper</b> <b>: <b/>' + order.shipper.companyName + '<br/>';
        
        mbody += `<div style="width: 100%; font-size: 18px; font-weight: bold; font-color: black"><br /><b style="background-color: yellow">`;
        mbody += order.orderStage[4]?.departureStage[0]?.dep ?
            'GOODS ON BOARD</b><br /><b style="background: yellow"> We are pleased to inform you that shipment has sailed on the below vessel:</b> '
            : 'BOOKING DETAILS</b> <br/><b style="background-color: yellow"> Please see below planned schedule:</b>';
        mbody += '<br /><br /><b style="color: blue">Vessel: ' + (order?.orderStage[4]?.departureStage[0]?.vesselNumber ? order?.orderStage[4]?.departureStage[0]?.vesselNumber : "") + '</b><br />';
        mbody += '<b style="color: blue">ETD: ' + (order?.orderStage[4]?.departureStage[0]?.etd ? formatDateMonthYear(order?.orderStage[4]?.departureStage[0]?.etd) : "") + '</b><br />';
        mbody += '<b style="color: blue">ETA: ' + (order?.orderStage[4]?.departureStage[0]?.eta ? formatDateMonthYear(order?.orderStage[4]?.departureStage[0]?.eta) : "") + '</b><br /><br />';
        //mbody += (!arrived ? '<br /><b style="font-size:large; background: yellow">Arrived to W/H eta: ' + formatDateTime(wheta) + "</b>" : "<br /><b>recieved time : " + formatDateTime(whrec) + "</b>");
        //mbody += (!eta ? '<br /><b style="font-size:large; background: yellow">ETA of order: ' + formatDateTime(eta) + "</b>" : "<br/>");
        mbody += '<b style="background-color: yellow">' + (mailBody ? getComments(mailBody) : "") + "</b><br/><br/>";
        mbody += '<table style="width:461.25pt;margin-left:.5pt;border-collapse:collapse;border:none"><tr><td width="615" colspan="4" style="width:461.25pt;border:solid black 1.0pt;border-bottom:solid windowtext 1.0pt;background:lightgrey;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt; text-align: center;">SHIPPING INFORMATION </td></tr>';
        mbody += '<tr><td width="150" valign="top" style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Consignee Reference</td>';
        mbody += '<td width="139" valign="top" style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + (order.clientRefNumber ? order.clientRefNumber : "") + '</td><td style="background: lightgrey; border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Origin</td>';
        mbody += '<td width="154" valign="top" style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + countryOfOriginData.find((ctr) => ctr.id == order.countryOfOriginId)?.name + '</td></tr><tr>';
        mbody += '<td width="172" valign="top" style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Shipper reference</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + (order.suppliersPO ? order.suppliersPO : "") + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Port of loading</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getFrom(order.polId, order.shippingModeId, false) + '</td></tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">No. of packs</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getTotalPieces() + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Port of discharge</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getTo(order.poaId, order.shippingModeId) + '</td>';
        mbody += '</tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Kind of packages</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getKindOfPieces() + '</td>';
        mbody += '<td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Shipment Method</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getType(order.shippingModeId) + '</td>';
        mbody += '</tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Weight Kg</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getTotalWeight() + '</td>';
        mbody += '<td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Shipment Terms</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + incotermsData.find(dat => dat.id == order.incotermsId)?.name + '</td>';
        mbody += '</tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Volume CBM.</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getChWeight() + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Consignee VAT.</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + (order.consignee.uatnumber ? order.consignee.uatnumber : "") + '</td></tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Type</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getCargoType(subModeData.find(dat => dat.id == order.subModeId)?.name) + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt"></td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt"></td></tr></table>';
        mbody += "<br/><br/> Regards.<br/>" + loginUser.fullName + ", <br/>" + (validateEmail(loginUser.username) ? loginUser.username : "");

        var toList = [];
        if (mtoCust)
            toList.push(mtoCust);
        if (mtoCust2)
            toList.push(mtoCust2);
        if (mtoCust3)
            toList.push(mtoCust3);
        if (mtoCust4)
            toList.push(mtoCust4);
        if (mtoCust5)
            toList.push(mtoCust5);
        if (mtoagent)
            toList.push(mtoagent);
        if (mtoagent2)
            toList.push(mtoagent2);
        if (mtoagent3)
            toList.push(mtoagent3);
        if (mtoagent4)
            toList.push(mtoagent4);
        if (mtoagent5)
            toList.push(mtoagent5);
        if (addto)
            toList.push(addto);
        console.log(toList.toString());
        var mailData = { to: toList.toString(), subject: mailSubject, content: mbody, cc: validateEmail(loginUser.username) ? loginUser.username : "", orderId: order.id };
        sendRequest(`/api/fa/sendMail`, REQUEST_ACTIONS.POST, mailData, {
            successCallback: response => {
                if (response === "SUCCESS")
                    dispatch(snackbarToggle({ type: 'success', message: "Mail was sent successfully." }));
                else
                    dispatch(snackbarToggle({ type: 'error', message: "Mail not sent: " + response }));
            },
            failedCallback,
        });
    }
    const validateEmail = (email) => {
        if (!email)
            return false;
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const onHandleCloseDialog = () => {
        dispatch(resetDepartureStage());
        setOpenDialog(false);
    };

    useEffect(() => {
        // predefined user abroad data get
        if (!nlUsers || nlUsers == undefined) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setNlUsers(response); },
                failedCallback,
            });
        }
    }, [nlUsers, failedCallback, setNlUsers]);

    useEffect(() => {
        // predefined user abroad data get
        if (!usUsers || usUsers == undefined) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setUSUsers(response); },
                failedCallback,
            });
        }
    }, [usUsers, failedCallback, setUSUsers]);

    useEffect(() => {
        // predefined user local data get
        if (!ilUsers || ilUsers == undefined) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setIlUsers(response); },
                failedCallback,
            });
        }
    }, [ilUsers, failedCallback, setIlUsers]);

    useEffect(() => {
        if (!departureStatuses) {
            // predefined departure statuses data get
            sendRequest('/api/stage/5', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { response.splice(0, 1); dispatch(onDepartureStatusesChange(response)); },
                failedCallback,
            });
        }

        if (!shippingLineNames) {
            // predefined departure statuses data get
            sendRequest('/api/serviceProvider/6?activeFlag=true', REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    dispatch(onShippingLineNamesChange(response));
                    const shippingLineNamesOptions = response.map(company => {
                        return {
                            id: company.companyName,
                            name: company.companyName,
                            shippingLineNumber: company.shippingLineNumber,
                            scac: company.scac,
                            serviceProviderLocationId: company.serviceProviderLocationId,
                        };
                    });
                    dispatch(onShippingLineNamesOptionChange(shippingLineNamesOptions));
                },
                failedCallback,
            });
        }

    }, [dispatch, failedCallback, departureStatuses, shippingLineNames]);

    useEffect(() => {
        setIsLoaded(!!departureStatuses);
        setIsLoaded(!!shippingLineNames);
    }, [departureStatuses, shippingLineNames]);

    useEffect(() => {
        setShippingLineNumber(shippingLineNames?.find(shippingLineObj => shippingLineObj.companyName === shippingLineName)?.shippingLineNumber);
    }, [shippingLineName]);

    useEffect(() => {
        const departureStage = currentStage.departureStage[0];

        dispatch(onStageStatusInfoIdChange(currentStage.stageStatusInfoId));
        dispatch(onNotesChange(currentStage.notes));

        if (departureStage?.id) {
            dispatch(onIdChange(departureStage.id));
            dispatch(onCutOffTimeChange(departureStage.cutOffTime));
            dispatch(onWHRECTimeChange(departureStage.whrec));
            dispatch(onWHETATimeChange(departureStage.wheta));
            dispatch(onETATimeChange(departureStage.eta));
            dispatch(onETDTimeChange(departureStage.etd));
            dispatch(onUpdateTimeChange(departureStage.updateTime));
            dispatch(onRefNumberChange(departureStage.refNumber));
            dispatch(onMasterChange(departureStage.master));
            dispatch(onHouseChange(departureStage.house));
            dispatch(onFileNumberChange(departureStage.fileNumber));
            dispatch(onShipNameChange(departureStage.shipName));
            dispatch(onImoChange(departureStage.imo));
            dispatch(onContainerNumberChange(departureStage.containerNumber));
            dispatch(onBookingNumberChange(departureStage.bookingNumber));
            dispatch(onVesselNumberChange(departureStage.vesselNumber));
            dispatch(onShippingLineNameChange(departureStage.shippingLineName));
            dispatch(onAirlineChange(departureStage.airline));
            dispatch(onFlightNumberChange(departureStage.flightNumber));
            dispatch(onTelexMBLChange(departureStage.telexMbl));
            dispatch(onTelexHBLChange(departureStage.telexHbl));
            dispatch(onUrgentShipmentStatusChange(departureStage.urgentShipmentStatus));
            dispatch(onAWBChange(departureStage.awb));
            dispatch(onDepartureDateChange(departureStage.departureDate));
            dispatch(onInvoiceUploadedChange(departureStage.invoiceUploaded));
            dispatch(onFileCloseChange(departureStage.fileClose));
            dispatch(onPreAlertFileChange(departureStage.preAlertFile));
            dispatch(onArrivedChange(departureStage.arrived));
            dispatch(onPartialShipmentChange(departureStage.partialShipment));
            dispatch(onCutoffChange(departureStage.cutoff));
            dispatch(onDEPChange(departureStage.dep));
        }
    }, [dispatch]);
    const checkInvoiceUploaded = () => {
        if (order.codeFileCount) {
            var len = order.codeFileCount.length;
            for (var i = 0; i < len; i++) {
                if (order.codeFileCount[i].code === "INVOICE /PACKING LIST")
                    return false;
            }
        }
        return true;
    }
    const polText = getFrom(order.polId, order.shippingModeId, true);
    const podText = getTo(order.poaId, order.shippingModeId);
    // create/save stage
    const onStageHandleSave = id => {
        if (shipName) {
            var val = validateSCAC(shipName);
            if (val) {
                dispatch(snackbarToggle(val));
                return;
            }
        }
        if (containerNumber) {
            var val = validateContainerNum(containerNumber);
            if (val) {
                dispatch(snackbarToggle(val));
                return;
            }
        }
        if (awb && order.shippingModeId == 1) {
            var val = validateAWB(awb);
            if (val) {
                dispatch(snackbarToggle(val));
                return;
            }
        }
        const departureStage = [
            {
                refNumber,
                master,
                fileNumber,
                cutoff,
                dep,
                house,
                updateTime,
                cutOffTime: cutOffTime || order.orderStage[3].whStageStage[0]?.cutOffTime,
                wheta: wheta,
                whrec: whrec,
                eta: eta || order.orderStage[3].whStageStage[0]?.eta,
                etd: etd || order.orderStage[3].whStageStage[0]?.etdTime,
                shipName,
                imo,
                containerNumber,
                bookingNumber,
                vesselNumber,
                shippingLineName,
                airline,
                awb,
                telexMbl,
                telexHbl,
                urgentShipmentStatus,
                flightNumber,
                departureDate: departureDate || order.orderStage[3].whStageStage[0]?.eta,
                invoiceUploaded: invoiceUploaded,
                fileClose: fileClose,
                preAlertFile: preAlertFile,
                arrived: arrived,
                partialShipment: partialShipment,
            },
        ];
        //if (departureStage[0].master == null || departureStage[0].master === "") {
        //    dispatch(snackbarToggle({ type: 'error', message: 'Master field is empty!' }));
        //    return;
        //}
        if (departureStage[0]?.fileNumber) {
            if (departureStage[0]?.fileNumber?.length < 6 || departureStage[0]?.fileNumber?.length > 15) {
                dispatch(snackbarToggle({ type: 'error', message: "File Number field's length should be between 6 and 15." }));
                return;
            }
        }
        /*if (departureStage[0].house == null || departureStage[0].house === "") {
            dispatch(snackbarToggle({ type: 'error', message: "House field is empty!" }));
            return;
        }*/

        if (id) {
            departureStage[0].id = id;
        }

        const newOrder = {
            ...order,
            freeDays: freeDays,
            orderStage: [
                order.orderStage[0],
                order.orderStage[1],
                order.orderStage[2],
                order.orderStage[3],
                {
                    ...currentStage,
                    notes,
                    stageStatusInfoId,
                    departureStage,
                },
            ],
        };
        if (newOrder.orderStage[4].status === "NOT_STARTED" && newOrder.orderStage[2].status === "SUCCESS") {
            newOrder.orderStage[4].status = "IN_PROGRESS";
        }
        if (newOrder.orderStage[4].stageStatusInfoId == null) {
            dispatch(snackbarToggle({ type: 'error', message: 'Choose status!' }));
            return;
        }
        newOrder.orderStage[4].departureStage[0].fileNumber = fileNumber;

        /* if (order?.orderStage[4]?.stageStatusInfoId != newOrder?.orderStage[4]?.stageStatusInfoId) {
           newOrder.chat = addStatusChatMessage(order, 'Departure', departureStatuses.find(status => status.id === newOrder?.orderStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
         }*/

        sendRequest(`/api/order?fromStage=5&loginUserId=` + loginUser?.id + `&actionOn=DepartureDialog`, REQUEST_ACTIONS.PUT, newOrder, {
            successCallback: () => {
                sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, null)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));

                        if (order?.orderStage[4]?.stageStatusInfoId != newOrder?.orderStage[4]?.stageStatusInfoId) {
                            //newOrder.chat = addStatusChatMessage(order, 'WH', 'Arrived', ilUsers, nlUsers, usUsers, loginUser);
                            var chatString = getStatusChatMessage(order, 'Departure', departureStatuses.find(status => status.id === newOrder?.orderStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
                            onUpdateChat(order.id, chatString, 5)
                        } else {
                            setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                            onHandleCloseDialog();
                        }
                    },
                    failedCallback,
                });
                //dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));


                /**/
            },
            failedCallback,
        });
    };



    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateChat/${id}?fromStage=${fromStage}&loginUserId=` + loginUser?.id + `&actionOn=DepartureDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, null)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                        onHandleCloseDialog();
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };
    return (
        <Dialog maxWidth='md' open={openDialog} onHandleCancel={onHandleCloseDialog} title={'PORT - ' + order?.jobNumber}>
            <Box component='form' noValidate autoComplete='off'>
                {!isLoaded ? (
                    <Grid container justifyContent='center' mt={2}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        {/* <DateTimePicker
              label='Cut off time'
              value={cutOffTime || order.orderStage[3].whStageStage[0]?.cutOffTime}
              onChange={date => dispatch(onCutOffTimeChange(date?.toISOString()))}
            /> */}
                        {/*<SelectField
                            disabled={!!(currentStage.status === STATUS['2'])}
                            label='Status'
                            lg={null}
                            value={stageStatusInfoId || 5}
                            onChange={event => onHandleChange(event, onStageStatusInfoIdChange)}
                            options={departureStatuses}
                        />*/}
                        <Grid container item spacing={2}>
                            <DateTimePicker
                                label='Update time'
                                value={updateTime}
                                onChange={date => dispatch(onUpdateTimeChange(date?.toISOString()))}
                            />
                            <DateTimePicker
                                required
                                label='ETD'
                                value={etd || order.orderStage[3].whStageStage[0]?.etdTime}
                                onChange={date => dispatch(onETDTimeChange(date?.toISOString()))}
                            />
                            <DateTimePicker
                                required
                                label='ETA'
                                value={eta || order.orderStage[3].whStageStage[0]?.eta}
                                onChange={date => dispatch(onETATimeChange(date?.toISOString()))}
                            />
                        </Grid>

                        {<Grid container item spacing={2}>
                            <DateTimePicker
                                label='W/H ETA'
                                value={wheta}
                                onChange={date => dispatch(onWHETATimeChange(date?.toISOString()))}
                            />
                            <DateTimePicker
                                label='W/H Recieved'
                                value={whrec}
                                onChange={date => dispatch(onWHRECTimeChange(date?.toISOString()))}
                            />
                            <DateTimePicker
                                label='Cutoff'
                                value={cutoff}
                                onChange={date => dispatch(onCutoffChange(date?.toISOString()))}
                            />
                            {<FormControlLabel style={{ marginLeft: "5px" }}
                                control={
                                    <Checkbox checked={(arrived != null) ? arrived : false}
                                        onChange={event => onHandleCheckboxChange(event, onArrivedChange)} />
                                }
                                label='Arrived'
                            />}
                            <FormControlLabel style={{ marginLeft: "5px" }}
                                control={
                                    <Checkbox checked={(partialShipment != null) ? partialShipment : false}
                                        onChange={event => onHandleCheckboxChange(event, onPartialShipmentChange)} />
                                }
                                label='Partial Shipment'
                            />
                            <FormControlLabel style={{ marginLeft: "5px" }}
                                control={
                                    <Checkbox checked={(dep != null) ? dep : false}
                                        onChange={event => onHandleCheckboxChange(event, onDEPChange)} />
                                }
                                label='DEP'
                            />
                        </Grid>}
                            {<Grid container item spacing={2}>
                                <TextField
                                    readOnly
                                    lg={null}
                                    label='POL'
                                    value={polText}
                                />
                                <TextField
                                    readOnly
                                    lg={null}
                                    label='POD'
                                    value={podText}
                                />
                            </Grid>}
                        <Grid container item spacing={2}>
                            <TextField
                                lg={null}
                                label='MAWB'
                                value={awb}
                                onChange={event => {
                                    onHandleChangeValidation(event, onAWBChange, validateAWB);
                                    onHandleChange(event, onMasterChange);
                                }}
                            />
                            {/*value={master}
                                    onChange={event => onHandleChange(event, onMasterChange)}*/}
                            <TextField
                                lg={null}
                                label='HAWB'
                                value={house}
                                onChange={event => onHandleChange(event, onHouseChange)}
                            />
                        </Grid>

                        {order.shippingModeId == 1 ?
                            (<Grid container item spacing={2}>
                                <TextField
                                    required
                                    lg={null}
                                    label='Airline'
                                    value={airline}
                                    onChange={event => onHandleChange(event, onAirlineChange)}
                                />
                                <TextField
                                    required
                                    lg={null}
                                    label='Flight Number'
                                    value={flightNumber}
                                    onChange={event => onHandleChange(event, onFlightNumberChange)}
                                />
                                {/*<TextField
                                    required
                                    lg={null}
                                    label='AWB'
                                    value={awb}
                                    onChange={event => onHandleChangeValidation(event, onAWBChange, validateAWB)}
                                />*/}
                            </Grid>) :
                            (<Grid container item spacing={2}>
                                <TextField
                                    lg={null}
                                    label='SCAC (actual shipping line)'
                                    value={shipName}
                                    onChange={event => onHandleChangeValidation(event, onShipNameChange, validateSCAC)}
                                />
                                <TextField
                                    lg={null}
                                    label='IMO'
                                    value={imo}
                                    onChange={event => onHandleChange(event, onImoChange)}
                                />
                                <TextField
                                    lg={null}
                                    label='Container Number'
                                    value={containerNumber}
                                    onChange={event => onHandleChangeValidation(event, onContainerNumberChange, validateContainerNum)}
                                />
                                </Grid>)
                            }
                            {order.shippingModeId == 3 && <TextField
                                lg={null}
                                label='Free Days'
                                type="number"
                                value={freeDays}
                                onChange={event => {
                                    if (event.target.value <= 30 && event.target.value >= 0)
                                        setFreeDays(event.target.value)
                                }}
                            />}
                        {<Grid container item spacing={2}>
                            {false && <DateTimePicker
                                label='Departure ETA'
                                value={departureDate || order.orderStage[3].whStageStage[0]?.eta}
                                onChange={date => dispatch(onDepartureDateChange(date?.toISOString()))}
                                />}
                                <FormControlLabel style={{ marginLeft: "5px" }}
                                    control={
                                        <Checkbox checked={(invoiceUploaded != null) ? invoiceUploaded : false} onChange={event => {
                                            onHandleCheckboxChange(event, onInvoiceUploadedChange);
                                        }} />
                                    }
                                    label={"Invoice Issued"}
                                />
                                <FormControlLabel style={{ marginLeft: "5px" }}
                                    control={
                                        <Checkbox checked={(fileClose != null) ? fileClose : false} onChange={event => {
                                            onHandleCheckboxChange(event, onFileCloseChange);
                                        }} />
                                    }
                                    label={"Close File"}
                                />
                            <FormControlLabel style={{ marginLeft: "5px" }}
                                control={
                                    <Checkbox checked={(preAlertFile != null) ? preAlertFile : false} onChange={event => onHandleCheckboxChange(event, onPreAlertFileChange)} />
                                }
                                label='Pre Alert File Issued'
                            />
                            {order.shippingModeId != 1 && <FormControlLabel style={{ marginLeft: "5px" }}
                                control={
                                    <Checkbox checked={(telexMbl != null) ? telexMbl : false} onChange={event => onHandleCheckboxChange(event, onTelexMBLChange)} />
                                }
                                label='Telex MBL'
                            />}
                            {order.shippingModeId != 1 && <FormControlLabel style={{ marginLeft: "5px" }}
                                control={
                                    <Checkbox checked={(telexHbl != null) ? telexHbl : false} onChange={event => onHandleCheckboxChange(event, onTelexHBLChange)} />
                                }
                                label='Telex HBL'
                            />}
                            <FormControlLabel style={{ marginLeft: "5px" }}
                                control={
                                    <Checkbox checked={(urgentShipmentStatus != null) ? urgentShipmentStatus : false} onChange={event => onHandleCheckboxChange(event, onUrgentShipmentStatusChange)} />
                                }
                                label='Urgent Shipment'
                            />
                        </Grid>}
                        {(order.shippingModeId == 2 || order.shippingModeId == 3) &&
                            (
                                <Grid container item spacing={2}>
                                    <TextField
                                        lg={null}
                                        label='Booking Number'
                                        value={bookingNumber}
                                        onChange={event => onHandleChange(event, onBookingNumberChange)}
                                    />
                                    <TextField
                                        lg={null}
                                        label='Vessel Name'
                                        value={vesselNumber}
                                        onChange={event => onHandleChange(event, onVesselNumberChange)}
                                    />
                                </Grid>
                            )
                        }
                        {(order.shippingModeId == 2 || order.shippingModeId == 3) &&
                            (
                                <Grid container item spacing={2}>
                                    <SelectField
                                        label='Shipping Line Name'
                                        lg={null}
                                        value={shippingLineName}
                                        onChange={event => onHandleShippingChange(event, onShippingLineNameChange)}
                                        options={[{ id: null, name: 'Reset' }, ...shippingLineNamesOption]}
                                    />
                                    <TextField
                                        lg={null}
                                        label='Shipping Line Number'
                                        value={shippingLineNumber}
                                        readonly={true}
                                    />
                                </Grid>
                            )}
                        <MultilineField
                            xs={12}
                            sm={null}
                            md={null}
                            lg={null}
                            label='Notes'
                            value={notes}
                            onChange={event => onHandleChange(event, onNotesChange)}
                        /><Grid container item lg={12}>
                            {showMail && <Grid container item lg={12}>
                                <TextField
                                    lg={12}
                                    required
                                    dissabled
                                    style={{ padding: "5px" }}
                                    type="Text"
                                    label='Subject'
                                    value={mailSubject}
                                />
                                <Grid container item lg={12}>
                                    <Grid lg={12}>
                                        <br /> <div style={{ textAlign: "center", width: "100%" }}><b>{order.locationId === "2" ? "Export " : "Import "} JOB ORDER No. - {order.jobNumber}</b></div> <br /><br />
                                            <b style={{textDecoration: "underline"}}>Shipper: </b> {order.shipper.companyName}<br />
                                            {/*<b>Date: </b> {getTodayDate()}<br />*/}<br />
                                        {/*<table style={{ width: "100%" }}>
                                            <tr>
                                                <td style={{ background: "lightgrey", width: "25%" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Shipper:</b></td>
                                                <td>{order?.shipper?.companyName}
                                                    {order?.shipper?.address1 && <br></br>}{order?.shipper?.address1 ? order?.shipper?.address1 : ""}
                                                    {order?.shipper?.address2 && <br></br>}{order?.shipper?.address2 ? order?.shipper?.address2 : ""}
                                                    {shipperCountry && <br></br>}{shipperCountry ? shipperCountry : ""}
                                                    {order?.shipper?.city && <br></br>}{order?.shipper?.city ? order?.shipper?.city : ""}
                                                    {order?.shipper?.zipCode && <br></br>}{order?.shipper?.zipCode ? order?.shipper?.zipCode : ""}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Contact:</b></td>
                                                <td>{order?.shipper?.name ? order?.shipper?.name : ""}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Email:</b></td>
                                                <td>
                                                    {order?.shipper?.email ? order?.shipper?.email : ""}{order?.shipper?.email && <br></br>}
                                                    {order?.shipper?.email2 ? order?.shipper?.email2 : ""}{order?.shipper?.email2 && <br></br>}
                                                    {order?.shipper?.email3 ? order?.shipper?.email3 : ""}{order?.shipper?.email3 && <br></br>}
                                                    {order?.shipper?.email4 ? order?.shipper?.email4 : ""}{order?.shipper?.email4 && <br></br>}
                                                    {order?.shipper?.email5 ? order?.shipper?.email5 : ""}{order?.shipper?.email5 && <br></br>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Tel:</b></td>
                                                <td>{getShipperPhone(order.shipper)}</td>
                                            </tr>
                                        </table>
                                        <br />
                                        <table style={{ width: "100%" }}>
                                            <tr>
                                                <td style={{ background: "lightgrey", width: "25%" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Consignee:</b></td>
                                                <td>{order?.consignee?.companyName}
                                                    {order?.consignee?.address1 && <br></br>}{order?.consignee?.address1 ? order?.consignee?.address1 : ""}
                                                    {order?.consignee?.address2 && <br></br>}{order?.consignee?.address2 ? order?.consignee?.address2 : ""}
                                                    {consigneeCountry && <br></br>}{consigneeCountry ? consigneeCountry : ""}
                                                    {order?.consignee?.city && <br></br>}{order?.consignee?.city ? order?.consignee?.city : ""}
                                                    {order?.consignee?.zipCode && <br></br>}{order?.consignee?.zipCode ? order?.consignee?.zipCode : ""}
                                                </td>
                                            </tr>
                                        </table>*/}
                                        {<Grid container item lg={12}>
                                            <br />
                                                {!order?.orderStage[4]?.departureStage[0]?.dep ? <div style={{ width: "100%", fontSize: "18px", fontWeight: "bold" }}>
                                                    <br />
                                                    <a style={{ background: "yellow" }}>BOOKING DETAILS</a><br />
                                                    <a style={{ background: "yellow" }}>Please see below planned schedule:</a> <br /> <br />
                                                    <a style={{ color: "blue" }}>Vessel: {order?.orderStage[4]?.departureStage[0]?.vesselNumber}</a><br />
                                                    <a style={{ color: "blue" }}>ETD: {formatDateMonthYear(order?.orderStage[4]?.departureStage[0]?.etd)}</a><br />
                                                    <a style={{ color: "blue" }}>ETA: {formatDateMonthYear(order?.orderStage[4]?.departureStage[0]?.eta)}</a><br />
                                                </div> : <div style={{ width: "100%", fontSize: "18px", fontWeight: "bold" }}>
                                                        <br />
                                                        <a style={{ background: "yellow" }}>GOODS ON BOARD</a><br />
                                                        <a style={{ background: "yellow" }}>We are pleased to inform you that shipment has sailed on the below vessel:</a>
                                                        <br /><br />
                                                        <a style={{ color: "blue" }}>Vessel: {order?.orderStage[4]?.departureStage[0]?.vesselNumber}</a><br />
                                                        <a style={{ color: "blue" }}>ETD: {formatDateMonthYear(order?.orderStage[4]?.departureStage[0]?.etd)}</a><br />
                                                        <a style={{ color: "blue" }}>ETA: {formatDateMonthYear(order?.orderStage[4]?.departureStage[0]?.eta)}</a><br />
                                                </div>}
                                            <br />
                                            Regards.
                                        </Grid>}
                                        <Grid continer lg={12} item style={{ margin: "5px" }}>
                                            <MultilineField
                                                required
                                                xs={12}
                                                sm={null}
                                                md={null}
                                                lg={null}
                                                label='Comments'
                                                value={mailBody}
                                                onChange={event => setMailBody(event.target.value)}
                                            />
                                        </Grid>
                                        <table style={{ width: "100%" }}>
                                            <tr style={{ background: "lightgrey", width: "100%", textAlign: "center" }}>SHIPPING INFORMATION </tr>
                                        </table>
                                        <table style={{ width: "100%" }}>
                                            <tr>
                                                <td style={{ background: "lightgrey", width: "25%" }}>Consignee Reference</td>
                                                <td style={{ width: "25%" }}>{order.clientRefNumber}</td>
                                                <td style={{ background: "lightgrey", width: "25%" }}>Origin</td>
                                                <td style={{ width: "25%" }}>{countryOfOriginData.find((ctr) => ctr.id == order.countryOfOriginId)?.name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}>Shipper reference</td>
                                                <td>{order.suppliersPO}</td>
                                                <td style={{ background: "lightgrey" }}>Port of loading</td>
                                                <td>{getFrom(order.polId, order.shippingModeId, false)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}>No. of packs</td>
                                                <td>{getTotalPieces()}</td>
                                                <td style={{ background: "lightgrey" }}>Port of discharge</td>
                                                <td>{getTo(order.poaId, order.shippingModeId)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}>Kind of packages</td>
                                                <td>{getKindOfPieces()}</td>
                                                <td style={{ background: "lightgrey" }}>Shipment Method</td>
                                                <td>{getType(order.shippingModeId)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}>Weight Kg</td>
                                                <td>{getTotalWeight()}</td>
                                                <td style={{ background: "lightgrey" }}>Shipment Terms</td>
                                                <td>{incotermsData.find(dat => dat.id == order.incotermsId)?.name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}>Volume CBM.</td>
                                                <td>{getChWeight()}</td>
                                                <td style={{ background: "lightgrey" }}>Consignee VAT.</td>
                                                <td>{order.consignee.uatnumber}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "lightgrey" }}>Type</td>
                                                    <td>{getCargoType(subModeData.find(dat => dat.id == order.subModeId)?.name)}</td>
                                                <td style={{ background: "lightgrey" }}></td>
                                                <td></td>
                                            </tr>
                                        </table>
                                    </Grid>
                                </Grid>
                                <Grid container item lg={12}>
                                    <br />
                                    Regards.
                                </Grid>
                                <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <TextField
                                        lg={12}
                                        style={{ padding: "5px", marginTop: "20px" }}
                                        type="Text"
                                        label='Additional E-Mails'
                                        value={mailToAdd}
                                        onChange={event => setMailToAdd(event.target.value)}
                                    />
                                    {!useMailToAdd && <Grid container item lg={12} style={{ color: "red" }}> * Additional emails are invalid.</Grid>}
                                </Grid>

                                <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent} onChange={event => useMailToAgent ? setUseMailToAgent(false) : setUseMailToAgent(true)} />
                                            }
                                            label={"Broker email: " + mailToAgent}
                                        />
                                    </view>
                                </Grid>
                                {!mailToAgentValidation && <Grid container item lg={12} style={{ color: "red" }}> * Broker email for: {brokerName} is invalid.</Grid>}

                                {mailToAgent2 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent2} onChange={event => useMailToAgent2 ? setUseMailToAgent2(false) : setUseMailToAgent2(true)} />
                                            }
                                            label={"Broker email2: " + mailToAgent2}
                                        />
                                    </view>
                                </Grid>}
                                {(mailToAgent2 && !mailToAgentValidation2) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email2 for: {brokerName} is invalid.</Grid>}

                                {mailToAgent3 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent3} onChange={event => useMailToAgent3 ? setUseMailToAgent3(false) : setUseMailToAgent3(true)} />
                                            }
                                            label={"Broker email 3: " + mailToAgent3}
                                        />
                                    </view>
                                </Grid>}
                                {(mailToAgent3 && !mailToAgentValidation3) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email3 for: {brokerName} is invalid.</Grid>}

                                {mailToAgent4 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent4} onChange={event => useMailToAgent4 ? setUseMailToAgent4(false) : setUseMailToAgent4(true)} />
                                            }
                                            label={"Broker email 4: " + mailToAgent4}
                                        />
                                    </view>
                                </Grid>}
                                {(mailToAgent4 && !mailToAgentValidation4) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email4 for: {brokerName} is invalid.</Grid>}

                                {mailToAgent5 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent5} onChange={event => useMailToAgent5 ? setUseMailToAgent5(false) : setUseMailToAgent5(true)} />
                                            }
                                            label={"Broker email5: " + mailToAgent5}
                                        />
                                    </view>
                                </Grid>}
                                {(mailToAgent5 && !mailToAgentValidation5) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email5 for: {brokerName} is invalid.</Grid>}

                                    {order?.consignee?.email && <Grid container lg={12}>
                                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={useMailToCustomer} onChange={event => useMailToCustomer ? setUseMailToCustomer(false) : setUseMailToCustomer(true)} />
                                                }
                                                label={"Consignee email: " + order?.consignee?.email}
                                            />
                                        </view>
                                    </Grid>}
                                    {(order?.consignee?.email && !mailToCustomerValidation) && <Grid container item style={{ color: "red" }}>* Consignee email for: {order.consignee.companyName} is invalid.</Grid>}

                                    {order?.consignee?.email2 && <Grid container lg={12}>
                                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={useMailToCustomer2} onChange={event => useMailToCustomer2 ? setUseMailToCustomer2(false) : setUseMailToCustomer2(true)} />
                                                }
                                                label={"Consignee email2: " + order?.consignee?.email2}
                                            />
                                        </view>
                                    </Grid>}
                                    {(order?.consignee?.email2 && !mailToCustomerValidation2) && <Grid container item style={{ color: "red" }}>* Consignee email2 for: {order.consignee.companyName} is invalid.</Grid>}

                                    {order?.consignee?.email3 && <Grid container lg={12}>
                                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={useMailToCustomer3} onChange={event => useMailToCustomer3 ? setUseMailToCustomer3(false) : setUseMailToCustomer3(true)} />
                                                }
                                                label={"Consignee email3: " + order.consignee.email3}
                                            />
                                        </view>
                                    </Grid>}
                                    {(order?.consignee?.email3 && !mailToCustomerValidation3) && <Grid container item style={{ color: "red" }}>* Consignee email3 for: {order.consignee.companyName} is invalid.</Grid>}

                                    {order?.consignee?.email4 && <Grid container lg={12}>
                                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={useMailToCustomer4} onChange={event => useMailToCustomer4 ? setUseMailToCustomer4(false) : setUseMailToCustomer4(true)} />
                                                }
                                                label={"Consignee email 4: " + order.consignee.email4}
                                            />
                                        </view>
                                    </Grid>}
                                    {(order?.consignee?.email4 && !mailToCustomerValidation4) && <Grid container item style={{ color: "red" }}>* Consignee email4 for: {order.consignee.companyName} is invalid.</Grid>}

                                    {order?.consignee?.email5 && <Grid container lg={12}>
                                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={useMailToCustomer5} onChange={event => useMailToCustomer5 ? setUseMailToCustomer5(false) : setUseMailToCustomer5(true)} />
                                                }
                                                label={"Consignee email 5: " + order.consignee.email5}
                                            />
                                        </view>
                                    </Grid>}
                                    {(order?.consignee?.email5 && !mailToCustomerValidation5) && <Grid container item style={{ color: "red" }}>* Consignee email5 for: {order.consignee.companyName} is invalid.</Grid>}
                                <Grid container lg={12}>
                                    <Button
                                        variant='contained'
                                        style={{ margin: "15px", marginLeft: "0", maxWidth: "150px" }}
                                        onClick={sendMail}>
                                        Send Mail
                                    </Button>
                                </Grid>
                            </Grid>}
                        </Grid>
                        <Grid container item justifyContent='flex-end'>
                            <Grid container lg={6}>
                                <Button
                                    variant='contained'
                                    style={{ margin: "15px", marginLeft: "0", maxWidth: "150px" }}
                                    onClick={setShowMail}>
                                    Mail
                                </Button>
                            </Grid>
                            <Grid container lg={6} justifyContent="right" alignItems="center">
                                <Button
                                    variant='contained'
                                    style={{ marginRight: "16px", maxHeight: "37px" }}
                                    onClick={id ? () => onStageHandleSave(id) : () => onStageHandleSave(null)}>
                                    Save
                                </Button>
                                <Button variant='contained' color='secondary'
                                    style={{ marginRight: 2, maxHeight: "37px" }} onClick={onHandleCloseDialog}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Dialog>
    );
};

export default DepartureDialog;
