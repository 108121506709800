import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import {  Grid } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TextButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Orders from './components/Order/Orders';
import Parcels from './components/Parcel/Orders';
import HistoryOrders from './components/Order/HistoryOrders';
import ParcelHistoryOrders from './components/Parcel/HistoryOrders';
import Manifest from './components/Parcel/Manifest/Manifest'
import ManifestHistory from './components/Parcel/Manifest/ManifestHistory'
import CustomerOrders from './components/Order/CustomerOrders';
import ServiceProvider from 'serviceProvider/ServiceProvider';
import ChatListDialog from './components/Order/ChatListDialog';
import ParcelChatListDialog from './components/Parcel/ChatListDialog';
import Tracker from 'shipTracker/Tracker';
import Users from './users/Users';
import OrderStagesTimeline from './components/orderStagesTimeline/OrderStagesTimeline';
import './MainPage.scss';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import PredefinedDataSettings from 'components/settings/PredefinedDataSettings';
// import { snackbarToggle } from 'reducers/snackbarSlicer';
import CustomerConatiner from 'components/customer/customerConatiner/CustomerConatiner';
import CustomerDashboard from 'components/customer/dashboard/CustomerDashboard';
import CustomerShipments from 'components/customer/shipments/CustomerShipments';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { Dialog } from 'style-guide';
import AddUser from './users/AddUser';
import AuditLogs from './audit/AuditLogs';
import { useDispatch } from 'react-redux';
import { getOrders, onParcelPageChange } from 'reducers/parcel-reducers/ordersSlicer';
import { onPageChange } from 'reducers/order-reducers/ordersSlicer';
const MainPage = () => {
    const dispatch = useDispatch();
    const { selectedLocationIndex } = useSelector(state => state.orderTableFilter);
    const [openChatNotificationDialog, setOpenChatNotificationDialog] = useState(false);
    const onHandleOpenChatNotificationDialog = () => setOpenChatNotificationDialog(true);
    const onHandleCancelChatNotificationDialog = () => setOpenChatNotificationDialog(false);

    const [isUsTime, setIsUsTime] = useState(false);
    const [openProfileDialog, setOpenProfileDialog] = useState(false);
    const onHandleOpenProfileDialog = () => { handleClose(); setOpenProfileDialog(true); }
    const onHandleCancelProfileDialog = () => setOpenProfileDialog(false);

    const [openAuditLogsDialog, setOpenAuditLogsDialog] = useState(false);
    const onHandleOpenAuditLogsDialog = () => { handleClose(); setOpenAuditLogsDialog(true); }
    const onHandleCancelAuditLogsDialog = () => setOpenAuditLogsDialog(false);



    const [openUsersDialog, setOpenUsersDialog] = useState(false);
    const onHandleOpenUsersDialog = () => { handleClose(); setOpenUsersDialog(true); }
    const onHandleCancelUsersDialog = () => {
        //console.log(window.location)
        setOpenUsersDialog(false);
        if (window.location.pathname === '/home') {
            navigate("/settings/users")
            //console.log(selectedLocationIndex + "   selectedLocationIndex")
            window.setTimeout(function () {
                navigate("/home", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Orders" } })
            }, 0)
        }
    }

    const [openCRMDialog, setOpenCRMDialog] = useState(false);
    const onHandleOpenCRMDialog = () => { handleClose(); setOpenCRMDialog(true); }
    const onHandleCancelCRMDialog = () => {
        setOpenCRMDialog(false);       
        if (window.location.pathname === '/home') {
            navigate("/settings/serviceProviders")
            //console.log(selectedLocationIndex + "   selectedLocationIndex")
            window.setTimeout(function () {
                navigate("/home", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Orders" } })
            }, 0)
        }



    }

    const [openStageTmDialog, setOpenStageTmDialog] = useState(false);
    const onHandleOpenStageTmDialog = () => { handleClose(); setOpenStageTmDialog(true); }
    const onHandleCancelStageTmDialog = () => {
        setOpenStageTmDialog(false);
        if (window.location.pathname === '/home') {
            navigate("/settings/stagesTimeline")
            //console.log(selectedLocationIndex + "   selectedLocationIndex")
            window.setTimeout(function () {
                navigate("/home", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Orders" } })
            }, 0)
        }
    }

    const [openMoreSettingDialog, setOpenMoreSettingDialog] = useState(false);
    const onHandleOpenMoreSettingDialog = () => { handleClose(); setOpenMoreSettingDialog(true); }
    const onHandleCancelMoreSettingDialog = () => {
        setOpenMoreSettingDialog(false);
        if (window.location.pathname === '/home') {
            navigate("/settings/moreSettings")
            //console.log(selectedLocationIndex + "   selectedLocationIndex")
            window.setTimeout(function () {
                navigate("/home", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Orders" } })
            }, 0)
        }
    }


    const [openTrackerDialog, setOpenTrackerDialog] = useState(false);
    const onHandleOpenTrackerDialog = () => { handleClose(); setOpenTrackerDialog(true); }
    const onHandleCancelTrackerDialog = () => {
        setOpenTrackerDialog(false);
        
        if (window.location.pathname === '/home') {
            navigate("/settings/Tracker")
            //console.log(selectedLocationIndex + "   selectedLocationIndex")
            window.setTimeout(function () {
                navigate("/home", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Orders" } })
            }, 0)
        }
    }

    const [jobSearch, setJobSearch] = useState("");
    const [mapDisplay, setMapDisplay] = useState(false);
    const [searchText, setSearchText] = useState("");

    const [anchorEl, setAnchorEl] = useState(null);
    const { chatCount } = useSelector(state => state.orders);
    const { chatCountC } = useSelector(state => state.orders);
    const { chatCountT } = useSelector(state => state.orders);
    const { chatCountCT } = useSelector(state => state.orders);
    const { chatListNotification } = useSelector(state => state.orders);
    const getChatCount = () => {
        return chatListNotification.length;
        return chatCount + chatCountT + chatCountC + chatCountCT;
        if (getUserInfo("department") == 1 || getUserInfo("department") == null || getUserInfo("department")==="")
            return chatCount;
        if (getUserInfo("department") == 2)
            return chatCountT;
        if (getUserInfo("department") == 3)
            return chatCountC;
    }
    const open = Boolean(anchorEl);

    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [userInfo, setUserInfo] = new useState(null);

    const getUserInfo = attrName => {
        if (userInfo !== null) {
            let userInfoObj = JSON.parse(userInfo);
            return userInfoObj[attrName];
        }
        return '';
    };

    const getUserInfoJson = () => {
        if (userInfo !== null) {
            let userInfoObj = JSON.parse(userInfo);
            return userInfoObj;
        }
        return '';
    };
    if (location.state && location.state.isLogout === true) {
        // For logout/from new login, Should invalidate the user info object.
        setUserInfo(null);
        location.state.isLogout = false;
        location.state.isNewUser = false;
    }

    if (userInfo === null) {
        // Get the user info from server.
        // If the server returned 401, redirect to Login the page.
        sendRequest('/api/loggedInUser', REQUEST_ACTIONS.POST, '', {
            successCallback: response => {
                setUserInfo(JSON.stringify(response));
                let userInfoObj = JSON.parse(JSON.stringify(response));
                let authorities = userInfoObj['authorities'];
                if (authorities && authorities[0].authority !== 'Customer' && location.pathname.indexOf('/home') === -1) {
                    if (location.pathname === '/phome') {
                        navigate('/phome', { state: { userInfo: userInfo } });
                    } else if (location.pathname === '/orderHistory') {
                        navigate('/orderHistory' + location.search, { state: { userInfo: userInfo, searchParam: searchParam } });
                    } else {
                        navigate('/home', { state: { userInfo: userInfo } });
                    }
                } else if (authorities && authorities[0].authority === 'Customer' && location.pathname.indexOf('/settings/Tracker') === -1 && location.pathname.indexOf('/customer/dashboard') === -1) {
                    navigate('/customer/dashboard', { state: { userInfo: userInfo } });
                }
            },
            failedCallback: error => {
                if (error.status === 401 || error.status === 403) {
                    setUserInfo('');
                    if (location.pathname !== '/login') {
                        navigate('/login');
                    }
                }
            },
        });
    }
    const doLogout = () => {
        sendRequest('/api/logout', REQUEST_ACTIONS.POST, null, {
            successCallback: () => {
                dispatch(getOrders([]));
                navigate('/login');
            },
            failedCallback: error => {
                console.error(error);
            },
        });
    };

    const StyledMenu = styled(props => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            minWidth: 180,
            color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                },
            },
        },
    }));
    const IlTime = new Date().toLocaleString("en-GB", { timeZone: 'Israel' }).split(",");
    // eslint-disable-next-line no-unused-vars
    const NlTime = new Date().toLocaleString("en-EN", { timeZone: 'Europe/Amsterdam' }).split(",");

    const Il_Time = new Date().toLocaleString("en-EN", { timeZone: 'Israel' });
    const Nl_Time = new Date().toLocaleString("en-EN", { timeZone: 'Europe/Amsterdam' });
    const Us_Time = new Date().toLocaleString("en-EN", { timeZone: 'PST' });

    const IL_Time = new Date(Il_Time);
    const NL_Time = new Date(Nl_Time);
    const US_Time = new Date(Us_Time);

    const toolbarStyle = {
        minHeight: 50,
        color: 'black',
        width: 'calc(100vw - 600px)',
        justifyContent: 'flex-end'
    };

    const pathname = window.location.pathname;

    return (
        <React.Fragment>
            {userInfo && (
                <React.Fragment>
                    {pathname !== '/customer/dashboard' && pathname !== '/customer/shipments' ?
                        <div style={{margin:"3px"}}>
                            <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDgpzrxExQg_AUW8h199onppaSz72SYlSY" />
                            <AppBar
                                position='static'
                                elevation={0}
                                className='appbar'
                                style={{
                                    marginBottom: 0,
                                    backgroundColor: 'white',
                                    color: 'black',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    width: 'calc(100vw - 350px)',
                                    justifyContent: "space-between"
                                }}>
                                {IlTime[0] + ", IL:" + IL_Time.getHours() + ":" + IL_Time.getMinutes() + ", US:" + US_Time.getHours() + ":" + US_Time.getMinutes()}
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority != 'Customer' ? (
                                    <Toolbar style={toolbarStyle}>
                                        <TextButton sx={{ mr: 6 }} onClick={handleClick} style={{ fontSize: 17, color: '#1976d2' }}>
                                            Settings
                                        </TextButton>
                                    </Toolbar>
                                ) : <Toolbar style={toolbarStyle} />}

                            </AppBar>
                            {getUserInfo('authorities') && getUserInfo('authorities')[0].authority === 'User' && (
                                <div className='logged-in-user-container' style={{ top: '10px' }}>
                                    <IconButton aria-label='Unread chat messages' onClick={onHandleOpenChatNotificationDialog}>
                                        <Badge badgeContent={getChatCount()} color='primary' showZero max={999}>
                                            <MarkUnreadChatAltIcon />
                                        </Badge>
                                    </IconButton>
                                </div>)}
                            {openChatNotificationDialog && (
                                <Dialog
                                    open={openChatNotificationDialog}
                                    onHandleCancel={onHandleCancelChatNotificationDialog}
                                    title='Chat Notifications'
                                    maxWidth='lg'>
                                    {location.pathname === '/phome' ? <ParcelChatListDialog loginUser={getUserInfoJson()} chatCount={getChatCount()} /> : <ChatListDialog onHandleCancelChatNotificationDialog={onHandleCancelChatNotificationDialog} setSearchText={setSearchText} loginUser={getUserInfoJson()} chatCount={getChatCount()} />}
                                </Dialog>
                            )}
                            {getUserInfo('authorities') && getUserInfo('authorities')[0].authority === 'User' ?
                                (<div className='logged-in-user-container' style={{ right: '75px' }}>
                                    <div className='dropdown'>
                                        Welcome,
                                        <button className='dropbtn'>{getUserInfo('username')}</button>
                                        <div className='dropdown-content'>
                                            <span onClick={doLogout}>Logout</span>
                                        </div>
                                    </div>
                                </div>) :
                                (<div className='logged-in-user-container'>
                                    <div className='dropdown'>
                                        Welcome,
                                        <button className='dropbtn'>{getUserInfo('username')}</button>
                                        <div className='dropdown-content'>
                                            <span onClick={doLogout}>Logout</span>
                                        </div>
                                    </div>
                                </div>)}
                            {openAuditLogsDialog && (
                                <Dialog
                                    open={openAuditLogsDialog}
                                    onHandleCancel={onHandleCancelAuditLogsDialog}
                                    title='Audit Logs'
                                    fullWidth='true'
                                    maxWidth='lg'>
                                    <AuditLogs />
                                </Dialog>
                            )}
                            {openProfileDialog && (
                                <Dialog
                                    open={openProfileDialog}
                                    onHandleCancel={onHandleCancelProfileDialog}
                                    title='Profile'
                                    maxWidth='lg'>
                                    <AddUser data={getUserInfoJson()} onHandleCancel={onHandleCancelProfileDialog} id={null} viewProfile={true} />
                                </Dialog>
                            )}
                            {openUsersDialog && (
                                <Dialog
                                    open={openUsersDialog}
                                    onHandleCancel={onHandleCancelUsersDialog}
                                    title='Users'
                                    maxWidth='lg'>
                                    <Users />
                                </Dialog>
                            )}
                            {openCRMDialog && (
                                <Dialog
                                    open={openCRMDialog}
                                    onHandleCancel={onHandleCancelCRMDialog}
                                    title='CRM'
                                    maxWidth='lg'>
                                    <ServiceProvider loginUser={getUserInfoJson()} />
                                </Dialog>
                            )}
                            {openStageTmDialog && (
                                <Dialog
                                    open={openStageTmDialog}
                                    onHandleCancel={onHandleCancelStageTmDialog}
                                    title='Order Stages Timeline'
                                    maxWidth='lg'>
                                    <OrderStagesTimeline />
                                </Dialog>
                            )}

                            {openMoreSettingDialog && (
                                <Dialog
                                    open={openMoreSettingDialog}
                                    onHandleCancel={onHandleCancelMoreSettingDialog}
                                    title='More Settings'
                                    maxWidth='lg'>
                                    <PredefinedDataSettings loginUser={getUserInfoJson()} />
                                </Dialog>
                            )}

                            { openTrackerDialog && (
                                <Dialog
                                    open={openTrackerDialog}
                                    onHandleCancel={onHandleCancelTrackerDialog}
                                    title='Tracker'
                                    maxWidth='1400px'>
                                    <Tracker />
                                </Dialog>
                            ) }

                            <StyledMenu
                                id='demo-customized-menu'
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClick={handleClose}>

                                {/*getUserInfo('authorities') && getUserInfo('authorities')[0].authority === 'User' ? (
                                    <MenuItem onClick={onHandleOpenProfileDialog}>Profile</MenuItem>
                                ) : null */}

                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority === 'Admin' && <TextButton onClick={onHandleOpenAuditLogsDialog} style={{ fontSize: 17, color: 'black' }}>
                                    Audit Logs
                                </TextButton>
                                }
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority === 'User' && <TextButton onClick={onHandleOpenProfileDialog} style={{ fontSize: 17, color: 'black' }}>
                                    Profile
                                </TextButton>
                                }
                                <br/>
                                {/*{getUserInfo('authorities') && getUserInfo('authorities')[0].authority === 'Admin' ? (
              <Link to='/settings/users' style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }} >
                <MenuItem onClick={handleClose}>Users</MenuItem> 
              </Link>
            ) : null}*/}
                                {/*<Grid container justifyContent="flex-start">*/}
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority === 'Admin' && <TextButton onClick={onHandleOpenUsersDialog} style={{ fontSize: 17, color: 'black' }}>
                                    Users
                                </TextButton>
                                }
                               {getUserInfo('authorities') && getUserInfo('authorities')[0].authority === 'Admin' &&  <br/>}
                               {/* <Link to='/settings/serviceProviders' style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                                    <MenuItem onClick={handleClose}>CRM</MenuItem> 
                                </Link>*/}
                                <TextButton onClick={onHandleOpenCRMDialog} style={{ fontSize: 17, color: 'black' }}>
                                    CRM
                                </TextButton>
                                <br />
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' &&<TextButton onClick={onHandleOpenStageTmDialog} style={{ fontSize: 17, color: 'black' }}>
                                    Order Stages Timeline
                                </TextButton>
                                }
                                <br />
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' &&<TextButton onClick={onHandleOpenMoreSettingDialog} style={{ fontSize: 17, color: 'black' }}>
                                    More Settings
                                </TextButton>
                                }
                                {/*<br />
                                
                                { getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' && <TextButton onClick={onHandleOpenTrackerDialog} style={{ fontSize: 17, color: 'black', width:"!00%" }}>
                                    Tracker
                                </TextButton>
                                }*/}

                               {/* <Link to='/settings/stagesTimeline' style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                                    <MenuItem onClick={handleClose}>Order Stages Timeline</MenuItem>
                                </Link>*/}
                                {/*<Link to='/settings/moreSettings' style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                                    <MenuItem onClick={handleClose}>More Settings</MenuItem>
                                </Link>*/}
                                {/*<Link to='/settings/Tracker' style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                                    <MenuItem onClick={handleClose}>Tracker</MenuItem>
                                </Link>*/}
                                   {/* </Grid>*/}
                            </StyledMenu>
                            <Routes>
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' ? (
                                    <Route exact path='/home' element={<Orders isTrackerLoaded={false} setIsUsTime={setIsUsTime} redboxParcels={function () {
                                        //navigate('/phome');
                                        window.location.assign(window.location.origin + "/phome")
                                    }} agentOrders={function () {
                                        // navigate('/home');
                                        window.location.assign(window.location.origin + "/home")
                                        }} loginUser={getUserInfoJson()} key="orders" setSearchText={setSearchText} searchText={searchText} />} />
                                ) : null}{
                                    getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' ? (
                                        <Route exact path='/tracker' element={<Orders isTrackerLoaded={true} setIsUsTime={setIsUsTime} redboxParcels={function () {
                                        //navigate('/phome');
                                        window.location.assign(window.location.origin + "/phome")
                                    }} agentOrders={function () {
                                        // navigate('/home');
                                        window.location.assign(window.location.origin + "/home")
                                    }} loginUser={getUserInfoJson()} key="orders" />} />
                                ) : null}
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' ? (
                                    <Route exact path='/phome' element={<Parcels setIsUsTime={setIsUsTime} redboxParcels={function () {
                                        //  navigate('/phome');
                                        window.location.assign(window.location.origin + "/phome")
                                        //console.log(window.location)
                                    }} agentOrders={function () {
                                        //navigate('/home');
                                        window.location.assign(window.location.origin + "/home")
                                    }} loginUser={getUserInfoJson()} key="Parcels"/>} />
                                ) : null}
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' ? (
                                    <Route exact path='/dhome' element={<Parcels setIsUsTime={setIsUsTime} redboxParcels={function () {
                                        //  navigate('/phome');
                                        window.location.assign(window.location.origin + "/phome")
                                        //console.log(window.location)
                                    }} agentOrders={function () {
                                        //navigate('/home');
                                        window.location.assign(window.location.origin + "/home")
                                    }} loginUser={getUserInfoJson()} key="drops" />} />
                                ) : null}

                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' ? (
                                    <Route path='/orderHistory' element={<HistoryOrders setIsUsTime={setIsUsTime} redboxParcels={function () {
                                        //  navigate('/phome');
                                        window.location.assign(window.location.origin + "/phome")
                                        //console.log(window.location)
                                    }} agentOrders={function () {
                                        //navigate('/home');
                                        window.location.assign(window.location.origin + "/home")
                                    }} loginUser={getUserInfoJson()} />} key="ordersHistory"/>
                                ) : null}

                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' ? (
                                    <Route exact path='/parcelHistory'  element={<ParcelHistoryOrders setIsUsTime={setIsUsTime} redboxParcels={function () {
                                        //  navigate('/phome');
                                        window.location.assign(window.location.origin + "/phome")
                                    }} agentOrders={function () {
                                        //navigate('/home');
                                        window.location.assign(window.location.origin + "/home")
                                    }} loginUser={getUserInfoJson()} key="parcelsHistory" />} />
                                ) : null}
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' ? (
                                    <Route exact path='/dropHistory'  element={<ParcelHistoryOrders setIsUsTime={setIsUsTime} redboxParcels={function () {
                                        //  navigate('/phome');
                                        window.location.assign(window.location.origin + "/phome")
                                    }} agentOrders={function () {
                                        //navigate('/home');
                                        window.location.assign(window.location.origin + "/home")
                                    }} loginUser={getUserInfoJson()} key="dropHistory" />} />
                                ) : null}
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' && !(getUserInfo('authorities') && getUserInfo('authorities')[0]?.authority === 'User' && getUserInfo('location')?.code === 'IL') ? (
                                    <Route exact path='/manifest' element={<Manifest loginUser={getUserInfoJson()}/>} />
                                ) : null}
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' ? (
                                    <Route exact path='/manifestHistory' element={<ManifestHistory loginUser={getUserInfoJson()}/>} />
                                ) : null}
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' ? (
                                    <Route exact path='/settings/serviceProviders' element={<ServiceProvider loginUser={getUserInfoJson()} />} />
                                ) : null}

                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority === 'Admin' ? (
                                    <Route exact path='/settings/users' element={<Users />} />
                                ) : null}

                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' ? (
                                    <Route exact path='/settings/stagesTimeline' element={<OrderStagesTimeline />} />
                                ) : null}

                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority !== 'Customer' ? (
                                    <Route exact path='/settings/moreSettings' element={<PredefinedDataSettings loginUser={getUserInfoJson()} />} />
                                ) : null}

                                <Route exact path='/settings/Tracker' element={<Tracker />} />

                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority === 'Customer' ? (
                                    <Route exact path='/customerOrders' element={<CustomerOrders loginUser={getUserInfoJson()} />} />
                                ) : null}
                            </Routes>
                        </div>
                        : <CustomerConatiner loginUser={getUserInfoJson()} logoutUser={doLogout} jobSearch={jobSearch} setJobSearch={setJobSearch} mapDisplay={mapDisplay} setMapDisplay={setMapDisplay}>
                            <Routes>
                                {getUserInfo('authorities') && getUserInfo('authorities')[0].authority === 'Customer' ? (
                                    <React.Fragment>
                                        <Route exact path='/customer/dashboard' element={<CustomerDashboard loginUser={getUserInfoJson()} jobSearch={jobSearch} setJobSearch={setJobSearch} mapDisplay={mapDisplay} setMapDisplay={setMapDisplay} />} />
                                        <Route exact path='/customer/shipments' element={<CustomerShipments loginUser={getUserInfoJson()} jobSearch={jobSearch} setJobSearch={setJobSearch} mapDisplay={mapDisplay} setMapDisplay={setMapDisplay} />} />
                                    </React.Fragment>
                                ) : null}
                            </Routes>
                        </CustomerConatiner>}

                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MainPage;
