/* eslint-disable react/prop-types */
import { Box, Button, List, ListItem, Menu, MenuItem, Grid, InputAdornment } from "@mui/material";
import { TextField } from 'style-guide';
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './CustomerHeader.scss'
//import SearchIcon from "../../../../resources/search_icon.svg"
// import ChatIcon from "../../../../resources/chat_icon.svg"
// import InsightIcon from "../../../../resources/insight_icon.svg"
import UserIcon from "../../../../resources/user.png"
import map from "../../../../resources/map.png"
import SearchIcon from '@mui/icons-material/Search';
import FaLogo from '../../../../resources/FA.png'
import FaBGVert from '../../../../resources/bgGradVert.png'
import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const CustomerHeader = ({ loginUser, logoutUser, jobSearch, setJobSearch, mapDisplay, setMapDisplay }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const [isToggle, setIsToggle] = new useState(false);
    const [searchKeyWord, setSearchKeyword] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);

    const isDropMenu1 = Boolean(anchorEl1);
    const handleClick1 = (e) => {
        setAnchorEl1(e.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    const isDropMenu = Boolean(anchorEl);
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChangeSearch = (e) => {
        setSearchKeyword(e.target.value)
    };

    const onClickSearch = () => {
        alert(`Not found for "${searchKeyWord}"`)
    }

    return (
        <>{isMobile() ? <header className="customer-header">
            <Grid item container className="tk-good-times" alignItems="center" flexWrap="nowrap" style={{ width: "33vw", marginLeft: "10px" }}>
                <List className="details-list">
                    <ListItem className="list-item">
                        <Button id="user-menu1" className="icon-btn-header-cust" style={{ border: "none", boxShadow: "none" }}
                            aria-controls={isDropMenu1 ? 'dropdowm-menu1' : undefined}
                            aria-haspopup="true"
                            aria-expanded={isDropMenu1 ? 'true' : undefined}
                            onClick={handleClick1}>
                            <MenuIcon fontSize='large' style={{ color: "white" }} />
                        </Button>
                        <Menu
                            style={{ overflow: "hidden" }}
                            id="dropdowm-menu1"
                            anchorEl={anchorEl1}
                            open={isDropMenu1}
                            onClose={handleClose1}
                            MenuListProps={{
                                'aria-labelledby': 'user-menu1',
                            }}
                        >
                            <MenuItem className="menuItem" onClick={() => { navigate('/customer/dashboard'); handleClose1() }}>Dashboard</MenuItem>
                            <MenuItem className="menuItem" onClick={() => { navigate('/customer/shipments'); handleClose1() }}>Shipment</MenuItem>
                        </Menu>
                    </ListItem>
                </List>
                <img src={FaLogo} className="fa-logo" alt="" />
            </Grid>
            <Grid item container className="tk-good-times" justifyContent="flex-end" alignItems="center" flexWrap="nowrap" style={{width:"calc(100% - 33vw)"}}>
                <Box className="customer-details">
                    <List className="details-list">
                        <ListItem className="list-item">
                            <Button id="user-menu" className="icon-btn-header-cust" style={{ border: "none", boxShadow: "none" }}
                                aria-controls={isDropMenu ? 'dropdowm-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={isDropMenu ? 'true' : undefined}
                                onClick={handleClick}>
                                <PersonOutlineIcon style={{color: "#fff"}} />
                                {/*<Box component={'span'}>{loginUser}</Box>*/}
                            </Button>
                            <Menu
                                style={{ overflow: "hidden" }}
                                id="dropdowm-menu"
                                anchorEl={anchorEl}
                                open={isDropMenu}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'user-menu',
                                }}
                            >
                                <MenuItem className="menuItem" onClick={() => logoutUser()}>Logout</MenuItem>
                            </Menu>
                        </ListItem>
                        <Button className="icon-btn-header" style={{ marginLeft: "3vw" }} onClick={() => setMapDisplay(!mapDisplay)}>
                            <img src={map} alt="" />
                            {/*<Box component={'span'}>Map</Box>*/}
                        </Button>
                    </List>
                </Box>
                <SearchIcon className="headerSearchIcon" style={{ marginLeft: "3vw", marginRight: "3vw" }} />
                <TextField
                    xs={4}
                    sm={null}
                    md={null}
                    lg={null}
                    label='SEARCH'
                    type='search'
                    className="tk-good-times searchTextField"
                    value={jobSearch}
                    onChange={event => setJobSearch(event.target.value)}
                />
            </Grid>
        </header> : <header className="customer-header">
            <Grid item container className="tk-good-times" style={{ marginLeft: "5.2vw", width: "18.5vw" }} alignItems="center" flexWrap="nowrap">
                <SearchIcon style={{ width: "1.84vw", height: "auto", color: "#1976d2" }} />
                <TextField
                    xs={12}
                    sm={null}
                    md={null}
                    lg={null}
                    style={{ width: "calc(100% - 1.84vw)", border: "none", height: "2vw", padding: "0", margin: "0" }}
                    label='SEARCH'
                    type='search'
                    className="tk-good-times"
                    value={jobSearch}
                    onChange={event => setJobSearch(event.target.value)}
                />
            </Grid>
            <Box className="customer-details">
                <List className="details-list">
                    <Button className="icon-btn-header" style={{}} onClick={() => setMapDisplay(!mapDisplay)}>
                        <img src={map} alt="" />
                        <Box component={'span'}>Map</Box>
                    </Button>
                    <ListItem className="list-item">
                        <Button id="user-menu" className="icon-btn-header-cust" style={{ border: "none", boxShadow: "none" }}
                            aria-controls={isDropMenu ? 'dropdowm-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={isDropMenu ? 'true' : undefined}
                            onClick={handleClick}>
                            <img src={UserIcon} alt="" />
                            <Box component={'span'}>{loginUser}</Box>
                        </Button>
                        <Menu
                            style={{ overflow: "hidden" }}
                            id="dropdowm-menu"
                            anchorEl={anchorEl}
                            open={isDropMenu}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'user-menu',
                            }}
                        >
                            <MenuItem className="menuItem" onClick={() => logoutUser()}>Logout</MenuItem>
                        </Menu>
                    </ListItem>
                </List>
            </Box>
        </header>}</>
    )
};

export default CustomerHeader;