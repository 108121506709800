import { useEffect, useCallback, useState } from 'react';

import { Box, Grid, Button, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import { SelectField, TextField, DateTimePicker, MultilineField, Dialog } from 'style-guide';

import { STATUS } from 'constants/global-constants';
import { REQUEST_ACTIONS, sendRequest, setOrderData } from 'utils/Communicator';

import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from 'reducers/order-reducers/ordersSlicer';
import {
    resetDepartureStage,
    onStageStatusInfoIdChange,
    onCutOffTimeChange,
    onUpdateTimeChange,
    onRefNumberChange,
    onNotesChange,
    onMasterChange,
    onHouseChange,
    onIdChange,
    onETATimeChange,
    onETDTimeChange,
    onDepartureStatusesChange,
    onShippingLineNamesOptionChange,
    onShippingLineNamesChange,
    onShipNameChange,
    onImoChange,
    onContainerNumberChange,
    onBookingNumberChange,
    onVesselNumberChange,
    onShippingLineNameChange,
    onAirlineChange,
    onFlightNumberChange,
    onAWBChange,
    onDepartureDateChange,
    onInvoiceUploadedChange,
} from 'reducers/stage-reducers/departureStageSlicer';
import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    DirectionsRenderer,
    Polyline,
} from '@react-google-maps/api'
import {
    onAirPoaDataChange,
    onAirPolDataChange,
    onLclPoaDataChange,
    onLclPolDataChange,
} from 'reducers/parcel-reducers/predefinedDataSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { addOrderFilter, addSearchFilter, addStatusChatMessage, getStatusChatMessage } from 'Utils';
const PolDialog = ({ onHandleCancel, loginUser, order, openDialog, setOpenDialog, pageName }) => {
    const dispatch = useDispatch();

    const [approveFlag, setApproveFlag] = useState(order.airStatus?.polExit ? order.airStatus?.polExit : false);
    const [customsDateOrg, setCustomsDateOrd] = useState(order.airStatus?.polDate ? order.airStatus?.polDate : (order.orderStage[4]?.departureStage[0]?.etd ? order.orderStage[4]?.departureStage[0].etd : null));
    const [customsDate, setCustomsDate] = useState(order.airStatus?.polDate ? (order.airStatus?.polDate.includes(" 1970") ? "" : order.airStatus?.polDate) : (order.orderStage[4]?.departureStage[0]?.etd ? order.orderStage[4]?.departureStage[0].etd : null));
    const [actualPOLArrivalDate, setActualPOLArrivalDate] = useState(order.polArrivalDate);
    const [polDelayHistory, setPolDelayHistory] = useState(order.polDelayHistory);

    const { incotermsData, airPolData, airPoaData, lclPolData, lclPoaData, countryOfOriginData, subModeData, fclType20Data, fclType40Data } =
        useSelector(state => state.predefinedData);
    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const [mapPath, setMapPath] = useState([]);
    const [maplat1, setMaplat1] = useState(31);
    const [maplat2, setMaplat2] = useState(33);
    const [maplng1, setMaplng1] = useState(33);
    const [maplng2, setMaplng2] = useState(35);
    const [mapZoom, setMapZoom] = useState(2);
    const [mapX, setMapX] = useState({ lat: 32, lng: 34 });
    const onHandleCheckboxChange = (event, action) => dispatch(action(event.target.checked));
    const onHandleChange = (event, action) => dispatch(action(event.target.value));
    const [mapRef, setMapRef] = useState();
    const [map, setMap] = useState(/** @type google.maps.Map */(null));
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDgpzrxExQg_AUW8h199onppaSz72SYlSY",
        libraries: ['places'],
    })
    const lineSymbol = {
        path: 'M 0,-1 0,1',
        scale: 2,
        strokeColor: 'blue',
        strokeOpacity: 1,
    }
    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        customerId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId, userTracer, userCustoms,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        search,
        urgentShipment,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.orderTableFilter);
    const { page, size } = useSelector(state => state.orders);
    const {
        id,
        stageStatusInfoId,
        departureStatuses,
        shippingLineNames,
        shippingLineNamesOption,
        cutOffTime,
        updateTime,
        refNumber,
        master,
        house,
        notes,
        eta,
        etd,
        shipName,
        imo,
        containerNumber,
        bookingNumber,
        vesselNumber,
        shippingLineName,
        airline,
        flightNumber,
        awb,
        departureDate,
        invoiceUploaded,
    } = useSelector(state => state.departureStage);

    const [isDialogLoaded, setIsLoaded] = useState(false);

    const [shippingLineNumber, setShippingLineNumber] = useState('');

    useEffect(() => {
        var date;
        if (!isNaN(customsDate))
            date = new Date(Number(customsDate));
        else
            date = new Date(customsDate);
        if (date.getFullYear() > 2000)
            setCustomsDate(date);
    }, []);
    const failedCallback = useCallback(message => function () {
        dispatch(snackbarToggle({ type: 'error', message }))
    }, [dispatch]);

    const currentStage = order.orderStage[4];

    const [historyNotes, setHistoryNotes] = useState(false);

    const [brokerNames, setBrokerNames] = useState(false);
    const [brokerName, setBrokerName] = useState(false);
    const [brokers, setBrokers1] = useState(false);
    const [fileNumber, setFileNumber] = useState(order.fileNumber ? order.fileNumber : "");

    const setBrokers = (data) => {
        setBrokers1(data);
        const brokerNameOptions = data?.map(broker => ({
            id: broker.id,
            name: broker.companyName,
            serviceProviderLocationId: broker.serviceProviderLocationId,
        }));
        var customerEmail = "";
        var customerEmail2 = "";
        var customerEmail3 = "";
        var customerEmail4 = "";
        var customerEmail5 = "";
        var customerCompanyName = "";
        if (order?.customer?.id) {
            customerEmail = data.find(dat => dat.id == order.customer.id).email
            customerEmail2 = data.find(dat => dat.id == order.customer.id).email2
            customerEmail3 = data.find(dat => dat.id == order.customer.id).email3
            customerEmail4 = data.find(dat => dat.id == order.customer.id).email4
            customerEmail5 = data.find(dat => dat.id == order.customer.id).email5
            customerCompanyName = data.find(dat => dat.id == order.customer.id).companyName
        }
        setMailToAgent(customerEmail);
        setMailToAgent2(customerEmail2);
        setMailToAgent3(customerEmail3);
        setMailToAgent4(customerEmail4);
        setMailToAgent5(customerEmail5);
        setBrokerNames(brokerNameOptions ? brokerNameOptions : []);
        setBrokerName(customerCompanyName ? customerCompanyName : "");
    }
    useEffect(() => {

        if (!approveFlag)
            setApproveFlag(false);
        // predefined customer data get
        sendRequest('/api/serviceProvider/12?activeFlag=true', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setBrokers(response),
            failedCallback,
        });
        if (getGap() > 0) {
            var notes = polDelayHistory?.split(",");
            var notesText = "";
            if (notes.length > 0) {
                notesText = " " + notes[0];
                if (notes.length > 1) {
                    for (var i = 1; i < notes.length; i++) {
                        notesText += "\n" + notes[i]
                    }
                }
            }
            setHistoryNotes(notesText)
        }
    }, []);

    const isHebrew = (str) => {
        return (/[\u0590-\u05FF]/).test(str);
    }

    const getComments = (str) => {
        var strs1 = [];
        var strs = [];
        strs = str.split("\n");
        for (var i = 0; i < strs.length; i++) {
            //if (isHebrew(strs[i]))
            //    strs1.push(encodeURI(strs[i]));
            //else
                strs1.push(strs[i]);
        }
        return strs1.join("<br/>");
    }


    const getKindOfPieces = () => {
        if (order.shippingModeId == 3) {
            if (order.orderFCLDetails) {
                var fcl20 = "";
                var fcl40 = "";
                if (order.orderFCLDetails?.fclType20Id) {
                    fcl20 = fclType20Data.find(dat => dat.id == order.orderFCLDetails?.fclType20Id)?.name
                }
                if (order.orderFCLDetails?.fclType40Id) {
                    fcl40 = fclType40Data.find(dat => dat.id == order.orderFCLDetails?.fclType40Id)?.name
                }
                if (fcl20 && fcl40)
                    return fcl20 + ", " + fcl40;
                if (fcl20 && !fcl40)
                    return fcl20;
                if (!fcl20 && fcl40)
                    return fcl40;
                return "";
            }
        }
        return "";
    }
    const getTotalPieces = () => {
        var num = 0;
        for (var i = 0; i < order.orderDetails?.length; i++) {
            num += order.orderDetails[i].numberOfPieces;
        }
        if (order.orderFCLDetails?.quantity20) {
            num += order.orderFCLDetails.quantity20;
        }
        if (order.orderFCLDetails?.quantity40) {
            num += order.orderFCLDetails.quantity40;
        }
        return num ? num : "";
    }
    const getTo = (poaId, shippingModeId, bol) => {
        try {
            var str = "";
            if (shippingModeId == 1) {
                str = airPoaData.find(poa => poa.id == poaId)?.name
            }
            else str = lclPoaData.find(poa => poa.id == poaId)?.name
            if (str)
                return str;
            else if (bol)
                str = countryOfOriginData.find((ctr) => ctr.id == order.countryOfOriginId)?.name;
            return str ? str : "";
        } catch (e) {
            return "N/A";
        }
    }
    const getFrom = (polId, shippingModeId) => {
        try {
            if (shippingModeId == 1) {
                return airPolData.find(pol => pol.id == polId).name
            }
            return lclPolData.find(pol => pol.id == polId).name
        } catch (e) {
            return "N/A"
        }
    }
    const getType = (shippingModeId) => {
        try {
            if (shippingModeId == 1) {
                return "Air";
            } else if (shippingModeId == 2) {
                return "LCL";
            } else {
                return "FCL";
            }
        } catch (e) {
            return "N/A"
        }
    }
    const getTotalWeight = () => {
        var wt = 0;
        for (var i = 0; i < order.orderDetails.length; i++) {
            wt += order.orderDetails[i].weightPerPiece != 0 ? (order.orderDetails[i].numberOfPieces * order.orderDetails[i].weightPerPiece) : order.orderDetails[i].totalweight;
        }
        return wt ? wt.toFixed(2) : "";
    }
    const getChWeight = () => {
        var vt = 0;
        var manualwt = 0;
        for (var i = 0; i < order.orderDetails.length; i++) {
            var vol = order.orderDetails[i].manualVolume ? order.orderDetails[i].manualVolume : (order.orderDetails[i]['length'] * order.orderDetails[i].width * order.orderDetails[i].height);
            vt = vt + order.orderDetails[i].numberOfPieces * (vol) / 1000000;
        }
        return vt ? vt.toFixed(2) : "";
    }
    const onHandleCloseDialog = () => {
        dispatch(resetDepartureStage());
        setOpenDialog(false);
    };

    useEffect(() => {
        if (!airPolData) {
            sendRequest('/api/pol/air', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onAirPolDataChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, airPolData]);

    useEffect(() => {
        if (!airPoaData) {
            sendRequest('/api/poa/air', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onAirPoaDataChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, airPoaData]);

    useEffect(() => {
        if (!lclPolData) {
            sendRequest('/api/pol/lcl', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onLclPolDataChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, lclPolData]);

    useEffect(() => {
        if (!lclPoaData) {
            sendRequest('/api/poa/lcl', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onLclPoaDataChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, lclPoaData]);

    useEffect(() => {
        // predefined user abroad data get
        if (!nlUsers || nlUsers == undefined) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setNlUsers(response); },
                failedCallback,
            });
        }
    }, [nlUsers, failedCallback, setNlUsers]);

    useEffect(() => {
        // predefined user abroad data get
        if (!usUsers || usUsers == undefined) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setUSUsers(response); },
                failedCallback,
            });
        }
    }, [usUsers, failedCallback, setUSUsers]);

    useEffect(() => {
        // predefined user local data get
        if (!ilUsers || ilUsers == undefined) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setIlUsers(response); },
                failedCallback,
            });
        }
    }, [ilUsers, failedCallback, setIlUsers]);

    useEffect(() => {
        if (!departureStatuses) {
            // predefined departure statuses data get
            sendRequest('/api/stage/5', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { response.splice(0, 1); dispatch(onDepartureStatusesChange(response)); },
                failedCallback,
            });
        }

        if (!shippingLineNames) {
            // predefined departure statuses data get
            sendRequest('/api/serviceProvider/6?activeFlag=true', REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    dispatch(onShippingLineNamesChange(response));
                    const shippingLineNamesOptions = response.map(company => {
                        return { id: company.companyName, name: company.companyName, serviceProviderLocationId: company.serviceProviderLocationId, };
                    });
                    dispatch(onShippingLineNamesOptionChange(shippingLineNamesOptions));
                },
                failedCallback,
            });
        }

    }, [dispatch, failedCallback, departureStatuses, shippingLineNames]);

    useEffect(() => {
        setIsLoaded(!!departureStatuses);
        setIsLoaded(!!shippingLineNames);
    }, [departureStatuses, shippingLineNames]);

    useEffect(() => {
        setShippingLineNumber(shippingLineNames?.find(shippingLineObj => shippingLineObj.companyName === shippingLineName)?.shippingLineNumber);
    }, [shippingLineName]);

    useEffect(() => {
        var path = [];
        var x1 = -1000;
        var x2 = -1000;
        var y1 = -1000;
        var y2 = -1000;
        if (order.sinay?.status?.val?.routeData?.routeSegments?.length) {
            order.sinay?.status?.val?.routeData?.routeSegments.forEach(item => {
                item?.path.forEach(lt => {
                    path.push(lt);
                    if (lt.lat < x1 || x1 == -1000)
                        x1 = lt.lat;
                    if (lt.lat > x2 || x2 == -1000)
                        x2 = lt.lat;
                    if (lt.lng < y1 || y1 == -1000)
                        y1 = lt.lng;
                    if (lt.lng > y2 || y2 == -1000)
                        y2 = lt.lng;
                });
            });
            setMaplat1(x1 - 1);
            setMaplat2(x2);
            setMaplng1(y1 - 1);
            setMaplng2(y2);
            setMapX(order.sinay?.status?.val?.routeData?.coordinates);
            setMapZoom(3);
        }
        else {
            setMaplat1(31);
            setMaplat2(33);
            setMaplng1(34);
            setMaplng2(36);
            setMapX(order.sinay?.status?.val?.routeData?.coordinates);
            setMapZoom(5);
        }
        setMapPath(path);
        setMailToCustomerValidation(validateEmail(order.consignee?.email))
    }, [order])
    function contains_heb(str) {
        var tes = (/[\u0590-\u05FF]/).test(str);
        return tes;
    }
    const formatDate = (date) => {
        if (!date)
            return "-";
        var str = [];
        if (date.includes("T")) {
            str = date.split("T")[1].split(":");
            var time = str[0] + ":" + str[1];

            const dt = new Date(date);
            const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
            return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)} ${time}`;
        } else {
            return date;
        }
    }
    const formatDateTime = (date) => {
        if (!date)
            return "";
        var dt = new Date(Number(date));
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)} ${padL(dt.getHours())}:${padL(dt.getMinutes())}`;
    }
    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateChat/${id}?fromStage=${fromStage}&loginUserId=` + loginUser?.id + `&actionOn=DepartureDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, null)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                        onHandleCloseDialog();
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };

    const getWeight = (t) => {
        if (t.airStatus?.quantity?.weight) {
            return t.airStatus?.quantity?.weight.unit + ", " + t.airStatus?.quantity?.weight.amount;
        }
        return "-";
    }

    const [showMail, setShowMail1] = useState(false);
    const getCompanyShortName = (company) => {
        if (company?.companyShortName)
            return company.companyShortName?.substring(0, 11).toUpperCase();
        else
            return company.companyName?.substring(0, 11).toUpperCase();
    }

    const setShowMail = () => {
        showMail ? setShowMail1(false) : setShowMail1(true);
        var sub = getType(order.shippingModeId) + " " + getFrom(order.polId, order.shippingModeId, true) + "-" + getTo(order.poaId, order.shippingModeId);
        sub += " | " + getCompanyShortName(order.shipper) + " | " + getCompanyShortName(order.consignee) + ", ref " + order.jobNumber;
        setMailSubject(sub);
    }
    const getTodayDate = () => {
        return new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(new Date())
    }
    const [mailSubject, setMailSubject] = useState("");
    const [mailBody, setMailBody] = useState("");
    const [mailToAdd, setMailToAdd1] = useState("");

    const [useMailToAgent, setUseMailToAgent] = useState(false);
    const [mailToAgent, setMailToAgent1] = useState("");
    const [mailToAgentValidation, setMailToAgentValidation] = useState(true);
    const setMailToAgent = (str) => {
        setMailToAgent1(str);
        setMailToAgentValidation(validateEmail(str));
    }
    const [useMailToAgent2, setUseMailToAgent2] = useState(false);
    const [mailToAgent2, setMailToAgent12] = useState("");
    const [mailToAgentValidation2, setMailToAgentValidation2] = useState(true);
    const setMailToAgent2 = (str) => {
        setMailToAgent12(str);
        setMailToAgentValidation2(validateEmail(str));
    }
    const [useMailToAgent3, setUseMailToAgent3] = useState(false);
    const [mailToAgent3, setMailToAgent13] = useState("");
    const [mailToAgentValidation3, setMailToAgentValidation3] = useState(true);
    const setMailToAgent3 = (str) => {
        setMailToAgent13(str);
        setMailToAgentValidation3(validateEmail(str));
    }
    const [useMailToAgent4, setUseMailToAgent4] = useState(false);
    const [mailToAgent4, setMailToAgent14] = useState("");
    const [mailToAgentValidation4, setMailToAgentValidation4] = useState(true);
    const setMailToAgent4 = (str) => {
        setMailToAgent14(str);
        setMailToAgentValidation4(validateEmail(str));
    }
    const [useMailToAgent5, setUseMailToAgent5] = useState(false);
    const [mailToAgent5, setMailToAgent15] = useState("");
    const [mailToAgentValidation5, setMailToAgentValidation5] = useState(true);
    const setMailToAgent5 = (str) => {
        setMailToAgent15(str);
        setMailToAgentValidation5(validateEmail(str));
    }
    const [useMailToAdd, setUseMailToAdd] = useState(false);
    const setMailToAdd = (str) => {
        setMailToAdd1(str);
        if (str) {
            var strs = str.split(",");
            var valid = true;
            for (var i = 0; i < strs.length; i++) {
                if (!validateEmail(strs[i]))
                    valid = false;
            }
            setUseMailToAdd(valid);
        }
    }
    const [useMailToCustomer, setUseMailToCustomer] = useState(false);
    const [mailToCustomer, setMailToCustomer] = useState(order?.consignee?.email ? order.consignee.email : "");
    const [mailToCustomerValidation, setMailToCustomerValidation] = useState(order?.consignee?.email ? true : false);

    const [useMailToCustomer2, setUseMailToCustomer2] = useState(false);
    const [mailToCustomer2, setMailToCustomer2] = useState(order?.consignee?.email2 ? order.consignee.email2 : "");
    const [mailToCustomerValidation2, setMailToCustomerValidation2] = useState(order?.consignee?.email2 ? true : false);

    const [useMailToCustomer3, setUseMailToCustomer3] = useState(false);
    const [mailToCustomer3, setMailToCustomer3] = useState(order?.consignee?.email3 ? order.consignee.email3 : "");
    const [mailToCustomerValidation3, setMailToCustomerValidation3] = useState(order?.consignee?.email3 ? true : false);

    const [useMailToCustomer4, setUseMailToCustomer4] = useState(false);
    const [mailToCustomer4, setMailToCustomer4] = useState(order?.consignee?.email4 ? order.consignee.email4 : "");
    const [mailToCustomerValidation4, setMailToCustomerValidation4] = useState(order?.consignee?.email4 ? true : false);

    const [useMailToCustomer5, setUseMailToCustomer5] = useState(false);
    const [mailToCustomer5, setMailToCustomer5] = useState(order?.consignee?.email5 ? order.consignee.email5 : "");
    const [mailToCustomerValidation5, setMailToCustomerValidation5] = useState(order?.consignee?.email5 ? true : false);

    const getCargoType = (type) => {
        if (type === "Cargo")
            return "General Cargo";
        return type;
    }
    const sendMail = () => {
        if (useMailToAgent && mailToAgent === "") {
            alert("Please enter agent email.")
            return;
        }
        if (useMailToAgent && !validateEmail(mailToAgent)) {
            alert("Please enter a proper agent email.")
            return;
        }
        if (useMailToCustomer && mailToCustomer === "") {
            alert("Please make sure to have proper consignee email.")
            return;
        }
        if (useMailToCustomer && !validateEmail(mailToCustomer)) {
            alert("Please enter a proper consignee email in CRM.")
            return;
        }
        if (!((useMailToAgent && validateEmail(mailToAgent)) ||
            (useMailToCustomer && validateEmail(mailToCustomer)) || useMailToAdd)) {
            alert("Select atleast one of agent and/or customer and/or additional e-mails should be valid.");
            return;
        }
        var mtoCust = mailToCustomer;
        if (!useMailToCustomer) {
            mtoCust = null;
        }
        var mtoCust2 = mailToCustomer2;
        if (!useMailToCustomer2) {
            mtoCust2 = null;
        }
        var mtoCust3 = mailToCustomer3;
        if (!useMailToCustomer3) {
            mtoCust3 = null;
        }
        var mtoCust4 = mailToCustomer4;
        if (!useMailToCustomer4) {
            mtoCust4 = null;
        }
        var mtoCust5 = mailToCustomer5;
        if (!useMailToCustomer5) {
            mtoCust5 = null;
        }
        var mtoagent = mailToAgent;
        if (!useMailToAgent) {
            mtoagent = null;
        }
        var mtoagent2 = mailToAgent2;
        if (!useMailToAgent2) {
            mtoagent2 = null;
        }
        var mtoagent3 = mailToAgent3;
        if (!useMailToAgent3) {
            mtoagent3 = null;
        }
        var mtoagent4 = mailToAgent4;
        if (!useMailToAgent4) {
            mtoagent4 = null;
        }
        var mtoagent5 = mailToAgent5;
        if (!useMailToAgent5) {
            mtoagent5 = null;
        }
        var addto = mailToAdd;
        if (!useMailToAdd) {
            addto = null;
        }
        var impt = order.locationId === "2" ? "Export" : "Import";
        /*var mbody = '<br /> <div style={{ textAlign: "center", width: "100%" }}><b>' + impt + ' JOB ORDER No. - ' + order.jobNumber + '</b></div><br /><br /><b> From: </b> <br />' + getFrom(order.polId, order.shippingModeId, false) + ' <br /> <br /><b>To: </b> <br />' + getTo(order.poaId, order.shippingModeId) + ' <br /><br /><b>Shipment Details: </b><table style="text-align: center"><tr><th style="text-decoration: underline">Pieces</th><th style="text-decoration: underline; padding: 5px">L(CM)</th><th style="text-decoration: underline; padding: 5px">W(CM)</th><th style="text-decoration: underline; padding: 5px">H(CM)</th><th style="text-decoration: underline; padding: 5px">Weight per piece (KG)</th></tr>';
        for (var i = 0; i < order.orderDetails.length; i++) {
            mbody += "<tr><td>" + order.orderDetails[i].numberOfPieces + "</td><td>" + order.orderDetails[i]["length"] + "</td><td>" + order.orderDetails[i].width + "</td><td>" + order.orderDetails[i].height + "</td><td>" + order.orderDetails[i].weightPerPiece + "</td></tr>";
        }
        mbody += "<tr><td></td><td></td><td></td><td></td><td>Total Weight</td></tr><tr><td></td><td></td><td></td><td></td><td>" + getTotalWeight() + "</td></tr><tr><td></td><td></td><td></td><td></td><td>Chargeable Weight</td></tr><tr><td></td><td></td><td></td><td></td><td>" + getChWeight() + "</td></tr></table> <br/><br/>";
        mbody += '<Grid continer lg={12} item style={{ margin: "5px" }}><br /> Client ref: ' + (order.clientRefNumber ? order.clientRefNumber : "") + ' <br />Sub mode: ' + subModeData.find((sub) => order.subModeId == sub.id).name;
        mbody += '<br /> <br /> <b>Customer Info</b><div style="width: 100%; margin: 10px; margin-top: 0; padding: 0">Name: ' + order.consignee.companyName + '<br />  Address: ' + (order.consignee.address1 ? order.consignee.address1 : "") + ' ' + (order.consignee.address2 ? order.consignee.address2 : "") + '<br />  City: ' + (order.consignee.city ? order.consignee.city : "") + '<br />  State: ' + (order.consignee.state ? order.consignee.state : "") + '<br />  Country: ' + countryOfOriginData.find((ctr) => ctr.id == order.consignee.countryId).name + '<br />  Phone: ' + (order.consignee.phone ? order.consignee.phone : "") + ' <br />  E-Mail : ' + (order.consignee.email ? order.consignee.email : "") + '</div>';
        mbody += '<br /> <b>Shipper Info</b><div style="width: 100%; margin: 10px; margin-top: 0; padding: 0"> Name: ' + order.shipper.companyName + '<br />  Address: ' + (order.shipper.address1 ? order.shipper.address1 : "") + ' ' + (order.shipper.address2 ? order.shipper.address2 : "") + '<br />  City: ' + (order.shipper.city ? order.shipper.city : "") + '<br />  State: ' + (order.shipper.state ? order.shipper.state : "") + '<br />  Country: ' + countryOfOriginData.find((ctr) => ctr.id == order.shipper.countryId).name + ' <br />  Phone: ' + (order.shipper.phone ? order.shipper.phone : "") + ' <br />  E-Mail : ' + (order.shipper.email ? order.shipper.email : "") + '</div> </div>';
        mbody += '<div style="width:100%; display:flex; background: yellow; margin-top: 15px"><p style="margin: 10px">' + (mailBody ? mailBody : "") + "</p></div> <br/><br/> Regards.";
*/
        var mbody = '<div style="width: 100%; text-align: center"><img src="cid:header"/></div>';
        mbody += '<br /> <div style="text-align: center; width: 100%"><b>' + (order.locationId === "2" ? "Export " : "Import ") + ' JOB ORDER No. - ' + order.jobNumber + ' </b></div> <br /><br />';
        mbody += '<b> Date: </b>' + getTodayDate() + ' <br /><br /><table style="border-collapse:collapse"><tr>'
        mbody += '<td style="background: lightgrey; width: 25%"><b style="margin-right: 5px; margin-left: 5px">Shipper:</b></td>';
        mbody += '<td>' + order.shipper.companyName;
        mbody += (order.shipper.address1 ? '<br/>' : '') + (order.shipper.address1 ? order.shipper.address1 : '');
        mbody += (order.shipper.address2 ? '<br/>' : '') + (order.shipper.address2 ? order.shipper.address2 : '');
        mbody += (order.shipper.city ? '<br/>' : '') + (order.shipper.city ? order.shipper.city : '');
        mbody += (order.shipper.zipCode ? '<br/>' : '') + (order.shipper.zipCode ? order.shipper.zipCode : '');
        mbody += '</td></tr><tr><td style="background: lightgrey"><b style="margin-right: 5px; margin-left: 5px">Contact:</b></td>';
        mbody += '<td>' + (order.shipper.name ? order.shipper.name : "") + '</td></tr><tr><td style="background: lightgrey"><b style="marginright: 5px; margin-left: 5px">Email:</b></td>';
        mbody += '<td>';
        mbody += (order.shipper.email ? order.shipper.email : "") + (order.shipper.email ? '<br/>' : '');
        mbody += (order.shipper.email2 ? order.shipper.email2 : "") + (order.shipper.email2 ? '<br/>' : '');
        mbody += (order.shipper.email3 ? order.shipper.email3 : "") + (order.shipper.email3 ? '<br/>' : '');
        mbody += (order.shipper.email4 ? order.shipper.email4 : "") + (order.shipper.email4 ? '<br/>' : '');
        mbody += (order.shipper.email5 ? order.shipper.email5 : "") + (order.shipper.email5 ? '<br/>' : '');
        mbody += '</td></tr><tr><td style="background: lightgrey"><b style="margin-right: 5px; margin-left: 5px">Tel:</b></td>';
        mbody += '<td>' + (order.shipper.mobile ? order.shipper.mobile : "") + '</td></tr></table><br /><table style="border-collapse:collapse"><tr><td style="background: lightgrey; width: 25%">';
        mbody += '<b style="marginright: 5px; margin-left: 5px">Consignee:</b></td ><td>' + order.consignee.companyName;
        mbody += (order.consignee.address1 ? '<br/>' : '') + (order.consignee.address1 ? order.consignee.address1 : '');
        mbody += (order.consignee.address2 ? '<br/>' : '') + (order.consignee.address2 ? order.consignee.address2 : '');
        mbody += (order.consignee.city ? '<br/>' : '') + (order.consignee.city ? order.consignee.city : '');
        mbody += (order.consignee.zipCode ? '<br/>' : '') + (order.consignee.zipCode ? order.consignee.zipCode : '');
        mbody += '</td></tr></table><br/>';
        mbody += '<b style="font-size:large; background: yellow">Departure date was updated to: ' + formatDateTime(customsDate) + '</b><br />';
        mbody += '<b style="font-size:large; background: yellow">' + (mailBody ? getComments(mailBody) : "") + "</b><br/>";
        mbody += '<table style="width:461.25pt;margin-left:.5pt;border-collapse:collapse;border:none"><tr><td width="615" colspan="4" style="width:461.25pt;border:solid black 1.0pt;border-bottom:solid windowtext 1.0pt;background:lightgrey;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt; text-align: center;">SHIPPING INFORMATION </td></tr>';
        mbody += '<tr><td width="150" valign="top" style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Consignee Reference</td>';
        mbody += '<td width="139" valign="top" style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + (order.clientRefNumber ? order.clientRefNumber : "") + '</td><td style="background: lightgrey; border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Origin</td>';
        mbody += '<td width="154" valign="top" style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + countryOfOriginData.find((ctr) => ctr.id == order.countryOfOriginId)?.name + '</td></tr><tr>';
        mbody += '<td width="172" valign="top" style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Shipper reference</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + (order.suppliersPO ? order.suppliersPO : "") + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Port of loading</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getFrom(order.polId, order.shippingModeId, false) + '</td></tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">No. of packs</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getTotalPieces() + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Port of discharge</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getTo(order.poaId, order.shippingModeId) + '</td>';
        mbody += '</tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Kind of packages</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getKindOfPieces() + '</td>';
        mbody += '<td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Shipment Method</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getType(order.shippingModeId) + '</td>';
        mbody += '</tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Weight Kg</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getTotalWeight() + '</td>';
        mbody += '<td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Shipment Terms</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + incotermsData.find(dat => dat.id == order.incotermsId)?.name + '</td>';
        mbody += '</tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Volume CBM.</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getChWeight() + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Consignee VAT.</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + (order.consignee.uatnumber ? order.consignee.uatnumber : "") + '</td></tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Type</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getCargoType(subModeData.find(dat => dat.id == order.subModeId)?.name) + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt"></td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt"></td></tr></table>';
        mbody += "<br/><br/> Regards.<br/>" + loginUser.fullName + ", <br/>" + (validateEmail(loginUser.username) ? loginUser.username : "");

        var status = 'Departure date was updated to: ' + formatDateTime(customsDate) ;
        var comments = (mailBody ? getComments(mailBody) : "");

        var toList = [];
        if (mtoCust)
            toList.push(mtoCust);
        if (mtoCust2)
            toList.push(mtoCust2);
        if (mtoCust3)
            toList.push(mtoCust3);
        if (mtoCust4)
            toList.push(mtoCust4);
        if (mtoCust5)
            toList.push(mtoCust5);
        if (mtoagent)
            toList.push(mtoagent);
        if (mtoagent2)
            toList.push(mtoagent2);
        if (mtoagent3)
            toList.push(mtoagent3);
        if (mtoagent4)
            toList.push(mtoagent4);
        if (mtoagent5)
            toList.push(mtoagent5);
        if (addto)
            toList.push(addto);
        console.log(toList.toString());
        var mailData = { status: status, comments: comments, to: toList.toString(), subject: mailSubject, content: mbody, cc: validateEmail(loginUser.username) ? loginUser.username : "", orderId: order.id };
        //console.log("Send Mail.");
        /*if ((useMailToAgent && mailToAgentValidation) || (useMailToCustomer && mailToCustomerValidation)) {
            sendRequest(`/api/fa/sendMail`, REQUEST_ACTIONS.POST, mailData, {
                successCallback: response => {
                    if (response === "SUCCESS")
                        dispatch(snackbarToggle({ type: 'success', message: "Mail was sent successfully." }));
                    else
                        dispatch(snackbarToggle({ type: 'error', message: "Mail not sent: " + response }));
                },
                failedCallback,
            });
        } else {
            dispatch(snackbarToggle({ type: 'error', message: "Atleast broker or consignee has to have valid email address" }))
        }*/
        sendRequest(`/api/fa/sendMail`, REQUEST_ACTIONS.POST, mailData, {
            successCallback: response => {
                if (response === "SUCCESS")
                    dispatch(snackbarToggle({ type: 'success', message: "Mail was sent successfully." }));
                else
                    dispatch(snackbarToggle({ type: 'error', message: "Mail not sent: " + response }));
            },
            failedCallback,
        });
    }
    const validateEmail = (email) => {
        if (!email)
            return false;
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const saveCustomsData = () => {
        var polHistory = polDelayHistory ? polDelayHistory : "";
        if (polHistory === "null")
            polHistory = "";
        if (customsDateOrg !== customsDate) {
            const today = new Date();
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1;
            let dd = today.getDate();
            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            const date = dd + ':' + mm + ':' + yyyy;

            if (polHistory.length != 0)
                polHistory = date + ": " + getGap() + " " + (getGap() != 1 ? "Days (" + loginUser.fullName + ")" : "Day (" + loginUser.fullName + ")") + ", " + polHistory;
            else
                polHistory = date + ": " + getGap() + " " + (getGap() != 1 ? "Days (" + loginUser.fullName + ")" : "Day (" + loginUser.fullName + ")");
        }
        if (!polHistory)
            polHistory = null;
        if (polHistory) {
            var polList = polHistory.split(", ");
            var listPol = [];
            for (var i = 0; i < polList.length; i++) {
                if (!polList[i].includes(" 0 Days")) {
                    listPol.push(polList[i]);
                }
            }
            polHistory = "";
            if (listPol.length > 0) {
                polHistory = listPol[0];
                for (var j = 1; j < listPol.length; j++) {
                    polHistory += ", " + listPol[i];
                }
            }
        }
        if (!polHistory)
            polHistory = null;
        //if (customsDate == null || customsDate == undefined) {
        //    return;
        //}
        if (order.shippingModeId != 1) {
            sendRequest(`/api/fa/setPolExitSea/${loginUser.id}/${order.id}/${approveFlag}/${customsDate}/${actualPOLArrivalDate}/${polHistory}`, REQUEST_ACTIONS.GET, null, {
                successCallback: data => {
                    //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                    sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, null)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                        successCallback: response => {
                            setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                            setOpenDialog(false);
                        },
                        failedCallback,
                    });
                },
                failedCallback,
            });
        } else {
            sendRequest(`/api/fa/setPolExit/${loginUser.id}/${order.id}/${approveFlag}/${customsDate}/${actualPOLArrivalDate}/${polHistory}`, REQUEST_ACTIONS.GET, null, {
                successCallback: data => {
                    //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                    sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, null)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                        successCallback: response => {
                            sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, null)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                                successCallback: response => {
                                    setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                    setOpenDialog(false);
                                },
                                failedCallback,
                            });
                        },
                        failedCallback,
                    });
                },
                failedCallback,
            });
        }
    }

    const getGap = () => {
        if (!order.orderStage[4]?.departureStage[0]?.etd)
            return "";
        var dt1 = order.orderStage[4]?.departureStage[0]?.etd;
        if (dt1) {
            var date1;
            if (!isNaN(dt1))
                date1 = new Date(Number(dt1));
            else
                date1 = new Date(dt1);
            date1.setHours(1);
            date1.setMinutes(0);
            date1.setSeconds(0);
            date1.setMilliseconds(0);
            var dt2 = customsDate;
            if (dt2) {
                var date2;
                if (!isNaN(dt2))
                    date2 = new Date(Number(dt2));
                else
                    date2 = new Date(dt2);
                date2.setHours(1);
                date2.setMinutes(0);
                date2.setSeconds(0);
                date2.setMilliseconds(0);
                var delay = 0;
                var status = order?.orderStatus[5]?.status ? order?.orderStatus[5]?.status : "NOT_STARTED";
                var timeDiff = date2.getTime() - date1.getTime();
                //if (timeDiff > 0)
                delay = timeDiff / 86400000 | 0;
                if (delay > 99)
                    delay = 99;
                if (delay < -99)
                    delay = -99;
                if (delay != 0) {
                    return delay;
                }
                else return 0;
                if (delay && status !== "SUCCESS" && status !== "NOT_STARTED")
                    return " (" + delay + ")";
                if (status === "SUCCESS") {
                    if (timeDiff < 0) {
                        delay = floor(timeDiff / 86400000);
                        if (delay < -99)
                            delay = -99;
                        return " (" + delay + ")";
                    }
                    return "";
                }
            }
        }
        return 0;
    }

    const getGap1 = () => {
        var dt1 = order.orderStage[4]?.departureStage[0]?.etd ? order.orderStage[4]?.departureStage[0]?.etd : null
        var date1 = new Date(dt1);
        var date2 = new Date(customsDate);
        var timeDiff = date2.getTime() - date1.getTime();
        if (timeDiff > 0)
            return timeDiff/86400000 | 0;
        return 0;
    }
    return (
        <Dialog maxWidth='1400px' minWidth="500px" open={openDialog} onHandleCancel={onHandleCloseDialog} title={'DEP - ' + order?.jobNumber}>
            <Grid container lg={12} >
                <Grid container lg={12} >
                    <Grid container lg={4}>
                        <DateTimePicker
                            disabled={true}
                            label='Planned departure'
                            value={order.orderStage[4]?.departureStage[0]?.etd}
                        />
                    </Grid>
                    <Grid container lg={4}>
                        <DateTimePicker
                            disabled={order.orderStage[4]?.departureStage[0]?.etd ? false : true }
                            label='Delay Departure'
                            value={customsDate}
                            onChange={date => setCustomsDate(date)}
                        />
                    </Grid>
                    <Grid container lg={4}>
                        Delayed By: {getGap()} {getGap() != 1 ? "Days" : "Day"}
                    </Grid>
                    <Grid container lg={12} style={{marginTop: "20px"}}>
                        <Grid container lg={12} style={{}}>
                            <MultilineField lg={12} label="History" value={historyNotes}></MultilineField>
                        </Grid>
                    </Grid>
                    {/*<Grid container lg={12} style={{ marginTop: "25px" }}>
                        <DateTimePicker
                            label='Actual Departure'
                            value={actualPOLArrivalDate}
                            onChange={date => setActualPOLArrivalDate(date)}
                        />
                    </Grid>*/}
                    <Grid container lg={4} style={{marginTop: "20px"}}>
                        <view style={{ margin: "15px", marginTop: "-5px", maxWidth: "300px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox disabled={order.orderStatus[4].status !== "SUCCESS"} checked={approveFlag} onChange={event => setApproveFlag(!approveFlag)} />
                                }
                                label='Departed'
                            />
                        </view>
                    </Grid>
                    <Grid container lg={12}>
                        <Grid container lg={6}>
                            <Button
                                variant='contained'
                                style={{ margin: "15px", marginLeft: "0", maxWidth: "150px" }}
                                onClick={setShowMail}>
                                Mail
                            </Button>
                        </Grid>
                        <Grid container lg={6} justifyContent="right">
                            <Button
                                dissabled={!approveFlag}
                                variant='contained'
                                style={{ margin: "15px", marginLeft: "0", maxWidth: "150px", maxHeight: "40px" }}
                                onClick={saveCustomsData}>
                                Save
                            </Button>
                            <Button style={{ margin: "15px", marginLeft: "0", maxWidth: "150px", maxHeight: "40px" }} onClick={onHandleCancel} variant='contained' color='secondary'>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item lg={12}>
                {showMail && <Grid container item lg={12}>
                    <TextField
                        lg={12}
                        required
                        dissabled
                        style={{ padding: "5px" }}
                        type="Text"
                        label='Subject'
                        value={mailSubject}
                    />
                    <Grid container item lg={12}>
                        <Grid lg={12}>
                            <br /> <div style={{ textAlign: "center", width: "100%" }}><b>{order.locationId === "2" ? "Export " : "Import "} JOB ORDER No. - {order.jobNumber}</b></div> <br /><br />
                            <b>Date: </b> {getTodayDate()}<br /><br />
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <td style={{ background: "lightgrey", width: "25%" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Shipper:</b></td>
                                    <td>{order.shipper.companyName}
                                        {order.shipper.address1 && <br></br>}{order.shipper.address1 ? order.shipper.address1 : ""}
                                        {order.shipper.address2 && <br></br>}{order.shipper.address2 ? order.shipper.address2 : ""}
                                        {order.shipper.city && <br></br>}{order.shipper.city ? order.shipper.city : ""}
                                        {order.shipper.zipCode && <br></br>}{order.shipper.zipCode ? order.shipper.zipCode : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ background: "lightgrey" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Contact:</b></td>
                                    <td>{order.shipper.name ? order.shipper.name : ""}</td>
                                </tr>
                                <tr>
                                    <td style={{ background: "lightgrey" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Email:</b></td>
                                    <td>
                                        {order.shipper.email ? order.shipper.email : ""}{order.shipper.email && <br></br>}
                                        {order.shipper.email2 ? order.shipper.email2 : ""}{order.shipper.email2 && <br></br>}
                                        {order.shipper.email3 ? order.shipper.email3 : ""}{order.shipper.email3 && <br></br>}
                                        {order.shipper.email4 ? order.shipper.email4 : ""}{order.shipper.email4 && <br></br>}
                                        {order.shipper.email5 ? order.shipper.email5 : ""}{order.shipper.email5 && <br></br>}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ background: "lightgrey" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Tel:</b></td>
                                    <td>{order.shipper.mobile ? order.shipper.mobile : ""}</td>
                                </tr>
                            </table>
                            <br />
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <td style={{ background: "lightgrey", width: "25%" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Consignee:</b></td>
                                    <td>{order.consignee.companyName}
                                        {order.consignee.address1 && <br></br>}{order.consignee.address1 ? order.consignee.address1 : ""}
                                        {order.consignee.address2 && <br></br>}{order.consignee.address2 ? order.consignee.address2 : ""}
                                        {order.consignee.city && <br></br>}{order.consignee.city ? order.consignee.city : ""}
                                        {order.consignee.zipCode && <br></br>}{order.consignee.zipCode ? order.consignee.zipCode : ""}
                                    </td>
                                </tr>
                            </table>
                            <Grid container item lg={12}>
                                <br />
                                Departure date was updated to: {formatDateTime(customsDate)}
                                <br />
                                <br />
                                Regards.
                            </Grid>
                            <Grid continer lg={12} item style={{ margin: "5px" }}>
                                <MultilineField
                                    required
                                    xs={12}
                                    sm={null}
                                    md={null}
                                    lg={null}
                                    label='Comments'
                                    value={mailBody}
                                    onChange={event => setMailBody(event.target.value)}
                                />
                            </Grid>
                            <table style={{ width: "100%" }}>
                                <tr style={{ background: "lightgrey", width: "100%", textAlign: "center" }}>SHIPPING INFORMATION </tr>
                            </table>
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <td style={{ background: "lightgrey", width: "25%" }}>Consignee Reference</td>
                                    <td style={{ width: "25%" }}>{order.clientRefNumber}</td>
                                    <td style={{ background: "lightgrey", width: "25%" }}>Origin</td>
                                    <td style={{ width: "25%" }}>{countryOfOriginData.find((ctr) => ctr.id == order.countryOfOriginId)?.name}</td>
                                </tr>
                                <tr>
                                    <td style={{ background: "lightgrey" }}>Shipper reference</td>
                                    <td>{order.suppliersPO}</td>
                                    <td style={{ background: "lightgrey" }}>Port of loading</td>
                                    <td>{getFrom(order.polId, order.shippingModeId, false)}</td>
                                </tr>
                                <tr>
                                    <td style={{ background: "lightgrey" }}>No. of packs</td>
                                    <td>{getTotalPieces()}</td>
                                    <td style={{ background: "lightgrey" }}>Port of discharge</td>
                                    <td>{getTo(order.poaId, order.shippingModeId)}</td>
                                </tr>
                                <tr>
                                    <td style={{ background: "lightgrey" }}>Kind of packages</td>
                                    <td>{getKindOfPieces()}</td>
                                    <td style={{ background: "lightgrey" }}>Shipment Method</td>
                                    <td>{getType(order.shippingModeId)}</td>
                                </tr>
                                <tr>
                                    <td style={{ background: "lightgrey" }}>Weight Kg</td>
                                    <td>{getTotalWeight()}</td>
                                    <td style={{ background: "lightgrey" }}>Shipment Terms</td>
                                    <td>{incotermsData.find(dat => dat.id == order.incotermsId)?.name}</td>
                                </tr>
                                <tr>
                                    <td style={{ background: "lightgrey" }}>Volume CBM.</td>
                                    <td>{getChWeight()}</td>
                                    <td style={{ background: "lightgrey" }}>Consignee VAT.</td>
                                    <td>{order.consignee.uatnumber}</td>
                                </tr>
                                <tr>
                                    <td style={{ background: "lightgrey" }}>Type</td>
                                    <td>{getCargoType(subModeData.find(dat => dat.id == order.subModeId)?.name)}</td>
                                    <td style={{ background: "lightgrey" }}></td>
                                    <td></td>
                                </tr>
                            </table>
                        </Grid>
                    </Grid>
                    <Grid container item lg={12}>
                        <br />
                        Regards.
                    </Grid>
                    <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                        <TextField
                            lg={12}
                            style={{ padding: "5px", marginTop: "20px" }}
                            type="Text"
                            label='Additional E-Mails'
                            value={mailToAdd}
                            onChange={event => setMailToAdd(event.target.value)}
                        />
                        {!useMailToAdd && <Grid container item lg={12} style={{ color: "red" }}> * Additional emails are invalid.</Grid>}
                    </Grid>

                    <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                        <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={useMailToAgent} onChange={event => useMailToAgent ? setUseMailToAgent(false) : setUseMailToAgent(true)} />
                                }
                                label={"Broker email: " + mailToAgent}
                            />
                        </view>
                    </Grid>
                    {!mailToAgentValidation && <Grid container item lg={12} style={{ color: "red" }}> * Broker email for: {brokerName} is invalid.</Grid>}

                    {mailToAgent2 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                        <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={useMailToAgent2} onChange={event => useMailToAgent2 ? setUseMailToAgent2(false) : setUseMailToAgent2(true)} />
                                }
                                label={"Broker email2: " + mailToAgent2}
                            />
                        </view>
                    </Grid>}
                    {(mailToAgent2 && !mailToAgentValidation2) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email2 for: {brokerName} is invalid.</Grid>}

                    {mailToAgent3 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                        <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={useMailToAgent3} onChange={event => useMailToAgent3 ? setUseMailToAgent3(false) : setUseMailToAgent3(true)} />
                                }
                                label={"Broker email 3: " + mailToAgent3}
                            />
                        </view>
                    </Grid>}
                    {(mailToAgent3 && !mailToAgentValidation3) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email3 for: {brokerName} is invalid.</Grid>}

                    {mailToAgent4 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                        <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={useMailToAgent4} onChange={event => useMailToAgent4 ? setUseMailToAgent4(false) : setUseMailToAgent4(true)} />
                                }
                                label={"Broker email 4: " + mailToAgent4}
                            />
                        </view>
                    </Grid>}
                    {(mailToAgent4 && !mailToAgentValidation4) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email4 for: {brokerName} is invalid.</Grid>}

                    {mailToAgent5 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                        <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={useMailToAgent5} onChange={event => useMailToAgent5 ? setUseMailToAgent5(false) : setUseMailToAgent5(true)} />
                                }
                                label={"Broker email5: " + mailToAgent5}
                            />
                        </view>
                    </Grid>}
                    {(mailToAgent5 && !mailToAgentValidation5) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email5 for: {brokerName} is invalid.</Grid>}

                    <Grid container lg={12}>
                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={useMailToCustomer} onChange={event => useMailToCustomer ? setUseMailToCustomer(false) : setUseMailToCustomer(true)} />
                                }
                                label={"Consignee email: " + order.consignee.email}
                            />
                        </view>
                    </Grid>
                    {!mailToCustomerValidation && <Grid container item style={{ color: "red" }}>* Consignee email for: {order.consignee.companyName} is invalid.</Grid>}

                    {order.consignee.email2 && <Grid container lg={12}>
                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={useMailToCustomer2} onChange={event => useMailToCustomer2 ? setUseMailToCustomer2(false) : setUseMailToCustomer2(true)} />
                                }
                                label={"Consignee email2: " + order.consignee.email2}
                            />
                        </view>
                    </Grid>}
                    {(order.consignee.email2 && !mailToCustomerValidation2) && <Grid container item style={{ color: "red" }}>* Consignee email2 for: {order.consignee.companyName} is invalid.</Grid>}

                    {order.consignee.email3 && <Grid container lg={12}>
                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={useMailToCustomer3} onChange={event => useMailToCustomer3 ? setUseMailToCustomer3(false) : setUseMailToCustomer3(true)} />
                                }
                                label={"Consignee email3: " + order.consignee.email3}
                            />
                        </view>
                    </Grid>}
                    {(order.consignee.email3 && !mailToCustomerValidation3) && <Grid container item style={{ color: "red" }}>* Consignee email3 for: {order.consignee.companyName} is invalid.</Grid>}

                    {order.consignee.email4 && <Grid container lg={12}>
                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={useMailToCustomer4} onChange={event => useMailToCustomer4 ? setUseMailToCustomer4(false) : setUseMailToCustomer4(true)} />
                                }
                                label={"Consignee email 4: " + order.consignee.email4}
                            />
                        </view>
                    </Grid>}
                    {(order.consignee.email4 && !mailToCustomerValidation4) && <Grid container item style={{ color: "red" }}>* Consignee email4 for: {order.consignee.companyName} is invalid.</Grid>}

                    {order.consignee.email5 && <Grid container lg={12}>
                        <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={useMailToCustomer5} onChange={event => useMailToCustomer5 ? setUseMailToCustomer5(false) : setUseMailToCustomer5(true)} />
                                }
                                label={"Consignee email 5: " + order.consignee.email5}
                            />
                        </view>
                    </Grid>}
                    {(order.consignee.email5 && !mailToCustomerValidation5) && <Grid container item style={{ color: "red" }}>* Consignee email5 for: {order.consignee.companyName} is invalid.</Grid>}
                    <Grid container lg={12}>
                        <Button
                            variant='contained'
                            style={{ margin: "15px", marginLeft: "0", maxWidth: "150px" }}
                            onClick={sendMail}>
                            Send Mail
                        </Button>
                    </Grid>
                </Grid>}
            </Grid>
            {order.shippingModeId != 1 ?
                <Box component='form' noValidate autoComplete='off'>
                    {<Grid container spacing={2}>
                        {order.sinay && <Grid item container>
                            <Grid item container>
                                {<Grid container item style={{ marginTop: "25px", fontSize: "1vw" }}>
                                    {(order.sinay.status) && <Grid style={{ margin: "10px", borderRadius: "10px", border: "1px grey solid", padding: "5px" }} item container>

                                        {order.sinay.status.val.errorCode && <Grid item container style={{ padding: "5px" }}>
                                            <Grid xs={4} item container>ERROR</Grid>
                                            <Grid xs={7} item container>{order.sinay.status.val.message}</Grid>
                                        </Grid>}
                                        {order.sinay.status.val?.route && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                                            <Grid xs={4} item container>POL</Grid>
                                            <Grid xs={7} item container>{order.sinay.status?.val?.route?.pol?.location?.country}, {order.sinay.status?.val?.route?.pol?.location?.state}, {order.sinay.status?.val?.route?.pol?.location?.locode}</Grid>
                                        </Grid>}
                                        {order.sinay.status.val?.route && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                                            <Grid xs={4} item container>POD</Grid>
                                            <Grid xs={7} item container>{order.sinay.arCountry}, {order.sinay.arPod}, {order.sinay.arLocode}</Grid>
                                        </Grid>}
                                        {order.sinay.status.val?.route && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                                            <Grid xs={4} item container>Estimated Time</Grid>
                                            <Grid xs={7} item container>{formatDate(order.sinay.arDate1)}</Grid>
                                        </Grid>}
                                        {order.sinay.status.val.route?.pod?.predictiveEta && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                                            <Grid xs={4} item container>Predictive Eta</Grid>
                                            <Grid xs={7} item container>{formatDate(order.sinay.status.val?.route?.pod?.predictiveEta)}</Grid>
                                        </Grid>}
                                        {order.sinay.status.val?.metadata && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                                            <Grid xs={4} item container>Updated At</Grid>
                                            <Grid xs={7} item container>{formatDate(order.sinay.status.val?.metadata?.updatedAt)}</Grid>
                                        </Grid>}
                                        {order.sinay.status.val?.metadata && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                                            <Grid xs={4} item container>Sealine</Grid>
                                            <Grid xs={7} item container>{order.sinay.status.val?.metadata?.sealine}</Grid>
                                        </Grid>}
                                        {order.sinay.status.val?.metadata && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                                            <Grid xs={4} item container>Sealine Name</Grid>
                                            <Grid xs={7} item container>{order.sinay.status.val?.metadata?.sealineName}</Grid>

                                        </Grid>}
                                        {order.sinay.status.val?.metadata && <Grid item container style={{ padding: "5px" }}>
                                            <Grid xs={4} item container>Shipping Status</Grid>
                                            <Grid xs={7} item container>{order.sinay.status.val?.metadata?.shippingStatus}</Grid>
                                        </Grid>}
                                    </Grid>}
                                    <Grid style={{ fontSize: "1vw", fontWeight: "Bold", marginTop: "25px", width: "1400px" }} item container></Grid>
                                    {(order.sinay.status) && <Grid style={{ fontWeight: "Bold", marginTop: "25px" }} item container>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>Date</Grid>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>Status</Grid>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>Location</Grid>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>Route</Grid>
                                    </Grid>}
                                    {(order.sinay.status) && <>{order.sinay.status.val?.containers != null ? <>{order.sinay.status.val?.containers[0].events.map((t) => <Grid style={{ fontSize: "1vw" }} item container>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>{formatDate(t.date)}</Grid>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>{t.description}</Grid>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>{t.location?.countryCode}, {t.location?.state}</Grid>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>{t.routeType}</Grid>
                                    </Grid>)}</> : <Grid style={{ fontSize: "1vw" }} item container>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>Error from server</Grid>
                                            <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container></Grid>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container></Grid>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container></Grid>
                                    </Grid>}</>}
                                </Grid>}
                                {<Grid container item style={{ fontSize: "1vw", marginTop: "25px" }}>

                                    {(order.sinay.status) && <Grid style={{ fontWeight: "Bold", marginTop: "25px" }} item container>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>Vessel Name</Grid>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>IMO</Grid>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>Flag</Grid>
                                    </Grid>}
                                    {(order.sinay.status) && <>{order.sinay.status.val?.vessels != null ? <>{order.sinay.status.val?.vessels.map((t) => <Grid style={{}} item container>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>{t.name}</Grid>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>{t.imo}</Grid>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>{t.flag}</Grid>
                                    </Grid>)}</> : <Grid style={{}} item container>
                                        <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>Error from server</Grid>
                                            <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container></Grid>
                                            <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container></Grid>
                                    </Grid>}</>}
                                </Grid>}

                            </Grid>
                        </Grid>}
                    </Grid>}
                </Box> :
                <Box component='form' noValidate autoComplete='off'>
                    {<Grid container >
                        {order.airStatus && <>
                            <Grid item container lg={12}>
                                <Grid item container lg={6} >
                                    <Grid item container lg={6} style={{ border: "1px solid grey" }}>AWB</Grid>
                                    <Grid item container lg={6} style={{ border: "1px solid grey" }}>{order.airStatus.awb}</Grid>
                                </Grid>
                                <Grid item container >
                                    <Grid item container lg={6}>
                                        <Grid item container lg={6} style={{ border: "1px solid grey" }}>POL</Grid>
                                        <Grid item container lg={6} style={{ border: "1px solid grey" }}>{order.airStatus.airStatus?.originAndDestination ? order.airStatus.airStatus.originAndDestination.origin : "-"}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container >
                                    <Grid item container lg={6} >
                                        <Grid item container lg={6} style={{ border: "1px solid grey" }}>POD</Grid>
                                        <Grid item container lg={6} style={{ border: "1px solid grey" }}>{order.airStatus.airStatus?.originAndDestination ? order.airStatus.airStatus.originAndDestination.destination : "-"}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container >
                                    <Grid item container lg={6} >
                                        <Grid item container lg={6} style={{ border: "1px solid grey" }}>Pieces</Grid>
                                        <Grid item container lg={6} style={{ border: "1px solid grey" }}>{order.airStatus.airStatus?.totalNumberOfPieces ? order.airStatus.airStatus?.totalNumberOfPieces : "-"}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container >
                                    <Grid item container lg={6} >
                                        <Grid item container lg={6} style={{ border: "1px solid grey" }}>Weight</Grid>
                                        <Grid item container lg={6} style={{ border: "1px solid grey" }}>{getWeight(order.airStatus)}</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {(order.airStatus?.regStatus?.confirmations?.length > 0) && <Grid lg={12} item container>
                                <Grid sm={3} item container style={{ border: "1px solid grey" }}>Error: </Grid>
                                <Grid sm={3} item container style={{ border: "1px solid grey" }}>
                                    {order.airStatus?.regStatus?.confirmations[0]?.error}
                                </Grid>
                            </Grid>}
                            {order.airStatus?.regStatus?.errorCode && <Grid lg={12} item container>
                                <Grid sm={3} item container style={{ border: "1px solid grey" }}>Error: </Grid>
                                <Grid sm={3} item container style={{ border: "1px solid grey" }} title={order.airStatus?.regStatus?.details}>
                                    {order.airStatus?.regStatus?.errorCode}
                                </Grid>
                            </Grid>}
                            {order.airStatus.airStatus?.flights && <Grid item container lg={12} style={{ marginTop: "25px" }}>
                                <Grid item container flexWrap="nowrap" style={{ fontWeight: "bold" }} >
                                    <Grid item container style={{ border: "1px solid grey" }}>Flight Number</Grid>
                                    <Grid item container style={{ border: "1px solid grey" }}>Origin</Grid>
                                    <Grid item container style={{ border: "1px solid grey" }}>Desitination</Grid>
                                    <Grid item container style={{ border: "1px solid grey" }}>Scheduled Departure</Grid>
                                    <Grid item container style={{ border: "1px solid grey" }}>Scheduled Arival</Grid>
                                    <Grid item container style={{ border: "1px solid grey" }}>ETA</Grid>
                                    <Grid item container style={{ border: "1px solid grey" }}>Actual Departure</Grid>
                                    <Grid item container style={{ border: "1px solid grey" }}>Actual Arival</Grid>
                                    {/*<Grid item container style={{ border: "1px solid grey" }}>Content</Grid>*/}
                                </Grid>
                                {order.airStatus.airStatus?.flights.map((t, index) => (
                                    <Grid item container flexWrap="nowrap" style={{ background: index % 2 == 1 ? "white" : "#eee" }}>
                                        <Grid item container style={{ border: "1px solid grey" }}>{t.flight}</Grid>
                                        <Grid item container style={{ border: "1px solid grey" }}>{t.origin}</Grid>
                                        <Grid item container style={{ border: "1px solid grey" }}>{t.destination}</Grid>
                                        <Grid item container style={{ border: "1px solid grey" }}>{t.tsd ? formatDate(t.tsd) : (t.std ? formatDate(t.std) : "--")}</Grid>
                                        <Grid item container style={{ border: "1px solid grey" }}>{t.tsa ? formatDate(t.tsa) : (t.sta ? formatDate(t.sta) : "--")}</Grid>
                                        <Grid item container style={{ border: "1px solid grey" }}>{t.eta ? formatDate(t.eta) : "--"}</Grid>
                                        <Grid item container style={{ border: "1px solid grey" }}>{t.atd ? formatDate(t.atd) : "--"}</Grid>
                                        <Grid item container style={{ border: "1px solid grey" }}>{t.ata ? formatDate(t.ata) : "--"}</Grid>
                                        {/*<Grid item container style={{ border: "1px solid grey" }}>{t.numberOfPieces} pcs, {t.weight.unit} {t.weight.amount}</Grid>*/}
                                    </Grid>
                                ))}
                            </Grid>}
                            {order.airStatus.airStatus?.events && <Grid item container lg={12} style={{ marginTop: "25px", overflow: "auto", maxHeight: "300px" }}>
                                <Grid item container flexWrap="nowrap" style={{ fontWeight: "bold" }} >
                                    <Grid item container style={{ border: "1px solid grey" }}>Time</Grid>
                                    <Grid item container style={{ border: "1px solid grey" }}>Status</Grid>
                                    <Grid item container style={{ border: "1px solid grey" }}>Flight Number</Grid>
                                    {/*<Grid lg={1.2} item container style={{ border: "1px solid grey" }}>Origin</Grid>
                        <Grid lg={1.2} item container style={{ border: "1px solid grey" }}>Destination</Grid>
                        <Grid item container style={{ border: "1px solid grey" }}>Departure</Grid>
                        <Grid item container style={{ border: "1px solid grey" }}>Arival</Grid>*/}
                                    <Grid item container style={{ border: "1px solid grey" }}>Content</Grid>
                                </Grid>
                                {order.airStatus.airStatus?.events.map((t, index) => (
                                    <Grid item container flexWrap="nowrap" style={{ background: index % 2 == 1 ? "white" : "#eee" }}>
                                        <Grid item container style={{ border: "1px solid grey" }}>{formatDate(t.timeOfEvent)}</Grid>
                                        <Grid item container style={{ border: "1px solid grey" }}>{t.type}</Grid>
                                        <Grid item container style={{ border: "1px solid grey" }}>{t.flight}</Grid>
                                        {/* <Grid lg={1.2} item container style={{ border: "1px solid grey" }}>{t.origin}</Grid>
                            <Grid lg={1.2} item container style={{ border: "1px solid grey" }}>{t.destination}</Grid>
                            <Grid item container style={{ border: "1px solid grey" }}>{t.timeOfScheduledDeparture ? formatDate(t.timeOfScheduledDeparture) : (t.dateOfScheduledDeparture ? t.dateOfScheduledDeparture :"-")}</Grid>
                            <Grid item container style={{ border: "1px solid grey" }}>{t.timeOfScheduledArrival ? formatDate(t.timeOfScheduledArrival) : (t.dateOfScheduledArrival ? t.dateOfScheduledArrival :"-")}</Grid>*/}
                                        <Grid item container style={{ border: "1px solid grey" }}>{t.numberOfPieces} pcs, {t.weight?.unit} {t.weight?.amount}</Grid>
                                    </Grid>
                                ))}
                            </Grid>}
                        </>}
                    </Grid>}
                </Box>
            }
        </Dialog>
    );
};

export default PolDialog;
