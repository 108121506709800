import { Fragment, useState, useEffect, useCallback } from 'react';

import { Divider, Grid, Typography } from '@mui/material';
import { MultilineField, SelectField, TextField, Dropzone, FilesRender, Autocomplete } from 'style-guide';

import { useDispatch, useSelector } from 'react-redux';
import { filterServiceProvider } from 'Utils';
import { USA_STATES } from 'constants/global-constants';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { snackbarToggle } from 'reducers/snackbarSlicer';

const OrderInformation = ({ customerlocation, brokers, order, copyOrder, loginUser, orderId, state, onStateChange, pageName, onHandleCloseParentModal }) => {
    const {
        subModeData,
        shippingModeData,
        countryOfOriginData,
        userILNames,
        incotermsData,
        airPolData,
        airPoaData,
        lclPolData,
        lclPoaData,
    } = useSelector(state => state.predefinedData);
    const { selectedLocationIndex, } = useSelector(state => state.orderTableFilter);
    const { shipperNameOptions } = useSelector(state => state.shippers);
    const { consigneeNameOptions } = useSelector(state => state.consignees);
    const dispatch = useDispatch();
    const { customerNameOptions } = useSelector(state => state.customers);
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const [coloaderNames, setColoaderNames] = useState(false);
    const [brokerNames, setBrokerNames] = useState(false);
    const [customersList, setCustomersList] = useState(false);
    const [adminData, setAdminData] = useState([]);

    const setBrokers = (data) => {
        const brokerNameOptions = data?.map(broker => ({
            id: broker.id,
            name: broker.companyName,
            serviceProviderLocationId: broker.serviceProviderLocationId,
        }));
        setBrokerNames(brokerNameOptions ? brokerNameOptions : []);
    }
    const setColoaders = (data) => {
        const coloaderNameOptions = data?.map(broker => ({
            id: broker.id,
            name: broker.companyName,
            serviceProviderLocationId: broker.serviceProviderLocationId,
        }));
        setColoaderNames(coloaderNameOptions ? coloaderNameOptions : []);
    }
    const getDate = (date) => {
        if (!isNaN(date))
            date = new Date(Number(date));
        else {
            date = new Date(date);
        }
        if (date.getFullYear() < 2000)
            return "";
        var year = "" + date.getFullYear();
        return (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "/" + (date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "/" + year[2] + year[3];
    }
    const getETDDisplay = () => {
        var dt = "";
        try {
            if (order?.astatus?.podDate)
                dt = getDate(order?.astatus?.podDate)
            else if (order?.cstatus?.podDate)
                dt = getDate(order?.cstatus?.podDate)
            else if (order?.cstatus?.arDate1)
                dt = getDate(order?.cstatus?.arDate1)
            else if (order.orderStage[4]?.departureStage[0]?.eta)
                dt = new Intl.DateTimeFormat('en-GB', {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                    /* hour: '2-digit',
                     minute: '2-digit',
                     second: '2-digit',*/
                }).format(order.orderStage[4]?.departureStage[0]?.eta)
            else
                dt = "";
        } catch (e) {
            dt = "";
        }
        if (dt.includes("NaN"))
            dt = "";
        return dt;
    }
    const getETADisplay = () => {
        try {
            var dt = "";
            if (order?.astatus?.polDate)
                dt = getDate(order?.astatus?.polDate)
            else if (order?.cstatus?.polDate)
                dt = getDate(order?.cstatus?.polDate)
            else if (order.orderStage[4]?.departureStage[0]?.etd)
                dt = new Intl.DateTimeFormat('en-GB', {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                    /* hour: '2-digit',
                     minute: '2-digit',
                     second: '2-digit',*/
                }).format(order.orderStage[4]?.departureStage[0]?.etd)
            else
                dt = "";
        } catch (e) {
            dt = "";
        }
        if (dt.includes("NaN"))
            dt = "";
        return dt;
    }
    const ETDDisplay = getETDDisplay();
    const ETADisplay = getETADisplay();
    const [userILImporter, setUserILImporter] = useState([]);
    const [userILTracer, setUserILTracer] = useState([]);
    const [userILCustoms, setUserILCustoms] = useState([]);
    const [userILSales, setUserILSales] = useState([]);
    const [userName, setUserName] = useState([]);
    useEffect(() => {
        var importers = [];
        var tracers = [];
        var customs = [];
        var sales = [];

        importers = userILNames.filter(name => name.department == 1 || name.department == null);
        tracers = userILNames.filter(name => name.department == 2);
        customs = userILNames.filter(name => name.department == 3);
        sales = userILNames.filter(name => name.department == 4);

        setUserILImporter(importers);
        setUserILTracer(tracers);
        setUserILCustoms(customs);
        setUserILSales(sales);

        // predefined customer data get
        sendRequest('/api/serviceProvider/2?activeFlag=true', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setCustomersList(response),
            failedCallback,
        });
        // predefined customer data get
        sendRequest('/api/serviceProvider/12?activeFlag=true', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setBrokers(response),
            failedCallback,
        });
        // predefined customer data get
        sendRequest('/api/serviceProvider/8?activeFlag=true', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setColoaders(response),
            failedCallback,
        });
        if (copyOrder) {
            onStateChange("jobNumber", null);
            onStateChange("codeFileCount", null);
        }
        sendRequest('/api/getUsers', REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                setAdminData(response)
                setUserName(response.find(user => user.id == order?.createdByUserId)?.fullName);
            },
            failedCallback,
        });
    }, []);
    useEffect(() => {
        setUserName(getILUserCreatedName());
    }, [adminData]);
    useEffect(() => {
        if (!order?.id) {
            var br = null;
            if (customerlocation == 0 && brokerNames) {
                //Total Care Logistics BV
                br = brokerNames.find(b => b.name === "Total Care Logistics BV")?.id
            }
            else if (customerlocation == 1 && brokerNames) {
                //Total Care�Logistics�Corp
                br = brokerNames.find(b => b.name === "Total Care Logistics Corp.")?.id
            }
            onStateChange("customerId", br);
        }
    }, [brokerNames, customerlocation]);
    const {
        jobNumber,
        userILId,
        userILTracerId,
        userILCustomsId,
        userILSalesId,
        incotermsId,
        shipperId,
        consigneeId,
        countryOfOriginId, originState,
        clientRefNumber,
        suppliersPO,
        customerId,
        coloaderId,
        shippingModeId,
        freeDays,
        subModeId,
        polId,
        poaId,
        notes,
        coments,
        files,
        orderStageId,
        codeFileCount,
    } = state;

    const onConsigneeChange = (field, value) => {
        onStateChange(field, value);
        var customer = customersList.find(cust => cust.id == value);
        if (customer?.iuser) {
            if (Number(customer.iuser))
                onStateChange("userILId", Number(customer.iuser));
        }
        if (customer?.ouser) {
            if (Number(customer.ouser))
                onStateChange("userILTracerId", Number(customer.ouser));
        }
        if (customer?.cuser) {
            if (Number(customer.cuser))
                onStateChange("userILCustomsId", Number(customer.cuser));
        }
        if (customer?.suser) {
            if (Number(customer.suser))
                onStateChange("userILSalesId", Number(customer.suser));
        }
    }

    const onHandleChange = (event, field) => onStateChange(field, event.target.value);
    const onFilesAdd = file => {
        var fls = [...files, file];
        onStateChange('files', fls);
    }
    const onCodeFileCountAdd = file => {
        var cf = JSON.parse(JSON.stringify(codeFileCount))
        const codeFile = cf.find(codeFile => codeFile.code === file.code);
        if (!!codeFile) {
            codeFile.count = codeFile.count + 1;
        } else {
            cf.push(file);
        }
        onStateChange('codeFileCount', cf);
    };

    const getILUserCreatedName = () => {
        if (adminData) {
            return adminData.find(user => user.id == order?.createdByUserId)?.fullName
        }
        return "-"
    }
    const creationDate = (state?.date ? new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(new Date(state?.date)) + ' ' + new Intl.DateTimeFormat('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }).format(new Date(state?.date)) : "");

    const fileNumber = (order && order.orderStage) ? (order.fileNumber ? order.fileNumber : (order.orderStage[4]?.departureStage[0]?.filenumber ? order.orderStage[4].departureStage[0].filenumber : "-")) : "-"

    return (
        <Fragment>
            {orderId && <Grid container item spacing={2}>
                <TextField
                    lg={null}
                    type="Text"
                    label='Creation Date'
                    value={creationDate}
                />
                <TextField
                    lg={null}
                    type="Text"
                    label='ETD'
                    value={ETADisplay}
                />
                <TextField
                    lg={null}
                    type="Text"
                    label='ETA'
                    value={ETDDisplay}
                />
                <TextField
                    lg={null}
                    type="Text"
                    label={"Created By"}
                    value={userName}
                />
                <TextField
                    lg={null}
                    type="Text"
                    label={"File Number"}
                    value={fileNumber}
                />
            </Grid>}
            <Grid container item spacing={2}>
                {/*<Autocomplete
                    field='customerId'
                    label='Broker'
                    lg={null}
                    value={customerId}
                    onChange={onStateChange}
                    options={filterServiceProvider(loginUser, customerlocation, brokerNames)}
                />*/}
                <Autocomplete
                    field='customerId'
                    label='Broker'
                    lg={null}
                    value={customerId}
                    onChange={onStateChange}
                    options={brokerNames ? brokerNames : []}
                />
                <TextField
                    lg={null}
                    type="Text"
                    label='Client ref. #'
                    value={clientRefNumber}
                    onChange={event => onHandleChange(event, 'clientRefNumber')}
                />
                {order?.jobNumber ? <TextField
                    required
                    readonly
                    lg={null}
                    label='Job #'
                    value={jobNumber}
                    onChange={event => onHandleChange(event, 'jobNumber')}
                /> : <Grid sm={4} ></Grid>}
                <Autocomplete
                    required
                    field='userILId'
                    label='F/U'
                    lg={null}
                    value={userILId}
                    onChange={onStateChange}
                    options={userILImporter}
                />
                {/*<Autocomplete
                  field='userILTracerId'
                  label='OPS USER'
                  lg={null}
                  value={userILTracerId}
                  onChange={onStateChange}
                  options={userILTracer}
              />*/}
                <Autocomplete
                    field='userILCustomsId'
                    label='CUSTOMS'
                    lg={null}
                    value={userILCustomsId}
                    onChange={onStateChange}
                    options={userILCustoms}
                />
                <Autocomplete
                    field='userILSalesId'
                    label='Sales'
                    lg={null}
                    value={userILSalesId}
                    onChange={onStateChange}
                    options={userILSales}
                />
                <Autocomplete
                    required
                    field='incotermsId'
                    label='Incoterms'
                    lg={null}
                    value={incotermsId}
                    onChange={onStateChange}
                    options={incotermsData}
                />
                <Autocomplete
                    required
                    field='shipperId'
                    label='Shipper'
                    lg={null}
                    value={shipperId}
                    onChange={onStateChange}
                    options={filterServiceProvider(loginUser, customerlocation, shipperNameOptions)}
                />
                <Autocomplete
                    required
                    field='consigneeId'
                    label='Consignee'
                    lg={null}
                    value={consigneeId}
                    onChange={onConsigneeChange}
                    options={consigneeNameOptions}
                />
                {(loginUser.location.code === 'US' || customerlocation == 1) ?
                    (<Autocomplete
                        required
                        field='originState'
                        label='Origin State'
                        lg={null}
                        value={originState}
                        onChange={onStateChange}
                        options={USA_STATES}
                    />) :
                    (<Autocomplete
                        required
                        field='countryOfOriginId'
                        label='Country Of Origin'
                        lg={null}
                        value={countryOfOriginId}
                        onChange={onStateChange}
                        options={countryOfOriginData}
                    />)}
                <Autocomplete
                    required
                    field='subModeId'
                    label='Sub Mode'
                    lg={null}
                    value={subModeId}
                    onChange={onStateChange}
                    options={subModeData}
                />
                {customersList ? <Autocomplete
                    field='co'
                    label='C/O'
                    lg={null}
                    value={Number(customersList.find(con => con.id == consigneeId)?.co)}
                    options={consigneeNameOptions}
                    readOnly={true}
                    disabled={true}
                /> : <Autocomplete
                    field='co'
                    label='C/O'
                    lg={null}
                        value={consigneeNameOptions.find(con => con.id == consigneeId)?.names}
                    options={consigneeNameOptions}
                    readOnly={true}
                    disabled={true}
                />}
                <SelectField
                    label='Shipping'
                    lg={null}
                    value={shippingModeId}
                    onChange={event => {
                        onHandleChange(event, 'shippingModeId');
                        onStateChange('poaId', '');
                        onStateChange('polId', '');
                    }}
                    options={shippingModeData}
                />
                {shippingModeId === 1 && <Grid sm={4} ></Grid>}
                {shippingModeId === 2 && <Autocomplete
                    field='coloaderId'
                    label='Co-Loaders'
                    lg={null}
                    value={coloaderId}
                    onChange={onStateChange}
                    options={coloaderNames ? coloaderNames : []}
                />}
                {shippingModeId == 3 && <TextField
                    xs={4}
                    sm={null}
                    md={null}
                    lg={null}
                    type="number"
                    label="Free Days"
                    value={freeDays}
                    onChange={event => {
                        if (event.target.value >= 0 && event.target.value <= 30)
                            onHandleChange(event, 'freeDays')
                    }}
                    readOnly={true}
                />}
                <Autocomplete
                    field='polId'
                    label='POL'
                    lg={null}
                    value={polId}
                    onChange={onStateChange}
                    options={shippingModeId === 1 ? airPolData : lclPolData}
                />
                <Autocomplete
                    field='poaId'
                    label='POD'
                    lg={null}
                    value={poaId}
                    onChange={onStateChange}
                    options={shippingModeId === 1 ? airPoaData : lclPoaData}
                />
                <TextField
                    xs={12}
                    sm={null}
                    md={null}
                    lg={null}
                    type="Text"
                    label="Supplier's PO"
                    value={suppliersPO}
                    onChange={event => onHandleChange(event, 'suppliersPO')}
                />
                <MultilineField
                    xs={12}
                    sm={null}
                    md={null}
                    lg={null}
                    label='Notes'
                    value={notes}
                    onChange={event => onHandleChange(event, 'notes')}
                />
                {loginUser.location.code === "IL" && <MultilineField
                    xs={12}
                    sm={null}
                    md={null}
                    lg={null}
                    label='Coments'
                    value={coments}
                    onChange={event => onHandleChange(event, 'coments')}
                />}
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h6'>Attachments</Typography>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12}>
                    <Dropzone loginUser={loginUser}
                        orderStageId={orderStageId}
                        orderId={copyOrder ? null : orderId}
                        type={copyOrder ? null : (orderId ? null : 'newOrder')}
                        onFilesAdd={onFilesAdd}
                        onCodeFileCountAdd={onCodeFileCountAdd}
                        pageName={pageName}
                        onHandleCloseParentModal={null}
                    />
                </Grid>
                <FilesRender loginUser={loginUser} files={files} type={copyOrder ? null : (orderId ? null : 'newOrder')} orderId={copyOrder ? null : orderId} codeFileCount={copyOrder ? null : codeFileCount} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Fragment>
    );
};

export default OrderInformation;
