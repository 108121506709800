import { useState, useCallback, useEffect } from 'react';

import { Grid, FormControl, RadioGroup, FormControlLabel, Radio, Typography, Button, Checkbox } from '@mui/material';
import { TextField, SelectField, Autocomplete } from '../style-guide';
import DialogWithGoBackWrapper from '../style-guide/Dialog/DialogWithGoBack';

import { sendRequest, REQUEST_ACTIONS } from 'utils/Communicator';

import { useDispatch, useSelector } from 'react-redux';
import { addShipper, updateShipper, onPickupAddressChange } from 'reducers/shippersSlicer';
import { addCustomer, updateCustomer } from 'reducers/customersSlicer';
import { onUsersLocationChange } from 'reducers/UsersSlicer';
import { addConsignee, updateConsignee } from 'reducers/consigneesSlicer';
import { addVendor, updateVendor, add, updateServiceProviders } from 'reducers/VendorSlicer';
import { addTruckingCompany, updateTruckingCompany } from 'reducers/TruckingCompanySlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { onFunctionChange } from 'reducers/order-reducers/predefinedDataSlicer';

const AddServiceProvider = props => {
    const dispatch = useDispatch();
    const { countryOfOriginData, serviceProviderType } = useSelector(state => state.predefinedData);
    const { usersLocation } = useSelector(state => state.users);
    const { pickupAddress } = useSelector(state => state.shippers);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const [lockerId, setLockerId] = useState(null);
    const onHandleCancelModal = () => setOpenOrderModal(false);

    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const [userILImporter, setUserILImporter] = useState([]);
    const [userILTracer, setUserILTracer] = useState([]);
    const [userILCustoms, setUserILCustoms] = useState([]);
    const [userILSales, setUserILSales] = useState([]);
    const [consigneeList, setConsigneeList] = useState([]);
    const {
        userILNames
    } = useSelector(state => state.predefinedData);

    useEffect(() => {
        var importers = [];
        var tracers = [];
        var customs = [];
        var sales = [];

        importers = userILNames.filter(name => name.department == 1 || name.department == null);
        tracers = userILNames.filter(name => name.department == 2);
        customs = userILNames.filter(name => name.department == 3);
        sales = userILNames.filter(name => name.department == 4);

        setUserILImporter(importers);
        setUserILTracer(tracers);
        setUserILCustoms(customs);
        setUserILSales(sales);
    }, []);
    const [value, setValue] = useState(
        props.data
            ? props.data
            : {
                id: null,
                serviceProviderTypeId: props.type ? props.type : null,
                serviceProviderTypeId1: props.type ? props.type : null,
                serviceProviderLocationId: null,
                city: null,
                customer: 'IS_CUSTOMER',
                countryId: null,
                companyName: null,
                companyShortName: null,
                localName: null,
                scac: null,
                shippingLineNumber: null,
                zipCode: null,
                iuser: null,
                ouser: null,
                cuser: null,
                suser: null,
                co:null,
                useCustoms: false,
                state: null,
                salesman: null,
                phone: null,
                fax: null,
                email: null,
                position: null,
                mobile: null,
                name: null,
                businessPhone: null,
                faxContent: null,
                email2: null,
                position2: null,
                mobile2: null,
                name2: null,
                businessPhone2: null,
                faxContent2: null,
                email3: null,
                position3: null,
                mobile3: null,
                name3: null,
                businessPhone3: null,
                faxContent3: null,
                email4: null,
                position4: null,
                mobile4: null,
                name4: null,
                businessPhone4: null,
                faxContent4: null,
                email5: null,
                position5: null,
                mobile5: null,
                name5: null,
                businessPhone5: null,
                faxContent5: null,
                uatnumber: null,
                address2: null,
                address1: null,
                functionsId: null,
                sameAsShipper: 'sameAsShipper',
                pickupAddress: null,
                active: true
            },
    );

    useEffect(() => {

        sendRequest(`/api/serviceProvider/2`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                var res = [];
                for (var i = 0; i < response.length; i++) {
                    res.push({ id: response[i].id, name: response[i].companyName })
                }
                setConsigneeList(res);
            },
            failedCallback,
        });
        if (value.id && props.pageName == "manifest") {
            sendRequest('/api/parcel/manifestToken/' + value.id, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    setLockerId(response?.token);
                },
                failedCallback,
            });
        }

        if (serviceProviderType == null || serviceProviderType.length == 0) {
            sendRequest('/api/function', REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    dispatch(onFunctionChange(response.filter(functions => functions.name !== 'new pickup Address')));
                },
                failedCallback,
            });
        }

        sendRequest('/api/locations', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onUsersLocationChange(response)),
            failedCallback
        });
    }, [dispatch, failedCallback]);

    const readOnly = props.readOnly;
    useEffect(() => {
        setValue(prev => ({ ...prev, pickupAddress: pickupAddress }));
    }, [pickupAddress]);

    const handleChange = event => {
        setValue(prev => ({ ...prev, customer: event.target.value }));
    };
    const handleChanges = event => {
        setValue(prev => ({ ...prev, sameAsShipper: event.target.value }));
        if (value.sameAsShipper === 'sameAsShipper') {
            setValue(prev => ({ ...prev, pickupAddress: null }));
        }
        if (event.target.value === 'differentAddress') {
            setOpenOrderModal(true);
        }
    };

    const create = () => {
        if (!value.serviceProviderLocationId) {
            dispatch(snackbarToggle({ type: 'error', message: "Please select W/H location" }));
            return;
        }
        sendRequest('/api/serviceProvider', REQUEST_ACTIONS.POST, value, {
            successCallback: response => {
                if (response.serviceProviderTypeId === 5) {
                    dispatch(onPickupAddressChange(response));
                } else {
                    if (response.serviceProviderTypeId === 1) {
                        dispatch(addShipper(response));
                        dispatch(onPickupAddressChange(null));
                    } else if (response.serviceProviderTypeId === 2) {
                        dispatch(addConsignee(response));
                        dispatch(onPickupAddressChange(null));
                    } else if (response.serviceProviderTypeId === 4) {
                        dispatch(addVendor(response));
                    } else if (response.serviceProviderTypeId === 13) {
                        dispatch(addCustomer(response));
                    } else {
                        dispatch(addTruckingCompany(response));
                    }
                    dispatch(add(response));
                }
                props.onHandleCancel();
            },
            failedCallback,
        });
    };

    const update = () => {
        sendRequest('/api/serviceProvider', REQUEST_ACTIONS.PUT, value, {
            successCallback: response => {
                if (response.serviceProviderTypeId === 5) {
                    dispatch(onPickupAddressChange(response));
                } else {
                    if (response.serviceProviderTypeId === 2) {
                        dispatch(updateConsignee(response));
                    } else if (response.serviceProviderTypeId === 4) {
                        dispatch(updateVendor(response));
                    } else if (response.serviceProviderTypeId === 1) {
                        dispatch(updateShipper(response));
                    } else if (response.serviceProviderTypeId === 13) {
                        dispatch(updateCustomer(response));
                    } else {
                        dispatch(updateTruckingCompany(response));
                    }
                    dispatch(updateServiceProviders(response));
                }
                props.onHandleCancel();
            },
            failedCallback,
        });
    };

    return (
        <div>
            {!openOrderModal && (
                <Grid container spacing={2}>
                    <Grid item xs={12} pt='0 !important' style={{ display: 'none' }}>
                        <FormControl component='fieldset' disabled={readOnly}>
                            <RadioGroup value={value.customer} onChange={handleChange}>
                                <FormControlLabel
                                    value='IS_CUSTOMER'
                                    control={<Radio size='small' />}
                                    label='Is Customer (Allows more options in customer management)'
                                    disabled={readOnly}
                                />
                                <FormControlLabel
                                    value='SHIPPER_CONSIGNEE_IS_NOT_CUSTOMER'
                                    control={<Radio size='small' />}
                                    label='Shipper / Consignee is Not my customer'
                                    disabled={readOnly}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={6} spacing={2}>
                        {lockerId != null ?
                            <TextField
                                lg={null}
                                md={null}
                                sm={null}
                                value={lockerId}
                                label='Locker Id'
                                readOnly={readOnly}
                            /> : null}
                        <TextField
                            required
                            lg={null}
                            md={null}
                            sm={null}
                            value={value.companyName}
                            onChange={event => setValue(prev => ({ ...prev, companyName: event.target.value }))}
                            label='CompanyName'
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            md={null}
                            sm={null}
                            value={value.companyShortName}
                            onChange={event => setValue(prev => ({ ...prev, companyShortName: event.target.value }))}
                            label='Company Short Name'
                            readOnly={readOnly}
                        />
                        {value.serviceProviderTypeId !== 5 && (
                            <SelectField
                                required
                                lg={null}
                                md={null}
                                sm={null}
                                label='Major Functions'
                                value={value.serviceProviderTypeId}
                                onChange={event => setValue(prev => ({ ...prev, serviceProviderTypeId: event.target.value }))}
                                options={serviceProviderType}
                                disabled={readOnly}
                            />
                        )}
                        {value.serviceProviderTypeId2 !== 5 && (
                            <SelectField
                                lg={null}
                                md={null}
                                sm={null}
                                label='Minor Functions'
                                value={value.serviceProviderTypeId1}
                                onChange={event => setValue(prev => ({ ...prev, serviceProviderTypeId1: event.target.value }))}
                                options={serviceProviderType}
                                disabled={readOnly}
                            />
                        )}
                        {(value.serviceProviderTypeId === 6 || value.serviceProviderTypeId1 === 6) && (
                            <TextField
                                lg={null}
                                md={null}
                                sm={null}
                                value={value.shippingLineNumber}
                                onChange={event => setValue(prev => ({ ...prev, shippingLineNumber: event.target.value }))}
                                label='Shipping Line Number'
                                readOnly={readOnly}
                            />
                        )}
                        <TextField
                            lg={null}
                            md={null}
                            sm={null}
                            value={value.localName}
                            onChange={event => setValue(prev => ({ ...prev, localName: event.target.value }))}
                            label='LocalName'
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            md={null}
                            sm={null}
                            value={value.scac}
                            onChange={event => setValue(prev => ({ ...prev, scac: event.target.value }))}
                            label='SCAC'
                            readOnly={readOnly}
                        />

                        <TextField
                            lg={null}
                            md={null}
                            sm={null}
                            value={value.address1}
                            onChange={event => setValue(prev => ({ ...prev, address1: event.target.value }))}
                            label='Address1'
                            readOnly={readOnly}
                        />

                        <SelectField
                            required
                            lg={6}
                            label='Country'
                            value={value.countryId}
                            onChange={event => setValue(prev => ({ ...prev, countryId: event.target.value }))}
                            options={countryOfOriginData}
                            disabled={readOnly}
                        />
                        <TextField
                            required
                            lg={6}
                            label='City'
                            value={value.city}
                            onChange={event => setValue(prev => ({ ...prev, city: event.target.value }))}
                            options={countryOfOriginData}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            value={value.zipCode}
                            onChange={event => setValue(prev => ({ ...prev, zipCode: event.target.value }))}
                            label='Zip Code'
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={4}
                            value={value.state}
                            onChange={event => setValue(prev => ({ ...prev, state: event.target.value }))}
                            label='State'
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={4}
                            value={value.salesman}
                            onChange={event => setValue(prev => ({ ...prev, salesman: event.target.value }))}
                            label='Salesman'
                            readOnly={readOnly}
                        />
                        {<Autocomplete
                            field='co'
                            label='C/O'
                            lg={6}
                            value={Number(value.co)}
                            onChange={(event, value) => {
                                setValue(prev => ({ ...prev, co: value }))
                            }}
                            options={consigneeList}
                            readOnly={readOnly}
                            disabled={readOnly}
                        />}
                        {(value.serviceProviderTypeId === 1 || value.serviceProviderTypeId === 2 || value.serviceProviderTypeId1 === 1 || value.serviceProviderTypeId1 === 2) && (
                            <FormControl
                                component='fieldset'
                                disabled={readOnly}
                                style={{
                                    paddingTop: 10,
                                    paddingLeft: 20,
                                }}>
                                <Typography color='#1976d2'>Pick up Address</Typography>
                                <RadioGroup value={value.sameAsShipper} onChange={event => handleChanges(event)}>
                                    <FormControlLabel
                                        value='sameAsShipper'
                                        control={<Radio size='small' />}
                                        label='Same as shippers Address'
                                        disabled={readOnly}
                                    />
                                    <FormControlLabel
                                        value='differentAddress'
                                        control={<Radio size='small' />}
                                        label='Different Address'
                                        disabled={readOnly}
                                    />
                                </RadioGroup>
                                <Typography color='#1976d2'>
                                    {' '}
                                    {props.data?.pickupAddress?.companyName || value.pickupAddress?.companyName || null}
                                </Typography>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid container item xs={4} spacing={2} maxHeight='1px'>
                        <SelectField
                            required
                            lg={null}
                            md={null}
                            sm={null}
                            label='Location'
                            value={value.serviceProviderLocationId}
                            onChange={event => setValue(prev => ({ ...prev, serviceProviderLocationId: event.target.value }))}
                            options={usersLocation}
                            disabled={readOnly}
                        />
                        <TextField
                            lg={null}
                            md={null}
                            sm={null}
                            value={value.uatnumber}
                            onChange={event => setValue(prev => ({ ...prev, uatnumber: event.target.value }))}
                            label='VAT Number'
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            md={null}
                            sm={null}
                            value={value.phone}
                            onChange={event => setValue(prev => ({ ...prev, phone: event.target.value }))}
                            label='Phone'
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            md={null}
                            sm={null}
                            value={value.fax}
                            onChange={event => setValue(prev => ({ ...prev, fax: event.target.value }))}
                            label='Fax'
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            md={null}
                            sm={null}
                            value={value.address2}
                            onChange={event => setValue(prev => ({ ...prev, address2: event.target.value }))}
                            label='Address2'
                            readOnly={readOnly}
                        />


                        <Autocomplete
                            field='salesUser'
                            label='Salesman'
                            lg={12}
                            value={Number(value.suser)}
                            onChange={(event, value) => {
                                setValue(prev => ({ ...prev, suser: value }))
                            }}
                            options={userILSales}
                        />
                        <Autocomplete
                            field='impUser'
                            label='F/U'
                            lg={12}
                            value={value.iuser}
                            onChange={(event, value) => {
                                setValue(prev => ({ ...prev, iuser: value }))
                            }}
                            options={userILImporter}
                        />
                        <Autocomplete
                            field='opsUser'
                            label='OPS'
                            lg={12}
                            value={value.ouser}
                            onChange={(event, value) => setValue(prev => ({ ...prev, ouser: value }))}
                            options={userILTracer}
                        />
                        <Autocomplete
                            field='customsUser'
                            label='CUSTOMS'
                            lg={12}
                            value={value.cuser}
                            onChange={(event, value) => setValue(prev => ({ ...prev, cuser: value }))}
                            options={userILCustoms}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox checked={value.useCustoms} onChange={event => setValue(prev => ({ ...prev, useCustoms: event.target.checked }))} />
                            }
                            label='Use Customs'
                        />

                    </Grid>
                    <Grid container item spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Add Contact</Typography>
                        </Grid>
                        <TextField
                            lg={null}
                            label='Email'
                            value={value.email}
                            onChange={event => setValue(prev => ({ ...prev, email: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Position'
                            value={value.position}
                            onChange={event => setValue(prev => ({ ...prev, position: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Mobile'
                            value={value.mobile}
                            onChange={event => setValue(prev => ({ ...prev, mobile: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='English Name'
                            value={value.name}
                            onChange={event => setValue(prev => ({ ...prev, name: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Business Phone'
                            value={value.businessPhone}
                            onChange={event => setValue(prev => ({ ...prev, businessPhone: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Fax'
                            value={value.faxContent}
                            onChange={event => setValue(prev => ({ ...prev, faxContent: event.target.value }))}
                            readOnly={readOnly}
                        />

                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Contact 2</Typography>
                        </Grid>
                        <TextField
                            lg={null}
                            label='Email'
                            value={value.email2}
                            onChange={event => setValue(prev => ({ ...prev, email2: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Position'
                            value={value.position2}
                            onChange={event => setValue(prev => ({ ...prev, position2: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Mobile'
                            value={value.mobile2}
                            onChange={event => setValue(prev => ({ ...prev, mobile2: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='English Name'
                            value={value.name2}
                            onChange={event => setValue(prev => ({ ...prev, name2: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Business Phone'
                            value={value.businessPhone2}
                            onChange={event => setValue(prev => ({ ...prev, businessPhone2: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Fax'
                            value={value.faxContent2}
                            onChange={event => setValue(prev => ({ ...prev, faxContent2: event.target.value }))}
                            readOnly={readOnly}
                        />

                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Contact 3</Typography>
                        </Grid>
                        <TextField
                            lg={null}
                            label='Email'
                            value={value.email3}
                            onChange={event => setValue(prev => ({ ...prev, email3: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Position'
                            value={value.position3}
                            onChange={event => setValue(prev => ({ ...prev, position3: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Mobile'
                            value={value.mobile3}
                            onChange={event => setValue(prev => ({ ...prev, mobile3: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='English Name'
                            value={value.name3}
                            onChange={event => setValue(prev => ({ ...prev, name3: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Business Phone'
                            value={value.businessPhone3}
                            onChange={event => setValue(prev => ({ ...prev, businessPhone3: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Fax'
                            value={value.faxContent3}
                            onChange={event => setValue(prev => ({ ...prev, faxContent3: event.target.value }))}
                            readOnly={readOnly}
                        />

                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Contact 4</Typography>
                        </Grid>
                        <TextField
                            lg={null}
                            label='Email'
                            value={value.email4}
                            onChange={event => setValue(prev => ({ ...prev, email4: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Position'
                            value={value.position4}
                            onChange={event => setValue(prev => ({ ...prev, position4: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Mobile'
                            value={value.mobile4}
                            onChange={event => setValue(prev => ({ ...prev, mobile4: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='English Name'
                            value={value.name4}
                            onChange={event => setValue(prev => ({ ...prev, name4: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Business Phone'
                            value={value.businessPhone4}
                            onChange={event => setValue(prev => ({ ...prev, businessPhone4: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Fax'
                            value={value.faxContent4}
                            onChange={event => setValue(prev => ({ ...prev, faxContent4: event.target.value }))}
                            readOnly={readOnly}
                        />

                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Contact 5</Typography>
                        </Grid>
                        <TextField
                            lg={null}
                            label='Email'
                            value={value.email5}
                            onChange={event => setValue(prev => ({ ...prev, email5: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Position'
                            value={value.position5}
                            onChange={event => setValue(prev => ({ ...prev, position5: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Mobile'
                            value={value.mobile5}
                            onChange={event => setValue(prev => ({ ...prev, mobile5: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='English Name'
                            value={value.name5}
                            onChange={event => setValue(prev => ({ ...prev, name5: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Business Phone'
                            value={value.businessPhone5}
                            onChange={event => setValue(prev => ({ ...prev, businessPhone5: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <TextField
                            lg={null}
                            label='Fax'
                            value={value.faxContent5}
                            onChange={event => setValue(prev => ({ ...prev, faxContent5: event.target.value }))}
                            readOnly={readOnly}
                        />
                        <view style={{ marginLeft: 10 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={(value.active != null) ? value.active : true} onChange={event => setValue(prev => ({ ...prev, active: event.target.checked }))} disabled={readOnly} />
                                }
                                label='Active/In-Active'
                            />
                        </view>
                    </Grid>
                    {!readOnly ?
                        <Grid container item justifyContent='flex-end'>
                            <Button onClick={value.id ? update : create} variant='contained' sx={{ mr: 2 }}>
                                Save
                            </Button>
                            <Button variant='contained' color='secondary' onClick={props.onHandleCancel}>
                                Cancel
                            </Button>
                        </Grid>
                        : ''}
                </Grid>
            )}
            {openOrderModal && (
                <DialogWithGoBackWrapper
                    maxWidth='md'
                    open={openOrderModal}
                    title={'New pickup address'}
                    onHandleCancel={onHandleCancelModal}>
                    <AddServiceProvider type={5} onHandleCancel={onHandleCancelModal} id={null} readOnly={false} />
                </DialogWithGoBackWrapper>
            )}
        </div>
    );
};

export default AddServiceProvider;
